import { Injectable } from '@angular/core';
import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { OidcService } from '../../authentication/services/oidc.service';
import { AppError } from '@domain';
import ErrorManager from '../../../../../application/src/lib/core/error-management/error.manager';

export const CUSTOM_ERROR_MSG = new HttpContextToken<boolean>(() => false);

@Injectable({
	providedIn: 'root',
})
export class HttpErrorsInterceptor {
	constructor(
		private errorManager: ErrorManager,
		private readonly oidcService: OidcService,
	) {}

	intercept(req: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.oidcService.logout();
				}
				const appError = new AppError({
					code: error.status,
					message: error.message,
					details: error.error,
					timestamp: new Date(),
				});
				if (req.context.get(CUSTOM_ERROR_MSG)) {
					// ONLY THROW THE ERROR AND LET THE MANAGER DISPLOAY THE SPECIFCI ERROR MSG
					throw error;
				} else {
					// DISPLAY A DEFAULT ERROR MSG
					this.errorManager.declareError(appError);
					throw error;
				}
			}),
		);
	}
}
