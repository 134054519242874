import { Injectable } from '@angular/core';
import { HasStore } from '@application';
import { dynamicType } from '@components';

export type listState = {
	data: dynamicType[];
	state: state;
	rowState: rowState;
};

type rowState = { field: string | null; isCollapsed: boolean | null; rowId: number | null };
type state = { isCollapsed: boolean };

@Injectable({
	providedIn: 'root',
})
export class ListManager extends HasStore<listState> {
	constructor() {
		const state = { isCollapsed: false };
		const rowState = { field: null, isCollapsed: null, rowId: null };
		super({ data: [], state, rowState }, 'list');
	}

	saveState(collapsed: boolean) {
		this.store.update(state => {
			state.state.isCollapsed = collapsed;
			return { ...state };
		});
	}

	resetState() {
		this.store.update(state => {
			state.state.isCollapsed = false;
			state.data = [];
			state.rowState = {
				field: null,
				isCollapsed: null,
				rowId: null,
			};
			return { ...state };
		});
	}

	saveRowState(row: rowState) {
		this.store.update(state => {
			state.rowState = row;
			return { ...state };
		});
	}

	saveData(data: dynamicType[]) {
		this.store.update(state => {
			state.data = data;
			return { ...state };
		});
	}

	clearData() {
		this.store.update(state => {
			state.data = [];
			return { ...state };
		});
	}

	getDataByKey(key: string): dynamicType[] {
		return this.store.state.data.map((row: dynamicType) => {
			return row[key];
		});
	}
}
