<header class="modal-header">
	<div class="content-inject">
		<ng-content></ng-content>
	</div>

	<div
		class="modal-action"
		(click)="closeModal()">
		<fa-icon
			size="2x"
			[icon]="faTimes"></fa-icon>
	</div>
</header>
