<div class="breadcrumb">
	<fa-icon class="hover" routerLink="/home" [icon]="faHouse"></fa-icon>

	<fa-icon [icon]="faChevronRight"></fa-icon>

	@for (data of breadcrumb.labels; track data; let l = $last, f = $first, i = $index) {

		@if (!f) {
			<fa-icon [icon]="faChevronRight"></fa-icon>
		}

		@if(i === 0){
			<p>
				@if (breadcrumb.labels.length > 1) {					
					<a [routerLink]="breadcrumb.path">{{ data }}</a>
				} @else {
					{{ data }}
				}
			</p>
		} @else {
			<p class="capital">{{ data }}</p>
		}

	}
</div>


