import { Injectable } from '@angular/core';
import { BaseAdapter } from '@infrastructure';
import { Assure } from '@domain';
import { DTO_assure } from '../types/DTO_insured.type';

@Injectable({
	providedIn: 'root',
})
export class AssureAdapter implements BaseAdapter<DTO_assure, Assure> {
	adapt(data: DTO_assure): Assure {
		const assureData = {
			id: data.assure.id ?? null,
			firstName: data.assure.firstName ?? null,
			lastName: data.assure.lastName ?? null,
			civilityId: data.assure.civilityId ?? null,
			civilityLabel: data.assure.civilityLabel ?? null,
			hasSecuriteSociale: data.assure.hasSecuriteSociale ?? null,
			birthRank: data.assure.birthRank ?? null,
			birthDate: data.assure.birthDate ?? null,
			insee: data.assure.insee ?? null,
			comment: data.assure.comment ?? null,
			addressId: data.assure.addressId ?? null,
			addressStreet: data.assure.addressStreet ?? null,
			addressBuilding: data.assure.addressBuilding ?? null,
			addressComplement: data.assure.addressComplement ?? null,
			addressCityCode: data.assure.addressCityCode ?? null,
			addressCityName: data.assure.addressCityName ?? null,
			addressIndication: data.assure.addressIndication ?? null,
			addressPhone: data.assure.addressPhone ?? null,
			addressMobile: data.assure.addressMobile ?? null,
			addressFax: data.assure.addressFax ?? null,
			addressEmail: data.assure.addressEmail ?? null,
			assureAmo: {
				id: data.assure.assureAmo?.id ?? null,
				idOrthop: data.assure.assureAmo?.idOrthop ?? null,
				dateStart: data.assure.assureAmo?.dateStart ?? null,
				dateEnd: data.assure.assureAmo?.dateEnd ?? null,
				amo: {
					id: data.assure.assureAmo?.amo?.id ?? null,
					idOrthop: data.assure.assureAmo?.amo?.idOrthop ?? null,
					name: data.assure.assureAmo?.amo?.name ?? null,
					nameLegal: data.assure.assureAmo?.amo?.nameLegal ?? null,
					isActive: data.assure.assureAmo?.amo?.isActive ?? null,
					codeRegime: data.assure.assureAmo?.amo?.codeRegime ?? null,
					codeCaisse: data.assure.assureAmo?.amo?.codeCaisse ?? null,
					codeCentre: data.assure.assureAmo?.amo?.codeCentre ?? null,
					codeOrganismeDestinataire: data.assure.assureAmo?.amo?.codeOrganismeDestinataire ?? null,
					codeCentreInformatique: data.assure.assureAmo?.amo?.codeCentreInformatique ?? null,
					street: data.assure.assureAmo?.amo?.street ?? null,
					building: data.assure.assureAmo?.amo?.building ?? null,
					complement: data.assure.assureAmo?.amo?.complement ?? null,
					cityCode: data.assure.assureAmo?.amo?.cityCode ?? null,
					cityName: data.assure.assureAmo?.amo?.cityName ?? null,
					phone: data.assure.assureAmo?.amo?.phone ?? null,
					fax: data.assure.assureAmo?.amo?.fax ?? null,
				},
			},
			assureAmc: {
				id: data.assure.assureAmc?.id ?? null,
				idOrthop: data.assure.assureAmc?.idOrthop ?? null,
				pieceJustificative: data.assure.assureAmc?.pieceJustificative ?? null,
				dateStart: data.assure.assureAmc?.dateStart ?? null,
				dateEnd: data.assure.assureAmc?.dateEnd ?? null,
				taux: data.assure.assureAmc?.taux ?? null,
				numeroAdherent: data.assure.assureAmc?.numeroAdherent ?? null,
				amc: {
					id: data.assure.assureAmc?.amc?.id ?? null,
					idOrthop: data.assure.assureAmc?.amc?.idOrthop ?? null,
					name: data.assure.assureAmc?.amc?.name ?? null,
					taux: data.assure.assureAmc?.amc?.taux ?? null,
					numPrefectoral: data.assure.assureAmc?.amc?.numPrefectoral ?? null,
					dateCreation: data.assure.assureAmc?.amc?.dateCreation ?? null,
					dateModification: data.assure.assureAmc?.amc?.dateModification ?? null,
					numComplementaire: data.assure.assureAmc?.amc?.numComplementaire ?? null,
					isActive: data.assure.assureAmc?.amc?.isActive ?? null,
					street: data.assure.assureAmc?.amc?.street ?? null,
					building: data.assure.assureAmc?.amc?.building ?? null,
					complement: data.assure.assureAmc?.amc?.complement ?? null,
					cityCode: data.assure.assureAmc?.amc?.cityCode ?? null,
					cityName: data.assure.assureAmc?.amc?.cityName ?? null,
					phone: data.assure.assureAmc?.amc?.phone ?? null,
					fax: data.assure.assureAmc?.amc?.fax ?? null,
					email: data.assure.assureAmc?.amc?.email ?? null,
				},
			},
		};

		return new Assure({
			relationshipLink: data.relationshipLink ?? null,
			assure: assureData,
			assureAmo: assureData.assureAmo,
			amo: assureData.assureAmo.amo,
			assureAmc: assureData.assureAmc,
			amc: assureData.assureAmc.amc,
		});
	}
}
