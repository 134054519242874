import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IHasAgGridActionButton } from './hasAgGridActionButton.interface';

@Component({
	selector: 'intranet-action-button',
	standalone: true,
	imports: [CommonModule, FaIconComponent],
	template: '<div class="flex w-full h-full"><div class="btn-sos btn-primary align-self-center"><span>{{actionData.text}}</span><fa-icon [icon]="actionData.icon!"></fa-icon></div></div>',
	styleUrl: './action-button.component.scss',
})
export class ActionButtonComponent implements ICellRendererAngularComp, IHasAgGridActionButton {
	actionData: { text: string; buttonClass: string; action: Function | null; actionParams: any; icon: IconDefinition | null };

	constructor() {
		this.actionData = {
			text: '',
			buttonClass: '',
			action: null,
			actionParams: null,
			icon: null,
		};
	}

	public triggerAction() {
		if (this.actionData.action)
			if (this.actionData.actionParams) {
				this.actionData.action(this.actionData.actionParams);
			} else {
				this.actionData.action();
			}
	}

	agInit(params: ICellRendererParams): void {
		if (!params.data.action) {
			this.actionData = {
				text: params.data.actionData.text,
				buttonClass: params.data.actionData.buttonClass,
				action: params.data.actionData.action,
				actionParams: params.data.actionData.actionParams,
				icon: params.data.actionData.icon,
			};
		}
	}

	refresh(params: ICellRendererParams<any>): boolean {
		return false;
	}
}
