import { HasStore } from '../store/has-store';
import { Injectable } from '@angular/core';
import { loadingListenerState } from './loading-listener-types';
import { Observable, of } from 'rxjs';
import { select } from '@ngneat/elf';

@Injectable({
	providedIn: 'root',
})
export class LoadingListenerManager extends HasStore<loadingListenerState> {
	constructor() {
		super(
			{
				listeners: [],
			},
			'loadingListener',
		);
	}

	toggleLoadingListener(key: string, isLoading: boolean) {
		this.store.update(state => {
			const newState = { ...state };
			const listener = state.listeners.find(listener => listener.key === key);
			if (listener) {
				listener.isLoading = isLoading;
			} else {
				state.listeners.push({ key, isLoading });
			}

			return { ...state, ...newState };
		});
	}

	isLoading(key: string): Observable<boolean> {
		if (this.store.value.listeners.find(listener => listener.key === key) === undefined) return of(false);
		return this.store.pipe(select(state => state.listeners.find(listener => listener.key === key)!.isLoading));
	}
}
