import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AgPromise, INoRowsOverlayComp, INoRowsOverlayParams } from 'ag-grid-community';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

export interface NoRowsOverlayParamsExtra {
	title?: string;
	image?: string;
}

interface NoRowsOverlayParams extends NoRowsOverlayParamsExtra, INoRowsOverlayParams {}

@Component({
	selector: 'lib-no-rows-overlay',
	standalone: true,
	imports: [CommonModule, FaIconComponent, ReactiveFormsModule],
	styleUrls: ['./no-rows-overlay.component.scss'],
	template: ` <div class="no-rows-overlay"></div>`,
})
export class NoRowsOverlayComponent implements INoRowsOverlayComp {
	protected params!: NoRowsOverlayParams;

	init(params: NoRowsOverlayParams): AgPromise<void> | void {
		this.params = params;
	}

	getGui(): HTMLElement {
		const div = document.createElement('div');
		div.innerHTML = `
			<div style="display: flex; flex: 1; justify-content: center; align-items: center">
				<div style="width: 50%; height: 50%; align-content: center; justify-content: center">
						${
							this.params.image
								? `<div style="margin-bottom: 30px">
						<img src="${this.params.image}" alt="image" height="100%" width="100%"/>
						</div>`
								: ''
						}
						
					<div>
						${this.params.title ?? 'Lancer une recherche pour afficher des données'}
					</div>
					
				</div>
			</div>
		`;

		return div;
	}
}
