export enum EmployeeProfile {
	ADMIN = 'ADMIN',
	INTERVENANT = 'INTERVENANT',
	DISPATCHEUR = 'DISPATCHEUR',
	PHARMACIEN = 'PHARMACIEN',
	SUPERVISEUR_TECHNIQUE = 'SUPERVISEUR_TECHNIQUE',
	COMMERCIAL = 'COMMERCIAL',
	RESP_TECH_MAD_LENS = 'RESP_TECH_MAD_LENS',
	COMMERCIALE_TOULON = 'COMMERCIALE_TOULON',
	SECRETAIRE = 'SECRETAIRE',
	COORDINATRICE_ADMINISTRATIVE = 'COORDINATRICE_ADMINISTRATIVE',
	COORDINATEUR_TECHNIQUE = 'COORDINATEUR_TECHNIQUE',
	VISU = 'VISU',
	ASSISTANTE_ADMINISTRATIVE = 'ASSISTANTE_ADMINISTRATIVE',
	JURIDIQUE = 'JURIDIQUE',
	RELANCES = 'RELANCES',
	COMMERCIAL_ADMINISTRATIF = 'COMMERCIAL_ADMINISTRATIF',
	MAGASINIER = 'MAGASINIER',
	RESP_TECHNIQUE = 'RESP_TECHNIQUE',
	DIRECTEUR = 'DIRECTEUR',
	DIRECTEUR_SECONDAIRE = 'DIRECTEUR_SECONDAIRE',
	STATISTIQUES = 'STATISTIQUES',
	COORDINATEUR_COMMERCIAL = 'COORDINATEUR_COMMERCIAL',
	COORDINATEUR_COMMERCIAL_ADMINISTRATIF = 'COORDINATEUR_COMMERCIAL_ADMINISTRATIF',
	TECHNICIEN_ASTREINTE = 'TECHNICIEN_ASTREINTE',
	COMMERCIAL_GAMEP = 'COMMERCIAL_GAMEP',
	INTERVENANT_AGENDA_AGENCE = 'INTERVENANT_AGENDA_AGENCE',
	TELECONSEILLER = 'TELECONSEILLER',
	INTERVENANT_SUISSE = 'INTERVENANT_SUISSE',
	VISU_APPEL_OFFRE = 'VISU_APPEL_OFFRE',
	COMMERCIAL_ADMINISTRATIF_GAMEP = 'COMMERCIAL_ADMINISTRATIF_GAMEP',
}
