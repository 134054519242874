import { BaseModel } from '../../core/base.model';
import { addressType } from '../../enums/address-type.enum';
import { StringManager } from '@application';

export type address = {
	migrationId: number;
	addressBuilding: string;
	addressStreet: string;
	addressComplement: string;
	cityName: string;
	zipCode: string;
	indicationOfVisit: string;
	isAdministrativeAddress?: boolean;
	email: string;
	phone: string;
	phoneBis: string;
	mobile: string;
	mobileBis: string;
	fax: string;
	faxBis: string;
	phoneOwner: string;
	phoneBisOwner: string;
	mobileOwner: string;
	mobileBisOwner: string;
	hasRefusedMarketingSms: boolean;
	hasRefusedMarketingSmsBis: boolean;
	hasNotProvidedEmail: boolean;
	isSupportOnly: boolean;
	addressTypes: addressType[];
	meanOfContact?: { name: string; value: string }[];
	isOpen: boolean;
};

export class PatientAddress implements BaseModel<address> {
	constructor(data: address) {
		this._isAdministrativeAddress = data.addressTypes.includes(addressType.ADMINISTRATIVE);
		this._migrationId = data.migrationId;
		this._addressBuilding = data.addressBuilding;
		this._addressStreet = data.addressStreet;
		this._addressComplement = data.addressComplement;
		this._cityName = data.cityName;
		this._zipCode = data.zipCode;
		this._indicationOfVisit = data.indicationOfVisit;
		this._email = data.email;

		this._phone = data.phone;
		this._phoneOwner = data.phoneOwner;

		this._phoneBis = data.phoneBis;
		this._phoneBisOwner = data.phoneBisOwner;

		this._mobile = data.mobile;
		this._mobileOwner = data.mobileOwner;

		this._mobileBis = data.mobileBis;
		this._mobileBisOwner = data.mobileBisOwner;

		this._fax = data.fax;
		this._faxBis = data.faxBis;

		this._hasRefusedMarketingSms = data.hasRefusedMarketingSms;
		this._hasRefusedMarketingSmsBis = data.hasRefusedMarketingSmsBis;
		this._hasNotProvidedEmail = data.hasNotProvidedEmail;
		this._isSupportOnly = data.isSupportOnly;
		this._addressTypes = data.addressTypes;

		this._isOpen = data.isOpen;

		if (data.phone !== null) {
			this._meanOfContact.push({ name: data.phoneOwner, value: data.phone });
		}

		if (data.phoneBis !== null) {
			this._meanOfContact.push({ name: data.phoneBisOwner, value: data.phoneBis });
		}

		if (data.mobile !== null) {
			this._meanOfContact.push({ name: data.mobileOwner, value: data.mobile });
		}

		if (data.mobileBis !== null) {
			this._meanOfContact.push({ name: data.mobileBisOwner, value: data.mobileBis });
		}
	}

	private _isAdministrativeAddress: boolean;

	get isAdministrativeAddress(): boolean {
		return this._isAdministrativeAddress;
	}

	set isAdministrativeAddress(value: boolean) {
		this._isAdministrativeAddress = value;
	}

	private _isOpen: boolean;

	get isOpen(): boolean {
		return this._isOpen;
	}

	set isOpen(value: boolean) {
		this._isOpen = value;
	}

	private _meanOfContact: { name: string; value: string }[] = [];

	get meanOfContact(): { name: string; value: string }[] {
		return this._meanOfContact;
	}

	set meanOfContact(value: { name: string; value: string }[]) {
		this._meanOfContact = value;
	}

	private _addressTypes: addressType[] = [];

	get addressTypes(): addressType[] {
		return this._addressTypes;
	}

	set addressTypes(value: addressType[]) {
		this._addressTypes = value;
	}

	private _migrationId: number;

	get migrationId(): number {
		return this._migrationId;
	}

	set migrationId(value: number) {
		this._migrationId = value;
	}

	private _addressBuilding: string;

	get addressBuilding(): string {
		return this._addressBuilding;
	}

	set addressBuilding(value: string) {
		this._addressBuilding = value;
	}

	private _addressStreet: string;

	get addressStreet(): string {
		return this._addressStreet;
	}

	set addressStreet(value: string) {
		this._addressStreet = value;
	}

	private _addressComplement: string;

	get addressComplement(): string {
		return this._addressComplement;
	}

	set addressComplement(value: string) {
		this._addressComplement = value;
	}

	private _cityName: string;

	get cityName(): string {
		return this._cityName;
	}

	set cityName(value: string) {
		this._cityName = value;
	}

	private _zipCode: string;

	get zipCode(): string {
		return this._zipCode;
	}

	set zipCode(value: string) {
		this._zipCode = value;
	}

	private _indicationOfVisit: string;

	get indicationOfVisit(): string {
		return this._indicationOfVisit;
	}

	set indicationOfVisit(value: string) {
		this._indicationOfVisit = value;
	}

	private _email: string;

	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
	}

	private _phone: string;

	get phone(): string {
		return this._phone;
	}

	set phone(value: string) {
		this._phone = value;
	}

	private _phoneBis: string;

	get phoneBis(): string {
		return this._phoneBis;
	}

	set phoneBis(value: string) {
		this._phoneBis = value;
	}

	private _mobile: string;

	get mobile(): string {
		return this._mobile;
	}

	set mobile(value: string) {
		this._mobile = value;
	}

	private _mobileBis: string;

	get mobileBis(): string {
		return this._mobileBis;
	}

	set mobileBis(value: string) {
		this._mobileBis = value;
	}

	private _fax: string;

	get fax(): string {
		return this._fax;
	}

	set fax(value: string) {
		this._fax = value;
	}

	private _faxBis: string;

	get faxBis(): string {
		return this._faxBis;
	}

	set faxBis(value: string) {
		this._faxBis = value;
	}

	private _phoneOwner: string;

	get phoneOwner(): string {
		return this._phoneOwner;
	}

	set phoneOwner(value: string) {
		this._phoneOwner = value;
	}

	private _phoneBisOwner: string;

	get phoneBisOwner(): string {
		return this._phoneBisOwner;
	}

	set phoneBisOwner(value: string) {
		this._phoneBisOwner = value;
	}

	private _mobileOwner: string;

	get mobileOwner(): string | undefined {
		return this._mobileOwner;
	}

	set mobileOwner(value: string) {
		this._mobileOwner = value;
	}

	private _mobileBisOwner: string;

	get mobileBisOwner(): string {
		return this._mobileBisOwner;
	}

	set mobileBisOwner(value: string) {
		this._mobileBisOwner = value;
	}

	private _hasRefusedMarketingSms: boolean;

	get hasRefusedMarketingSms(): boolean {
		return this._hasRefusedMarketingSms;
	}

	set hasRefusedMarketingSms(value: boolean) {
		this._hasRefusedMarketingSms = value;
	}

	private _hasRefusedMarketingSmsBis: boolean;

	get hasRefusedMarketingSmsBis(): boolean {
		return this._hasRefusedMarketingSmsBis;
	}

	set hasRefusedMarketingSmsBis(value: boolean) {
		this._hasRefusedMarketingSmsBis = value;
	}

	private _hasNotProvidedEmail: boolean;

	get hasNotProvidedEmail(): boolean {
		return this._hasNotProvidedEmail;
	}

	set hasNotProvidedEmail(value: boolean) {
		this._hasNotProvidedEmail = value;
	}

	private _isSupportOnly: boolean;

	get isSupportOnly(): boolean {
		return this._isSupportOnly;
	}

	set isSupportOnly(value: boolean) {
		this._isSupportOnly = value;
	}

	asObject(): address {
		return {
			migrationId: this._migrationId,
			addressBuilding: this._addressBuilding,
			addressStreet: this._addressStreet,
			addressComplement: this._addressComplement,
			cityName: this._cityName,
			zipCode: this._zipCode,
			indicationOfVisit: this._indicationOfVisit,
			isAdministrativeAddress: this._isAdministrativeAddress,
			email: this._email,
			phone: this._phone,
			phoneBis: this._phoneBis,
			mobile: this._mobile,
			mobileBis: this._mobileBis,
			fax: this._fax,
			faxBis: this._faxBis,
			phoneOwner: this._phoneOwner,
			phoneBisOwner: this._phoneBisOwner,
			mobileOwner: this._mobileOwner,
			mobileBisOwner: this._mobileBisOwner,
			hasRefusedMarketingSms: this._hasRefusedMarketingSms,
			hasRefusedMarketingSmsBis: this._hasRefusedMarketingSmsBis,
			hasNotProvidedEmail: this._hasNotProvidedEmail,
			isSupportOnly: this._isSupportOnly,
			addressTypes: this._addressTypes,
			meanOfContact: this._meanOfContact,
			isOpen: this._isOpen,
		};
	}

	oneLine(): string {
		return StringManager.concatStrings([this._addressBuilding, this._addressStreet, this._addressComplement, this._zipCode, this._cityName], '-');
	}
}
