import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';

import { DTO_user } from '../../authentication/types/DTO_auth.types';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { PrescriptionAdapter } from '../../prescription/adapter/prescription.adapter';
import { DTO_prescription } from '../../prescription/type/DTO_prescription.types';

import { DTO_doctor, DTO_doctor_patient, DTO_doctor_search } from '../types/DTO_doctor.types';
import { DTO_doctorSpecialty } from '../types/DTO_doctorSpecialty';
import { DoctorAdapter } from '../adapters/doctor.adapter';
import { DoctorPatientAdapter } from '../adapters/doctor-patient.adapter';
import { DoctorSpecialtyAdapter } from '../adapters/doctor-specialty.adapter';
import { paginatedResult, pagination } from '@application';
import { Doctor, DoctorPatient, doctorPatientsParamSearch, doctorPrescriptionsParamSearch, doctorSearchParams, DoctorSpecialty, Prescription } from '@domain';
import { DoctorSearchAdapter } from '../adapters/doctor-search.adapter';

@Injectable({
	providedIn: 'root',
})
export class DoctorRepository extends BaseHttpRepository {
	constructor(
		private readonly doctorAdapter: DoctorAdapter,
		private readonly doctorSearchAdapter: DoctorSearchAdapter,
		private readonly doctorPatientAdapter: DoctorPatientAdapter,
		private readonly doctorSpecialtyAdapter: DoctorSpecialtyAdapter,
		private readonly prescriptionAdapter: PrescriptionAdapter
	) {
		super();
	}

	searchDoctors(params: doctorSearchParams, pagination: pagination): Observable<paginatedResult<Doctor[]>> {

		const dataToSend: DTO_doctor_search = this.doctorSearchAdapter.adapt({
			pagination: pagination,
			params: params,
		});
		return this.paginatedGet<DTO_doctor[]>(`${this.apiUrl}doctors`, dataToSend, { 'X-Loader-Key': 'doctor-search' }).pipe(
			map((data: paginatedResult<DTO_doctor[]>) => {
				console.log(pagination)
				return {
					data: data.data.map((doctor: DTO_doctor) => this.doctorAdapter.adapt(doctor)),
					pagination: data.pagination,
				};
			}),
		);
	}

	getDoctorByRpps(rpps: string): Observable<Doctor> {
		return this.http.get<DTO_doctor>(`${this.apiUrl}doctors/${rpps}`).pipe(
			map((data: DTO_doctor) => this.doctorAdapter.adapt(data)),
			switchMap((doctor: Doctor) => {
				if (doctor.accountIdExtranetV2) {
					return this.getUserAppUsernames([doctor.accountIdExtranetV2]).pipe(
						map((infos: DTO_user[]) => {
							doctor.appUsername = infos[0].appUsername;
							return doctor;
						}),
					);
				} else {
					return of(doctor);
				}
			}),
		);
	}

	getUserAppUsernames(idV2: string[]): Observable<DTO_user[]> {
		return this.http.get<DTO_user[]>(`${this.vnextSecurityUrl}account/usernames`, { params: { appUserIds: idV2 } });
	}

	getDoctorSpecialties(): Observable<DoctorSpecialty[]> {
		return this.http
			.get<DTO_doctorSpecialty[]>(`${this.apiUrl}doctors/types`)
			.pipe(map((doctorSpecialties: DTO_doctorSpecialty[]) => doctorSpecialties.map(doctorSpecialty => this.doctorSpecialtyAdapter.adapt(doctorSpecialty))));
	}

	getDoctorPrescriptions(rpps: string, params: doctorPrescriptionsParamSearch): Observable<paginatedResult<Prescription[]>> {
		return this.paginatedGet<DTO_prescription[]>(`${this.apiUrl}prescriptions`, params).pipe(
			map((data: paginatedResult<DTO_prescription[]>) => {
				return {
					data: data.data.map((dtoPrescription: DTO_prescription) => this.prescriptionAdapter.adapt(dtoPrescription)),
					pagination: data.pagination,
				};
			}),
		);
	}

	searchDoctorPatients(rpps: string, params: doctorPatientsParamSearch): Observable<paginatedResult<DoctorPatient[]>> {
		return this.paginatedGet<DTO_doctor_patient[]>(`${this.apiUrl}doctors/${rpps}/patients`, params).pipe(
			map((data: paginatedResult<DTO_doctor_patient[]>) => {
				return {
					data: data.data.map((dtoDoctorPatient: DTO_doctor_patient) => this.doctorPatientAdapter.adapt(dtoDoctorPatient)),
					pagination: data.pagination,
				};
			}),
		);
	}
}
