import { DateTime } from 'luxon';
import { BaseModel } from '../../core/base.model';
import { techInterventionStatus } from './techIntervention.enum';
import { Technician } from './technician';

export type interventionDetails = {
	idGestAppel: number;
	idSourceGestAppel: number;
	gaCategory: string;
	gaPrecisionInfo: string;
	gaMateriel: string;
	gaComment: string;
	gaCallDate: string;
};

export type techIntervention = {
	id: number;
	technician: Technician;
	patient: interventionPatient;
	idTypeIntervention: number;
	typeInterventionLibelle: string;
	status: techInterventionStatus;
	realisationDate: string | null;
	expectedDate: string | null;
	interventionStartHour: string | null;
	interventionEndHour: string | null;
	comment: string;
	source: string;
	details: interventionDetails | null;
};

export type interventionPatient = {
	id: number | null;
	firstname: string;
	lastname: string;
	address: string;
	phoneNumber: string;
	doctor: {
		firstname: string;
		lastname: string;
	} | null;
};

export class TechIntervention implements BaseModel<techIntervention> {
	constructor(intervention: techIntervention) {
		this._id = intervention.id;
		this._technician = intervention.technician;
		this._patient = intervention.patient;
		this._idTypeIntervention = intervention.idTypeIntervention;
		this._typeInterventionLibelle = intervention.typeInterventionLibelle;
		this._status = intervention.status;
		this._realisationDate = intervention.realisationDate;
		this._expectedDate = intervention.expectedDate;
		this._interventionStartHour = intervention.interventionStartHour;
		this._interventionEndHour = intervention.interventionEndHour;
		this._comment = intervention.comment;
		this._source = intervention.source;
		this._details = intervention.details;
	}

	_id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	_technician: Technician;

	get technician(): Technician {
		return this._technician;
	}

	set technician(value: Technician) {
		this._technician = value;
	}

	_patient: interventionPatient;

	get patient(): interventionPatient {
		return this._patient;
	}

	set patient(value: interventionPatient) {
		this._patient = value;
	}

	_idTypeIntervention: number;

	get idTypeIntervention(): number {
		return this._idTypeIntervention;
	}

	set idTypeIntervention(value: number) {
		this._idTypeIntervention = value;
	}

	_typeInterventionLibelle: string;

	get typeInterventionLibelle(): string {
		return this._typeInterventionLibelle;
	}

	set typeInterventionLibelle(value: string) {
		this._typeInterventionLibelle = value;
	}

	_status: techInterventionStatus;

	get status(): techInterventionStatus {
		return this._status;
	}

	set status(value: techInterventionStatus) {
		this._status = value;
	}

	_realisationDate: string | null;

	get realisationDate(): string | null {
		return this._realisationDate;
	}

	set realisationDate(value: string | null) {
		this._realisationDate = value;
	}

	_expectedDate: string | null;

	get expectedDate(): string | null {
		return this._expectedDate;
	}

	set expectedDate(value: string | null) {
		this._expectedDate = value;
	}

	_interventionStartHour: string | null;

	get interventionStartHour(): string | null {
		return this._interventionStartHour;
	}

	set interventionStartHour(value: string | null) {
		this._interventionStartHour = value;
	}

	_interventionEndHour: string | null;

	get interventionEndHour(): string | null {
		return this._interventionEndHour;
	}

	set interventionEndHour(value: string | null) {
		this._interventionEndHour = value;
	}

	_comment: string;

	get comment(): string {
		return this._comment;
	}

	_source: string;

	get source(): string {
		return this._source;
	}

	set source(value: string) {
		this._source = value;
	}

	_details: interventionDetails | null;

	get details(): interventionDetails | null {
		return this._details;
	}

	set details(value: interventionDetails | null) {
		this._details = value;
	}

	set commentaire(value: string) {
		this._comment = value;
	}

	get displayedStatus(): string {
		switch (this.status) {
			case techInterventionStatus.ToPlan:
				return 'A planifier';
			case techInterventionStatus.Planned:
				return 'Planifié';
			case techInterventionStatus.Done:
				return 'Réalisé';
			default:
				return '';
		}
	}

	get displayedExpectedDate(): string {
		let hour = '';
		if (this.expectedDate && DateTime.fromFormat(this.expectedDate, "yyyy-MM-dd'T'HH:mm:ss").hour != 0) {
			hour = ' à ' + DateTime.fromFormat(this.expectedDate, "yyyy-MM-dd'T'HH:mm:ss").toFormat('HH:mm');
		}
		return this.expectedDate ? DateTime.fromFormat(this.expectedDate, "yyyy-MM-dd'T'HH:mm:ss").toFormat('dd/MM/yyyy') + hour : '-';
	}

	get displayedRealisationDate(): string {
		let hour = '';
		if (this.realisationDate && DateTime.fromFormat(this.realisationDate, "yyyy-MM-dd'T'HH:mm:ss").hour != 0) {
			hour = ' à ' + DateTime.fromFormat(this.realisationDate, "yyyy-MM-dd'T'HH:mm:ss").toFormat('HH:mm');
		}
		return this.expectedDate ? DateTime.fromFormat(this.expectedDate, "yyyy-MM-dd'T'HH:mm:ss").toFormat('dd/MM/yyyy') + hour : '-';
	}

	get displayedGACalldate(): string {
		return this.details?.gaCallDate ? DateTime.fromFormat(this.details?.gaCallDate, "yyyy-MM-dd'T'HH:mm:ss.SSS").toFormat("dd/MM/yyyy ' à 'HH:mm") : '-';
	}

	get isExpectedDateInFuture(): boolean {
		return this.expectedDate ? DateTime.fromFormat(this.expectedDate, "yyyy-MM-dd'T'HH:mm:ss").startOf('day') >= DateTime.now().startOf('day') : false;
	}

	asObject(): techIntervention {
		return {
			id: this.id,
			technician: this.technician,
			patient: this.patient,
			idTypeIntervention: this.idTypeIntervention,
			typeInterventionLibelle: this.typeInterventionLibelle,
			status: this.status,
			realisationDate: this.realisationDate,
			expectedDate: this.expectedDate,
			interventionStartHour: this.interventionStartHour,
			interventionEndHour: this.interventionEndHour,
			comment: this.comment,
			source: this.source,
			details: this.details,
		};
	}
}
