import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_patient_observance } from '../types/DTO_patient-observance';
import { Observance, patient_observance } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientObservanceAdapter implements BaseAdapter<DTO_patient_observance, patient_observance> {
	adapt(data: DTO_patient_observance): patient_observance {
		const observanceData: patient_observance = {
			count: data?.count,
			countUsed: data?.countUsed,
			dataPoints: [],
			durationAverageByMinutes: data?.durationAverageByMinutes,
			durationAverageTotalByMinutes: data?.durationTotalByMinutes,
			durationTotalByMinutes: data?.durationTotalByMinutes,
			iahAverage: data?.iahAverage,
			iahAverageOverDuration: data?.iahAverageOverDuration,
			iahAverageTotal: data?.iahAverageTotal,
			iahTotal: data?.iahTotal,
			iahXDurationTotal: data?.iahXDurationTotal,
			leaksAverage: data?.leaksAverage,
			leaksAverageOverDuration: data?.leaksAverageOverDuration,
			leaksAverageTotal: data?.leaksAverageTotal,
			leaksTotal: data?.leaksTotal,
			leaksXDurationTotal: data?.leaksXDurationTotal,
			pressureAverage: data?.pressureAverage,
			pressureAverageOverDuration: data?.pressureAverageOverDuration,
			pressureAverageTotal: data?.pressureAverageTotal,
			pressureTotal: data?.pressureTotal,
			pressureXDurationTotal: data?.pressureXDurationTotal,
		};

		const observance = new Observance(observanceData);
		if (this.check(data)) {
			observance.dataPoints = data?.dataPoints;
		}
		return observance;
	}

	check(data: DTO_patient_observance) {
		return data?.dataPoints.length > 0;
	}
}
