import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_template_store } from '../../types/DTO_template-store.types';

import { Agency, agencyLevel, TemplateStore } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class TemplateStoreAdapter implements BaseAdapter<DTO_template_store, TemplateStore> {
	adapt(data: DTO_template_store): TemplateStore {
		return new TemplateStore({
			id: data.id,
			name: data.name,
			agency: new Agency({ id: data.agencyId, name: data.agencyName }),
			agencyLevel: data.agencyId == null ? agencyLevel.National : agencyLevel.Regional,
			magasinTypeId: data.magasinTypeId,
			articles: null,
		});
	}
}
