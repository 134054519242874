import { Component, EventEmitter, inject, Input, input, InputSignal, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { listColDef } from './listColDef.types';
import { DateTime, Settings } from 'luxon';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ListItemComponent } from './list-item/list-item.component';
import { ListManager } from './core/list.manager';

export type dynamicType = { [key: string]: any };

@Component({
	selector: 'intranet-list',
	standalone: true,
	imports: [CommonModule, FaIconComponent, NgxSkeletonLoaderModule, ListItemComponent, NgIf],
	templateUrl: './list.component.html',
	styleUrl: './list.component.scss',
})
export class ListComponent implements OnChanges, OnDestroy {
	colDef: InputSignal<listColDef[]> = input.required();

	isLoading: InputSignal<boolean> = input(false);
	isSortable: InputSignal<boolean> = input(false);
	isCollapsible: InputSignal<boolean> = input(false);
	isStripped: InputSignal<boolean> = input(false);

	displayBorder: InputSignal<boolean> = input(false);
	displayResult: InputSignal<boolean> = input(false);
	displayShadow: InputSignal<boolean> = input(false);

	@Input()
	dataSource: dynamicType[] = [];

	@Input()
	isClickable: boolean = false;

	sortDirection: { [key: string]: 'asc' | 'desc' } = {};
	componentId: string = Math.random().toString(36).substring(7);

	readonly faSort = faSort;

	@Output()
	rowIsClicked: EventEmitter<dynamicType> = new EventEmitter<dynamicType>();

	private readonly listManager: ListManager = inject(ListManager);

	constructor() {
		Settings.defaultLocale = 'fr';
	}

	getDataFromCol(index: number, col: listColDef): string[] {
		return this.dataSource.map(row => {
			return this.dataFromCol(row, col);
		});
	}

	selectItem(index: number, col: listColDef) {		
		this.rowIsClicked.emit({ data: this.dataSource[index], fieldName: col.field, index: index });
	}

	sortByColumn(col: listColDef) {
		const field = col.field;
		const direction = this.sortDirection[field] === 'asc' ? 'desc' : 'asc';
		this.sortDirection[field] = direction;
		this.sortDataSource(field, direction, col);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.listManager.saveData(changes['dataSource'].currentValue);
	}

	ngOnDestroy() {
		this.listManager.clearData();
	}

	private dataFromCol(data: dynamicType, col: listColDef): string {
		if (data === undefined || data === null) {
			return 'non renseigné';
		}
		if (col.isDate) {
			const date = DateTime.fromISO(data[col.field]).toLocaleString(DateTime.DATE_SHORT);
			return date === 'Invalid DateTime' ? 'non renseigné' : date;
		}
		return data[col.field];
	}

	private sortDataSource(field: string, direction: 'asc' | 'desc', col: listColDef) {
		this.dataSource.sort((a, b) => {
			let comparison = 0;

			if (col.isDate) {
				const dateA = DateTime.fromISO(a[field]);
				const dateB = DateTime.fromISO(b[field]);
				comparison = dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
			} else {
				comparison = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
			}

			return direction === 'asc' ? comparison : -comparison;
		});
	}
}
