import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_supply_request_search_result } from '../../types/DTO_supply-requests.types';
import { Agency, SupplyRequest, Technician } from '@domain';


@Injectable({
	providedIn: 'root',
})
export default class SupplyRequestSearchresultAdapter implements BaseAdapter<DTO_supply_request_search_result, SupplyRequest> {

	adapt(dto: DTO_supply_request_search_result): SupplyRequest {

		return new SupplyRequest({
			number: dto.id,
			agency: new Agency({ id: dto.agencyId, name: "" }),
			technician: new Technician({
				id: dto.technicianId,
				name: dto.technicianFullName

			}),
			creationDate: dto.creationDate,
			deliveryDate: "",
			submissionDate: dto.submissionDate,
			orderId: dto.orderId,
			articles: [],
			selected: false
		});

	}
}
