import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_order } from '../../types/DTO_order.types';
import { Agency, Order, OrderItem } from '@domain';


@Injectable({
	providedIn: 'root',
})
export class OrderAdapter implements BaseAdapter<DTO_order, Order> {
	adapt(dto: DTO_order): Order {
		return new Order({
			id: dto.id,
			intervenantId: dto.intervenantId,
			agency: { id: dto.agencyId, name: dto.agencyName } as Agency,
			deliveryAddress: {
				street: dto.deliveryAddress.street,
				building: dto.deliveryAddress.building,
				cityCode: dto.deliveryAddress.cityCode,
				cityName: dto.deliveryAddress.cityName,
			},
			comment: dto.comments,
			items: dto.articles ? dto.articles.map(item => {
				return new OrderItem({
					articleId: item.articleId,
					name: item.label,
					quantity: item.quantity
				})
			}) : []
		});
	}
}
