import { BaseModel } from '../../core/base.model';

export type patientFollowUp = {
	interventionId: number;
	date: string;
	parameters: followUpParameters[];
};

export type followUpParameters = {
	parameterId: number;
	label: string;
	value: string;
};

export class PatientFollowUp implements BaseModel<patientFollowUp> {
	constructor(data: patientFollowUp) {
		this._interventionId = data.interventionId;
		this._date = data.date;
		this._parameters = data.parameters;
	}

	private _interventionId: number;

	get interventionId(): number {
		return this._interventionId;
	}

	set interventionId(value: number) {
		this._interventionId = value;
	}

	private _date: string;

	get date(): string {
		return this._date;
	}

	set date(value: string) {
		this._date = value;
	}

	private _parameters: followUpParameters[];

	get parameters(): followUpParameters[] {
		return this._parameters;
	}

	set parameters(value: followUpParameters[]) {
		this._parameters = value;
	}

	asObject(): patientFollowUp {
		return {
			interventionId: this.interventionId,
			date: this._date,
			parameters: this._parameters,
		};
	}
}
