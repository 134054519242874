import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_fia_search_params } from '../../types/DTO_fia.types';
import { searchFiaParams } from '@domain';
import { pagination } from '@application';

@Injectable({
	providedIn: 'root',
})
export default class FiaSearchRequestAdapter
	implements
		BaseAdapter<
			{
				searchParams: searchFiaParams;
				withPagination: boolean;
				pagination: pagination;
			},
			DTO_fia_search_params
		>
{
	adapt(data: { searchParams: searchFiaParams; withPagination: boolean; pagination: pagination }): DTO_fia_search_params {
		if (!data.withPagination) {
			data.pagination.limit = 200;
		}

		const DTO_fia_params: DTO_fia_search_params = {
			PageNumber: data.pagination.page,
			PageSize: data.pagination.limit,
		};

		if (data.searchParams.Name) DTO_fia_params.Name = data.searchParams.Name;
		if (data.searchParams.ArticleCategory) DTO_fia_params.ArticleCategory = data.searchParams.ArticleCategory;
		if (data.searchParams.FamilyId) DTO_fia_params.FamilyId = data.searchParams.FamilyId;

		return DTO_fia_params;
	}
}
