import { BaseModel } from '../../../core/base.model';

export type articleFamily = {
	id: number;
	label: string;
};

export class ArticleFamily implements BaseModel<articleFamily> {
	constructor(family: articleFamily) {
		this._id = family.id;
		this._label = family.label;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _label: string;

	get label(): string {
		return this._label;
	}

	set label(value: string) {
		this._label = value;
	}

	asObject(): articleFamily {
		return {
			id: this.id,
			label: this._label,
		};
	}
}
