import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_doctor_search } from '../types/DTO_doctor.types';
import { doctorSearchParams } from '@domain';
import { pagination } from '@application';

@Injectable({
	providedIn: 'root',
})
export class DoctorSearchAdapter implements BaseAdapter<{
	params: doctorSearchParams;
	pagination: pagination;
}, DTO_doctor_search> {
	adapt(data: { params: doctorSearchParams; pagination: pagination }): DTO_doctor_search {
		const ret: DTO_doctor_search = {
			PageNumber: data.pagination.page,
			PageSize: data.pagination.limit,
		};
		if (data.params.agencyId) ret.agencyId = data.params.agencyId;
		if (data.params.firstName) ret.firstName = data.params.firstName;
		if (data.params.lastName) ret.lastName = data.params.lastName;
		if (data.params.rpps != null) ret.rpps = data.params.rpps;
		if (data.params.finess != null) ret.finess = data.params.finess;
		if (data.params.typeId != null) ret.typeId = data.params.typeId;

		return ret;
	}
}
