import { Injectable } from '@angular/core';
import { DTO_patient_consommable } from '../types/DTO_patient-consommable.types';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { consommable, PatientConsommable } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientConsommablesAdapter implements BaseAdapter<DTO_patient_consommable, PatientConsommable> {
	adapt(data: DTO_patient_consommable): PatientConsommable {
		const consommable: consommable = {
			expirationDate: data.expirationDate,
			quantity: data.quantity,
			id: data.id,
			label: data.label,
			noticeUrl: data.noticeUrl,
			manufacturer: data.manufacturer,
			familyId: data.familyId,
			familyLabel: data.familyLabel,
			dossierId: data.dossierId,
			dossierDetailId: data.dossierDetailId,
			rentalDate: data.rentalDate,
			installationDate: data.installationDate,
			lastRevisionDate: data.lastRevisionDate,
			imageUrl: '',
		};

		return new PatientConsommable(consommable);
	}
}
