import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { map, Observable } from 'rxjs';
import { DTO_patient_observance } from '../types/DTO_patient-observance';
import { PatientObservanceAdapter } from '../adapter/patient-observance.adapter';
import { HttpParams } from '@angular/common/http';
import { patient_observance } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientObservanceRepository extends BaseHttpRepository {
	constructor(private readonly patientObservanceAdapter: PatientObservanceAdapter) {
		super();
	}

	/****** LITE SECTION *******/

	getPatientLiteObservanceFromPeriod(patientId: number, days: number) {
		return this.http
			.get<DTO_patient_observance>(`${this.apiUrl}patient/${patientId}/observance/lite/last/${days}`)
			.pipe(map((data: DTO_patient_observance) => this.patientObservanceAdapter.adapt(data)));
	}

	getPatientLiteObservanceFromDateToDate(patientId: number, param: { [key: string]: any }) {
		const params: HttpParams = this.generateHttpParams(param);
		return this.http
			.get<DTO_patient_observance>(`${this.apiUrl}patient/${patientId}/observance/lite/`, { params })
			.pipe(map((data: DTO_patient_observance) => this.patientObservanceAdapter.adapt(data)));
	}

	/****** FULL SECTION *******/

	getPatientFullObservanceFromPeriod(patientId: number, day: number): Observable<patient_observance> {
		return this.http.get<DTO_patient_observance>(`${this.apiUrl}patient/${patientId}/observance/last/${day}`).pipe(map((data: DTO_patient_observance) => this.patientObservanceAdapter.adapt(data)));
	}

	getPatientFullObservanceFromDateToDate(patientId: number, param: { [key: string]: any }) {
		const params: HttpParams = this.generateHttpParams(param);
		return this.http.get<DTO_patient_observance>(`${this.apiUrl}patient/${patientId}/observance/`, { params }).pipe(map((data: DTO_patient_observance) => this.patientObservanceAdapter.adapt(data)));
	}
}
