import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_patient_medical_device } from '../types/DTO_patient-medical-device';
import { PatientMedicalDevice, patientMedicalDevice } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientMedicalDeviceAdapter implements BaseAdapter<DTO_patient_medical_device, PatientMedicalDevice> {
	adapt(data: DTO_patient_medical_device): PatientMedicalDevice {
		const device: patientMedicalDevice = {
			hasFollowUps: data.hasFollowUps,
			retrievalDate: data.retrievalDate,
			modeId: data.modeId,
			modeLabel: data.modeLabel,
			parameters: data.parameters,
			parkId: data.parkId,
			parkCode: data.parkCode,
			serialNumber: data.serialNumber,
			id: data.id,
			label: data.label,
			noticeUrl: data.noticeUrl,
			manufacturer: data.manufacturer,
			familyId: data.familyId,
			familyLabel: data.familyLabel,
			dossierId: data.dossierId,
			dossierDetailId: data.dossierDetailId,
			rentalDate: data.rentalDate,
			installationDate: data.installationDate,
			lastRevisionDate: data.lastRevisionDate,
		};

		return new PatientMedicalDevice(device);
	}
}
