import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { HttpParams } from '@angular/common/http';
import { DTO_patient, DTO_patient_pairing_req, DTO_patientSearch } from '../types/DTO_patient.types';
import { map, Observable } from 'rxjs';
import { paginationResult } from '../../core/types/base.types';
import { paginatedResult } from '@application';
import { DocumentType, Patient, PatientCase, PatientSearch, uploadPatientDocumentParams } from '@domain';
import { PatientAdapter } from '../adapter/patient.adapter';
import { PatientSearchAdapter } from '../adapter/patient-search.adapter';
import { DTO_patient_case, DTO_patient_document_request, DTO_patient_document_types } from '../types/DTO_patient-document.types';
import { PatientDocumentTypesAdapter } from '../adapter/patient-document-types.adapter';
import { PatientCaseAdapter } from '../adapter/patient-case.adapter';
import { PatientUploadDocumentRequestAdapter } from '../adapter/patient-upload-document-request-adapter';

@Injectable({
	providedIn: 'root',
})
export class PatientsRepository extends BaseHttpRepository {
	constructor(
		private readonly patientAdapter: PatientAdapter,
		private readonly patientSearchAdapter: PatientSearchAdapter,
		private readonly patientDocumentTypesAdapter: PatientDocumentTypesAdapter,
		private readonly patientCaseAdapter: PatientCaseAdapter,
		private readonly patientUploadDocumentRequestAdapter: PatientUploadDocumentRequestAdapter,
	) {
		super();
	}

	searchPatients(param: { [key: string]: any }): Observable<paginatedResult<PatientSearch[]>> {
		const params: HttpParams = this.generateHttpParams({
			agencyId: param['agencyId'],
			birthDate: param['birthDate'],
			cityName: param['cityName'],
			firstName: param['firstName'],
			insee: param['insee'],
			lastName: param['lastName'],
			onlyActivePatients: param['onlyActivePatients'],
			pageNumber: param['page'],
			pageSize: param['limit'],
			phoneNumber: param['phoneNumber'],
			zipCode: param['zipCode'],
		});
		return this.http.get<paginationResult<DTO_patientSearch>>(`${this.apiUrl}patient/exactsearch`, { params }).pipe(
			map((patients: paginationResult<DTO_patientSearch>) => {
				return {
					data: patients.items.map(patient => this.patientSearchAdapter.adapt(patient)),
					pagination: {
						total: patients.count,
						page: patients.pageNumber,
						limit: patients.pageSize,
						nbTotalPage: Math.ceil(patients.count / patients.pageSize),
					},
				};
			}),
		);
	}

	getPatient(id: number): Observable<Patient> {
		return this.http.get<DTO_patient>(`${this.apiUrl}patient/${id}`).pipe(map((patient: DTO_patient) => this.patientAdapter.adapt(patient)));
	}

	pairingPatientApplication(request: DTO_patient_pairing_req): Observable<void> {
		const params: HttpParams = this.generateHttpParams(request);
		return this.http.post<void>(`${this.apiUrl}patient/${request.patientId}/pairing`, {}, { params });
	}

	attachDocumentToPatient(params: uploadPatientDocumentParams, patientCaseId: number): Observable<void> {
		//BUILD DATA
		const dataToSend: DTO_patient_document_request = this.patientUploadDocumentRequestAdapter.adapt(params);

		return this.http.post<void>(`${this.apiUrl}patient/document/${patientCaseId}`, dataToSend);
	}

	getPatientCase(patientId: number): Observable<PatientCase> {
		return this.http.get<DTO_patient_case>(`${this.apiUrl}patient/${patientId}/dossier/principal`).pipe(map((patientCaseDto: DTO_patient_case) => this.patientCaseAdapter.adapt(patientCaseDto)));
	}

	getPatientDocumentTypes(): Observable<DocumentType[]> {
		return this.http
			.get<DTO_patient_document_types[]>(`${this.apiUrl}patient/documents/categories`)
			.pipe(map((types: DTO_patient_document_types[]) => types.map(type => this.patientDocumentTypesAdapter.adapt(type))));
	}
}
