export enum techInterventionStatus {
	'ToPlan' = 1,
	'Planned' = 2,
	'Done' = 3,
	'Lunch' = 4,
	'Away' = 5,
}

export const getEnumTechInterventionStatusValueByKey = (str: string): techInterventionStatus => {
	return techInterventionStatus[str as keyof typeof techInterventionStatus];
};
