import { LoginResponse } from 'angular-auth-oidc-client';
import { BaseModel } from '../../core/base.model';

export type authenticatedUserData = {
	email: string;
	userId: string;
	firstname: string;
	lastname: string;
	azureId: string;
};

export class AuthenticatedUserData implements BaseModel<authenticatedUserData> {
	constructor(data: LoginResponse) {
		this._userId = data.userData.oid;
		this._email = data.userData.upn;
		this._firstname = data.userData.given_name;
		this._lastname = data.userData.family_name;
		this._azureId = data.userData.aud;
	}

	private _email: string;

	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
	}

	private _userId: string;

	get userId(): string {
		return this._userId;
	}

	set userId(value: string) {
		this._userId = value;
	}

	private _firstname: string;

	get firstname(): string {
		return this._firstname;
	}

	set firstname(value: string) {
		this._firstname = value;
	}

	private _lastname: string;

	get lastname(): string {
		return this._lastname;
	}

	set lastname(value: string) {
		this._lastname = value;
	}

	private _azureId: string;

	get azureId(): string {
		return this._azureId;
	}

	set azureId(value: string) {
		this._azureId = value;
	}

	asObject(): authenticatedUserData {
		return {
			userId: this.userId,
			email: this.email,
			firstname: this.firstname,
			lastname: this.lastname,
			azureId: this._azureId
		};
	}
}
