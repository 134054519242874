import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { patientDocument } from '@domain';
import { faArrowDown, faEye, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { BaseListComponent } from '../../../list/core/Base-list.component';

@Component({
	selector: 'lib-document-actions-cell',
	standalone: true,
	imports: [CommonModule, FaIconComponent],
	templateUrl: './document-actions-cell.component.html',
	styleUrls: ['./document-actions-cell.component.scss'],
})
export class DocumentActionsCellComponent extends BaseListComponent {
	public info!: patientDocument;
	public faEye: IconDefinition = faEye;
	public faArrowDown: IconDefinition = faArrowDown;

	protected params!: ICellRendererParams<patientDocument>;

	public agInit(params: ICellRendererParams<patientDocument>): void {
		this.params = params;
		this.info = params.data as patientDocument;
	}

	public refresh(params: ICellRendererParams<patientDocument>): boolean {
		return false;
	}

	public downloadDocument() {
		this.params.context.context.componentParent.actionTrigger({ action: 'downloadDocument', data: this.data });
	}

	public openDocument() {
		this.params.context.context.componentParent.actionTrigger({ action: 'openDocument', data: this.data });
	}
}
