import { BaseModel } from '../../core/base.model';
import { patientSearch } from '../../types/patient';

export class PatientSearch implements BaseModel<patientSearch> {
	constructor(data: patientSearch) {
		this._id = data.id;
		this._firstname = data.firstname;
		this._lastname = data.lastname;
		this._birthDate = data.birthdate;
		this._isActive = data.isActive;
		this._insee = data.insee;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _firstname: string;

	get firstname(): string {
		return this._firstname;
	}

	set firstname(value: string) {
		this._firstname = value;
	}

	private _lastname: string;

	get lastname(): string {
		return this._lastname;
	}

	set lastname(value: string) {
		this._lastname = value;
	}

	private _birthDate: string;

	get birthDate(): string {
		return this._birthDate;
	}

	set birthDate(value: string) {
		this._birthDate = value;
	}

	private _isActive: boolean;

	get isActive(): boolean {
		return this._isActive;
	}

	set isActive(value: boolean) {
		this._isActive = value;
	}

	private _insee: string;

	get insee(): string {
		return this._insee;
	}

	set insee(value: string) {
		this._insee = value;
	}

	asObject(): patientSearch {
		return {
			id: this._id,
			firstname: this._firstname,
			lastname: this._lastname,
			birthdate: this._birthDate,
			isActive: this._isActive,
			insee: this._insee,
		};
	}
}
