import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '@infrastructure';
import { map, Observable } from 'rxjs';
import { Assure } from '@domain';
import { AssureAdapter } from '../adapter/assure.adapter';
import { DTO_assure } from '../types/DTO_insured.type';

@Injectable({
	providedIn: 'root',
})
export class AssureRepository extends BaseHttpRepository {
	constructor(private readonly assureAdapter: AssureAdapter) {
		super();
	}

	getPatientAssure(id: number): Observable<Assure> {
		return this.http.get<DTO_assure>(`${this.apiUrl}patient/${id}/assure`).pipe(map((data: DTO_assure) => this.assureAdapter.adapt(data)));
	}
}
