import { Injectable } from '@angular/core';
import { HasStore } from '../store/has-store';
import { AppError } from '@domain';

export type ErrorManagerState = {
	errors: AppError[];
};

@Injectable({
	providedIn: 'root',
})
export default class ErrorManager extends HasStore<ErrorManagerState> {
	constructor() {
		super(
			{
				errors: [],
			},
			'ErrorManager',
		);
	}

	declareError(error: AppError): void {
		this.store.update(state => {
			return {
				...state,
				errors: [...state.errors, error],
			};
		});
	}

	setErrorDisplayed(error: AppError): void {
		this.store.update(state => {
			const clone = [...state.errors];
			clone.forEach(e => {
				if (e.id === error.id) {
					e.hasBeenDeclared = true;
				}
			});
			return {
				...state,
				errors: clone,
			};
		});
	}
}
