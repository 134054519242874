import { inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs';
import { PaginationManager } from '@application';
import { environment } from '../../../../../../global-environment/environment';

export abstract class BaseHttpRepository {
	protected businessUrl = `${environment.business}v1`;
	protected legacyUrl = `${environment.legacy}v1`;
	protected apiUrl: string = environment.api;
	protected vnextSecurityUrl: string = environment.vnextSecurity;
	protected appointmentUrl: string = environment.appointment;
	protected orthopUrl: string = environment.orthop;

	protected http: HttpClient = inject(HttpClient);
	protected paginationManager = inject(PaginationManager);

	paginatedGet<R>(url: string, params: { [key: string]: unknown }, headers?: { [key: string]: string }) {
		return this.http
			.get<R>(url, {
				params: this.generateHttpParams(params),
				observe: 'response',
				headers,
			})
			.pipe(
				catchError(error => {
					throw error;
				}),
				map(response => this.paginationManager.extractPaginationFromRequest<R>(response)),
			);
	}

	protected generateHttpParams(params?: { [key: string]: any }) {
		let httpParams = new HttpParams();
		if (params && Object.keys(params).length) {
			for (const key in params) {
				if (params[key] !== null && params[key] !== '' && params[key] !== undefined) {
					httpParams = httpParams.append(key, params[key]);
				}
			}
		}
		return httpParams;
	}
}
