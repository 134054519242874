import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormComponent } from '../../core';
import { checkbox } from './checkbox.types';
import { FormsModule } from '@angular/forms';

@Component({
	selector: 'intranet-checkbox',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './checkbox.component.html',
	styleUrl: './checkbox.component.scss',
})
export class CheckboxComponent extends BaseFormComponent<checkbox> implements OnInit {
	state!: boolean;

	updateState() {
		this.onChange(!this.state);
		this.onTouched();
	}

	ngOnInit() {
		this.disabled = this.config().isDisabled;
	}
}
