import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '../../../directives';
import { options } from '../core/form.types';
import { autocompleteFieldTypes } from './autocomplete-field.types';
import { BaseFormComponent } from '../../core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCircleXmark, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'intranet-autocomplete-field',
	standalone: true,
	imports: [CommonModule, FaIconComponent, ClickOutsideDirective],
	templateUrl: './autocomplete-field.component.html',
	styleUrl: './autocomplete-field.component.scss',
})
export class AutocompleteFieldComponent extends BaseFormComponent<autocompleteFieldTypes> implements OnInit {
	areSuggestionsVisible: boolean = false;
	protected readonly faCircleXmark: IconDefinition = faCircleXmark;

	get className() {
		if (this.config().isDisabled && this.isError) {
			return 'disabled error';
		} else if (this.config().isDisabled && !this.isError) {
			return 'disabled';
		} else if (!this.config().isDisabled && this.isError) {
			return 'error';
		}

		return '';
	}

	clearField() {
		this.control.reset();
	}

	selectSuggestion(item: options) {
		this.control.setValue(item);
		this.onChange(item);
		this.onTouched();
		this.areSuggestionsVisible = false;
	}

	ngOnInit() {
		this.disabled = this.config().isDisabled;
	}

	protected getChange(event: any) {
		if (event.target.value) {
			this.onChange({ label: event.target.value });
		} else {
			this.onChange(null);
		}
		this.onTouched();
	}
}
