import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_order, DTO_order_creation, DTO_order_search_request } from '../../types/DTO_order.types';
import { Order } from '@domain';


@Injectable({
	providedIn: 'root',
})
export class OrderUpdateAdapter implements BaseAdapter<Order, DTO_order> {
	adapt(order: Order): DTO_order {
		return {
			id: order.id,
			intervenantId: order.intervenantId,
			articles: order.items.map(item => {
				return {
					articleId: item.articleId!,
					label: item.name,
					quantity: item.quantity,
				}
			}),
			deliveryAddress: {

				street: order.deliveryAddress.street,
				building: order.deliveryAddress.building,
				cityCode: order.deliveryAddress.cityCode,
				cityName: order.deliveryAddress.cityName,
			},
			comments: order.comment,
			agencyId: order.agency.id,
			agencyName: order.agency.name
		}
	}
}
