import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_pdm_search_result } from '../../types/DTO_pdm.types';
import { MedicalDevicePiece, Patient, Store, storeType } from '@domain';
import { getEnumKeyByValue } from '../../../core/types/enums-helpers';

@Injectable({
	providedIn: 'root',
})
export class PDMSearchAdapter implements BaseAdapter<DTO_pdm_search_result, MedicalDevicePiece> {
	adapt(data: DTO_pdm_search_result): MedicalDevicePiece {
		return new MedicalDevicePiece({
			name: data.label,
			parkCode: data.codeParc,
			serialNumber: data.serialNumber,
			state: data.state,
			patient: data.patient
				? new Patient({
						id: data.patient.id,
						civility: null,
						firstname: data.patient.firstName,
						lastname: data.patient.lastName,
						patronym: null,
						agencyId: null,
						birthDate: null,
						isActive: null,
						insee: null,
						size: null,
						hasMobileAccess: null,
						hasTelesuivieConsentement: null,
						hasGroupingVisibility: null,
						hasAld: null,
						hasLivretFFAIR: null,
						hasNoPrescription: null,
						age: null,
						agencyIdV1: null,
						agencyName: null,
						birthDateAtypic: null,
						comment: null,
						dateCreation: null,
						deathDate: null,
						dossierId: null,
						emailMobile: null,
						familyDoctorId: null,
						finalized: null,
						gender: null,
						iahValue: null,
						isHidden: null,
						isMainDossier: null,
						isMobilePatient: null,
						patientIdV2: null,
						prescriberDoctorId: null,
						typeId: null,
						typeLabel: null,
						typeSpecificId: null,
						typeSpecificLabel: null,
						treatment: [],
						addresses: [],
					})
				: null,
			magasin: data.magasin
				? new Store({
						id: data.magasin.id,
						name: data.magasin.name,
						storeAgency: {
							id: data.magasin.agencyId,
							name: data.magasin.agencyName,
						},
						storeType: storeType[getEnumKeyByValue(data.magasin.magasinType, storeType) as keyof typeof storeType],
						operator: null,
						isDisabled: data.magasin.isDisabled,
					})
				: null,
			idParc: null,
			familleCode: null,
			familleArticleId: null,
			familleArticleLabel: null,
			fournisseurId: null,
			fournisseurName: null,
			purchaseDate: null,
			firstCommissioning: null,
			warrantyEndDate: null,
			controlDate: null,
			transferHistory: null,
			articleId: data.idArticle,
		});
	}
}
