import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { paginatedResult } from './pagination-types';

@Injectable({
	providedIn: 'root',
})
export class PaginationManager {
	extractPaginationFromRequest<R>(result: HttpResponse<R>): paginatedResult<R> {
		const pagination = this.findPaginationHeader(result.headers);
		return {
			data: result.body as R,
			pagination,
		};
	}

	checkPaginationHeader(headers: HttpHeaders): boolean {
		return headers.has('sos-page-item-count') && headers.has('sos-page-number') && headers.has('sos-page-size');
	}

	private findPaginationHeader(headers: HttpHeaders) {
		if (!this.checkPaginationHeader(headers)) {
			throw new Error('Pagination headers not found');
		}

		const total = headers.get('sos-page-item-count');
		const page = headers.get('sos-page-number');
		const limit = headers.get('sos-page-size');
		return {
			total: total ? parseInt(total, 10) : 0,
			page: page ? parseInt(page, 10) : 0,
			limit: limit ? parseInt(limit, 10) : 0,
			nbTotalPage: total && limit ? Math.ceil(parseInt(total, 10) / parseInt(limit, 10)) : 0,
		};
	}
}
