import { BaseModel } from '../../../core/base.model';

export type orderItem = {
	articleId: number | null;
	name: string
	quantity: number;
};

export class OrderItem implements BaseModel<orderItem> {
	constructor(item: orderItem) {

		this._articleId = item.articleId;
		this._name = item.name;
		this._quantity = item.quantity;

	}

	private _articleId: number | null;

	get articleId(): number | null {
		return this._articleId;
	}

	set articleId(value: number | null) {
		this._articleId = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}


	private _quantity: number;

	get quantity(): number {
		return this._quantity;
	}

	set quantity(value: number) {
		this._quantity = value;
	}


	asObject(): orderItem {
		return {
			articleId: this.articleId,
			name: this.name,
			quantity: this.quantity
		};
	}


}
