import { Injectable } from '@angular/core';
import { DTO_preference } from '../types/DTO_preference.types';
import { Observable, of } from 'rxjs';
import { Preference } from '@domain';
import { PreferenceAdapter } from '../adapter/preference.adapter';

@Injectable({
	providedIn: 'root',
})
export class PreferenceRepository {
	constructor(private readonly prefAdapter: PreferenceAdapter) {}

	savePreferences(data: string) {
		localStorage.setItem('preference', data);
	}

	getPreferences(): Observable<Preference> {
		const userPreference: DTO_preference = localStorage.getItem('preference') ? JSON.parse(localStorage.getItem('preference') as string) : null;
		if (userPreference) {
			return of(this.prefAdapter.adapt(userPreference));
		}
		return of({} as Preference);
	}
}
