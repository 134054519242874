import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import { prescription } from '@domain';

@Component({
	selector: 'lib-prescription-state-cell',
	standalone: true,
	imports: [CommonModule, FaIconComponent],
	templateUrl: './prescription-state-cell.component.html',
	styleUrls: ['./prescription-state-cell.component.scss'],
})
export class PrescriptionStateCellComponent implements ICellRendererAngularComp {
	public data!: prescription;
	public stateStyle: string = '';
	private params!: ICellRendererParams<prescription>;

	public agInit(params: ICellRendererParams<prescription>): void {
		this.params = params;
		this.data = params.data as prescription;
		switch (this.data.state) {
			case 'Ok':
				this.stateStyle = 'prescription-valid';
				break;
			case 'EnRetard':
				this.stateStyle = 'prescription-late';
				break;
			case 'ASigner':
				this.stateStyle = 'prescription-sign';
				break;
			case 'AVenir':
				this.stateStyle = 'prescription-comming';
				break;
			case 'EnValidation':
				this.stateStyle = 'prescription-validating';
				break;
			case 'AControler':
				this.stateStyle = 'prescription-control';
				break;
		}
	}

	public refresh(params: ICellRendererParams<prescription>): boolean {
		return false;
	}
}
