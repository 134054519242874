import { BaseModel } from '../../core/base.model';

export type doctor = {
	id: string;
	rpps: string;
	typeId: number;
	typeLabel: string;
	titleId: number;
	titleLabel: string;
	lastname: string;
	firstname: string;
	fullname: string;
	email: string;
	phone: string;
	mobile: string;
	fax: string;
	isActive: boolean;
	accountIdExtranetV2?: string;
	hasAccountExtranetV1: boolean;
	appUsername?: string;
	establishments: any[];
};

export class Doctor implements BaseModel<doctor> {
	constructor(data: doctor) {
		this._id = data.rpps;
		this._rpps = data.rpps;
		this._typeId = data.typeId;
		this._typeLabel = data.typeLabel;
		this._titleId = data.titleId;
		this._titleLabel = data.titleLabel;
		this._lastname = data.lastname;
		this._firstname = data.firstname;
		this._fullname = data.fullname;
		this._email = data.email;
		this._phone = data.phone;
		this._mobile = data.mobile;
		this._fax = data.fax;
		this._isActive = data.isActive;
		this._accountIdExtranetV2 = data.accountIdExtranetV2;
		this._hasAccountExtranetV1 = data.hasAccountExtranetV1;
		this._appUsername = data.appUsername;
		this._establishments = data.establishments;
	}

	private _id: string;

	get id(): string {
		return this._id;
	}

	set id(value: string) {
		this._id = value;
	}

	private _rpps: string;

	get rpps(): string {
		return this._rpps;
	}

	set rpps(value: string) {
		this._rpps = value;
	}

	private _typeId: number;

	get typeId(): number {
		return this._typeId;
	}

	set typeId(value: number) {
		this._typeId = value;
	}

	private _typeLabel: string;

	get typeLabel(): string {
		return this._typeLabel;
	}

	set typeLabel(value: string) {
		this._typeLabel = value;
	}

	private _titleId: number;

	get titleId(): number {
		return this._titleId;
	}

	set titleId(value: number) {
		this._titleId = value;
	}

	private _titleLabel: string;

	get titleLabel(): string {
		return this._titleLabel;
	}

	set titleLabel(value: string) {
		this._titleLabel = value;
	}

	private _lastname: string;

	get lastname(): string {
		return this._lastname;
	}

	set lastname(value: string) {
		this._lastname = value;
	}

	private _firstname: string;

	get firstname(): string {
		return this._firstname;
	}

	set firstname(value: string) {
		this._firstname = value;
	}

	private _fullname: string;

	get fullname(): string {
		return this._fullname;
	}

	set fullname(value: string) {
		this._fullname = value;
	}

	private _email: string;

	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
	}

	private _phone: string;

	get phone(): string {
		return this._phone;
	}

	set phone(value: string) {
		this._phone = value;
	}

	private _mobile: string;

	get mobile(): string {
		return this._mobile;
	}

	set mobile(value: string) {
		this._mobile = value;
	}

	private _fax: string;

	get fax(): string {
		return this._fax;
	}

	set fax(value: string) {
		this._fax = value;
	}

	private _isActive: boolean;

	get isActive(): boolean {
		return this._isActive;
	}

	set isActive(value: boolean) {
		this._isActive = value;
	}

	private _accountIdExtranetV2?: string;

	get accountIdExtranetV2(): string | undefined {
		return this._accountIdExtranetV2;
	}

	set accountIdExtranetV2(value: string | undefined) {
		this._accountIdExtranetV2 = value;
	}

	private _hasAccountExtranetV1: boolean;

	get hasAccountExtranetV1(): boolean {
		return this._hasAccountExtranetV1;
	}

	set hasAccountExtranetV1(value: boolean) {
		this._hasAccountExtranetV1 = value;
	}

	private _appUsername?: string;

	get appUsername(): string | undefined {
		return this._appUsername;
	}

	set appUsername(value: string) {
		this._appUsername = value;
	}

	private _establishments: any[];

	get establishments(): any[] {
		return this._establishments;
	}

	set establishments(value: any[]) {
		this._establishments = value;
	}

	get accessDisplay(): string {
		return this.accountIdExtranetV2!! ? 'V2' : this.hasAccountExtranetV1 ? 'V1' : "Pas d'accès";
	}

	asObject(): doctor {
		return {
			id: this.id,
			rpps: this._rpps,
			typeId: this._typeId,
			typeLabel: this._typeLabel,
			titleId: this._titleId,
			titleLabel: this._titleLabel,
			lastname: this._lastname,
			firstname: this._firstname,
			fullname: this._fullname,
			email: this._email,
			phone: this._phone,
			mobile: this._mobile,
			fax: this._fax,
			isActive: this._isActive,
			accountIdExtranetV2: this._accountIdExtranetV2,
			hasAccountExtranetV1: this._hasAccountExtranetV1,
			appUsername: this._appUsername,
			establishments: this._establishments,
		};
	}
}
