import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_template_store_article } from '../../types/DTO_template-store.types';
import { Article } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class ArticleAdapter implements BaseAdapter<DTO_template_store_article, Article> {
	adapt(data: DTO_template_store_article): Article {
		return new Article({
			id: data.articleId,
			name: data.articleName ? data.articleName : '',
			location: null,
			min: data.minLimit != null ? data.minLimit : -1,
			max: data.maxLimit != null ? data.maxLimit : -1,
		});
	}
}
