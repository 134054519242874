import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { faChevronRight, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterLink } from '@angular/router';
import { BaseComponent } from '../core';
import { takeUntil, tap } from 'rxjs';

@Component({
	selector: 'intranet-breadcrumb',
	standalone: true,
	imports: [CommonModule, FaIconComponent, RouterLink],
	templateUrl: './breadcrumb.component.html',
	styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent extends BaseComponent implements OnInit, OnDestroy {
	readonly faHouse = faHouse;
	readonly faChevronRight = faChevronRight;

	breadcrumb: { labels: string[], path: string } = { labels: [], path: '' };

	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
	) {
		super();
	}

	setLabels(arrayUrl: string[]) {
		let lastLabel = 'information';
		if (this.breadcrumb.labels[length - 1] !== 'information' && arrayUrl.length > 3) {
			lastLabel = arrayUrl[arrayUrl.length - 1].split('-').join(' ').toLowerCase();
			this.breadcrumb.labels[this.breadcrumb.labels.length - 1] = lastLabel;
		}

	}

	ngOnInit() {
		this.route.data
			.pipe(
				takeUntil(this.$unsubscribe),
				tap(data => {
					if (data) {
						this.breadcrumb = data['breadcrumb'];
					}
				}),
			)
			.subscribe();

		this.router.events
			.pipe(
				takeUntil(this.$unsubscribe),
				tap(event => {

					if (event instanceof NavigationStart) {
						this.setLabels(['Informations']);
					}

					if (event instanceof NavigationEnd) {
						const arrayUrl = event.url.split('/').map(url => url.split('?')[0]);
						if (arrayUrl[arrayUrl.length - 1].split('?')[0] !== this.breadcrumb.labels[this.breadcrumb.labels.length - 1] && arrayUrl.length > 1) {							
							this.setLabels(arrayUrl);
						}
					}
				}),
			)
			.subscribe();
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
		this.breadcrumb = { labels: [], path: '' };
	}

}
