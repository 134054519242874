<div #selectField (click)="displayList()" [class]="listIsVisible ? 'open' : 'close'" [ngClass]="isError ? 'error' : ''"
	class="select-field" intranetUtilities [size]="size">



	<label>
		<span>
			{{ label() }}
			@if (config().isRequired) {
			<span class="sos-required-file">*</span>
			}
		</span>

		@if(isNotEmpty && clearable){
		<fa-icon class="align-self-center close-icon" (click)="clear()" [icon]="faClose"></fa-icon>
		}


		<fa-icon [icon]="faChevronDown"></fa-icon>
	</label>

	<ul #list (outSideClick)="listIsVisible = false" [appOutSideClick]="listIsVisible" [class.close]="!listIsVisible"
		[class.open]="listIsVisible" [id]="config().label" class="select-list" intranetClickOutside>
		@for (item of config().options; track item.label) {
		<li (click)="selectListItem(item)" class="select-list_item">
			{{ item.label }}
		</li>
		}
	</ul>


</div>


@if (isError && config().errorMessages) {
<div class="error mt-small">
	<small>{{ config().errorMessages }}</small>
</div>
}