import { Component, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-body-modal',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './body-modal.component.html',
	styleUrl: './body-modal.component.scss',
})
export class BodyModalComponent {
	title: InputSignal<string> = input.required<string>();
}
