import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_preference } from '../types/DTO_preference.types';
import { Agency, Preference } from '@domain';

type agencyStringified = {
	_id: number;
	_name: string;
};

@Injectable({
	providedIn: 'root',
})
export class PreferenceAdapter implements BaseAdapter<DTO_preference, Preference> {
	adapt(data: DTO_preference): Preference {
		const userPreference = {
			navigation: data.navigation,
			agency: data.agency
				? new Agency({
						id: (data.agency as unknown as agencyStringified)._id,
						name: (data.agency as unknown as agencyStringified)._name,
					})
				: null,
		};

		return new Preference(userPreference);
	}
}
