import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-footer-modal',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './footer-modal.component.html',
	styleUrl: './footer-modal.component.scss',
})
export class FooterModalComponent {}
