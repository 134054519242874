import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { DTO_patient_medical_device } from '../types/DTO_patient-medical-device';
import { PatientMedicalDeviceAdapter } from '../adapter/patient-medical-device.adapter';
import { map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PatientMedicalDeviceRepository extends BaseHttpRepository {
	constructor(private readonly patientMedicalDeviceAdapter: PatientMedicalDeviceAdapter) {
		super();
	}

	getPatientMedicalDevice(patientId: number) {
		return this.http
			.get<DTO_patient_medical_device[]>(`${this.apiUrl}patient/${patientId}/article/devices`, { headers: { 'X-Loader-Key': 'patient-medical-devices' } })
			.pipe(map((devices: DTO_patient_medical_device[]) => devices.map(device => this.patientMedicalDeviceAdapter.adapt(device))));
	}

	getPatientMedicalDeviceByActiv(patientId: number, isActive: boolean) {
		return this.http
			.get<DTO_patient_medical_device[]>(`${this.apiUrl}patient/${patientId}/article/devices`, {
				headers: { 'X-Loader-Key': 'patient-medical-devices' },
				params: { OnlyCurrent: isActive },
			})
			.pipe(map((devices: DTO_patient_medical_device[]) => devices.map(device => this.patientMedicalDeviceAdapter.adapt(device))));
	}
}
