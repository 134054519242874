import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { DTO_patient_address } from '../types/DTO_patient-address.types';
import { map, Observable } from 'rxjs';
import { PatientAddressAdapter } from '../adapter/patient-address.adapter';
import { PatientAddress } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientAddressRepository extends BaseHttpRepository {
	constructor(private readonly patientAddressAdapter: PatientAddressAdapter) {
		super();
	}

	getPatientAddresses(patientId: number): Observable<PatientAddress[]> {
		return this.http
			.get<DTO_patient_address[]>(`${this.apiUrl}patient/${patientId}/addresses`, {
				headers: {
					'X-Loader-Key': 'get-patient-addresses',
				},
			})
			.pipe(map((addresses: DTO_patient_address[]) => addresses.map((address: DTO_patient_address) => this.patientAddressAdapter.adapt(address))));
	}
}
