<div
	[ngClass]="className"
	class="sos-form-control">
	@if (config().isRequired) {
		<span class="sos-required-file">*</span>
	}

	@if (isError && config().errorMessages) {
		<small>{{ config().errorMessages }}</small>
	}

	<label
		[ngClass]="isNotEmpty ? 'floating' : ''"
		class="sos-form-control_label"
		>{{ config().label }}
	</label>

	<input
		(input)="getChange($event)"
		[disabled]="disabled"
		type="text"
		[value]="control.value?.label"
		(focus)="areSuggestionsVisible = true"
		class="sos-form-control_input" />

	@if (isNotEmpty) {
		<fa-icon
			(click)="clearField()"
			[icon]="faCircleXmark"
			class="clear-field">
		</fa-icon>
	}

	<ng-content></ng-content>

	@if (this.config().suggestions && areSuggestionsVisible) {
		<ul
			class="select-list"
			intranetClickOutside
			[appOutSideClick]="areSuggestionsVisible"
			(outSideClick)="areSuggestionsVisible = false">
			@for (item of config().suggestions; track item.label) {
				<li
					(click)="selectSuggestion(item)"
					class="select-list_item">
					{{ item.label }}
				</li>
			}
		</ul>
	}
</div>
