import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCircleCheck, faCircleXmark, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'intranet-state-formateur',
	standalone: true,
	imports: [CommonModule, FaIconComponent],
	template: '<fa-icon [class]="currentState" [icon]="currentIconStare"></fa-icon>',
	styleUrl: './state-formateur.component.scss',
})
export class StateFormateurComponent implements ICellRendererAngularComp {
	currentIconStare: IconDefinition = faCircleCheck;
	state = 'success';

	get currentState() {
		return this.state;
	}

	set currentState(value: string) {
		this.state = value;
	}

	agInit(params: ICellRendererParams): void {
		if (!params.data.isActive) {
			this.currentIconStare = faCircleXmark;
			this.currentState = 'error';
		} else {
			this.currentState = 'success';
		}
	}

	refresh(params: ICellRendererParams<any>): boolean {
		return false;
	}
}
