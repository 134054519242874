import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, Observable, tap } from 'rxjs';
import { LoadingListenerManager } from '@application';

@Injectable()
export class LoadingListenerInterceptor implements HttpInterceptor {
	constructor(private loadingListenerManager: LoadingListenerManager) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const loaderKey = request.headers.get('X-Loader-Key');
		if (loaderKey) {
			this.loadingListenerManager.toggleLoadingListener(loaderKey, true);
		}

		return next.handle(request).pipe(
			catchError((err: HttpErrorResponse) => {
				if (loaderKey) {
					this.loadingListenerManager.toggleLoadingListener(loaderKey, false);
				}
				return next.handle(request);
			}),
			tap(event => {
				if (event instanceof HttpResponse && loaderKey) {
					this.loadingListenerManager.toggleLoadingListener(loaderKey, false);
				}
			}),
		);
	}
}
