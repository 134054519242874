import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import {
	DTO_piece_medical_device,
	DTO_piece_medical_device_history,
	DTO_store,
	DTO_store_consommable,
	DTO_store_definition_item,
	DTO_store_dm,
	DTO_store_search_request,
} from '../types/DTO_store.types';
import { map, Observable } from 'rxjs';
import StoreMedicalConsommableAdapter from '../adapters/store/store-medical-consommable.adapter';
import { ToastrService } from 'ngx-toastr';
import MedicalDevicePieceAdapter from '../adapters/medical-device/medicalDevicePiece.adapter';
import MedicalDevicePieceHistoryAdapter from '../adapters/medical-device/medicalDevicePieceHistory.adapter';
import StoreSearchRequestAdapter from '../adapters/store/store-search-request-adapter';
import StoreCreationrequestRequestAdapter from '../adapters/store/store-creation-request-adapter';
import StoreEditRequestAdapter from '../adapters/store/store-edit-request-adapter';
import StoreArticleCreationRequestAdapter from '../adapters/store/store-article-creation-adapter';
import StoreMedicalDeviceCreationRequestAdapter from '../adapters/store/store-medical-device-creation-request.adapter';
import StoreTransferMedicalDeviceRequestAdapter from '../adapters/store/store-transfer-medical-device-request-adapter';
import { HttpContext } from '@angular/common/http';
import { CUSTOM_ERROR_MSG } from '../../core/interceptors/http-errors.interceptor';
import {
	addStoreDefinitionItemParams,
	addStoreDMParams,
	Article,
	createStoreParams,
	editStoreParams,
	MedicalConsommable,
	MedicalDevice,
	MedicalDevicePiece,
	MedicalDeviceTransfer,
	searchStoresParams,
	Store,
	transfertDMParams,
} from '@domain';
import { paginatedResult, pagination } from '@application';
import { StoreAdapter } from '../adapters/store/store.adapter';
import { StoreDefinitionAdapter } from '../adapters/store/store-definition.adapter';
import { StoreMedicalDevicePiecesAdapter } from '../adapters/store/store-medical-device-pieces.adapter';

@Injectable({ providedIn: 'root' })
export class StoreRepository extends BaseHttpRepository {
	constructor(
		private readonly storeAdapter: StoreAdapter,
		private readonly storeDefinitionAdapter: StoreDefinitionAdapter,
		private readonly storeMedicalConsommableAdapter: StoreMedicalConsommableAdapter,
		private readonly storeMedicalDevicePiecesAdapter: StoreMedicalDevicePiecesAdapter,
		private readonly toastrService: ToastrService,
		private readonly medicalDevicePieceAdapter: MedicalDevicePieceAdapter,
		private readonly medicalDevicePieceHistoryAdapter: MedicalDevicePieceHistoryAdapter,
		private readonly storeSearchrequestAdapter: StoreSearchRequestAdapter,
		private readonly storeCreationRequestAdapter: StoreCreationrequestRequestAdapter,
		private readonly storeEditRequestAdapter: StoreEditRequestAdapter,
		private readonly storeArticleCreationAdapter: StoreArticleCreationRequestAdapter,
		private readonly storeMedicalArticleCreationrequestAdapter: StoreMedicalDeviceCreationRequestAdapter,
		private readonly storeTransferMedicalDevicerequestAdapter: StoreTransferMedicalDeviceRequestAdapter,
	) {
		super();
	}

	findStoreById(id: number): Observable<Store> {
		return this.http.get<DTO_store>(`${this.apiUrl}stock/magasin/${id}`).pipe(map((data: DTO_store) => this.storeAdapter.adapt(data)));
	}

	listStores(params: searchStoresParams, pagination: pagination): Observable<paginatedResult<Store[]>> {
		const dataToSend: DTO_store_search_request = this.storeSearchrequestAdapter.adapt({
			pagination: pagination,
			params: params,
		});

		return this.paginatedGet<DTO_store[]>(`${this.apiUrl}Stock/Magasin/Search`, dataToSend, { 'X-Loader-Key': 'stores' }).pipe(
			map((data: paginatedResult<DTO_store[]>) => {
				// SPECIFIC MESSAGE WHEN NO DATA
				if (data.data.length == 0) {
					this.toastrService.warning("Aucun magasin correspondant à vos critères de recherche n'a été trouvé", '', {
						timeOut: 3000,
						closeButton: true,
						progressBar: true,
					});
				}

				return {
					data: data.data.map(store => this.storeAdapter.adapt(store)),
					pagination: data.pagination,
				};
			}),
		);
	}

	getStoreConsommables(id: number): Observable<MedicalConsommable[]> {
		return this.http
			.get<DTO_store_consommable[]>(`${this.apiUrl}Stock/Magasins/${id}/Consommables`)
			.pipe(map((consos: DTO_store_consommable[]) => consos.map(conso => this.storeMedicalConsommableAdapter.adapt(conso))));
	}

	getStoreDMs(id: number): Observable<MedicalDevice[]> {
		return this.http.get<DTO_store_dm[]>(`${this.apiUrl}Stock/Magasins/${id}/PiecesDispositifsMedicaux`).pipe(map((dms: DTO_store_dm[]) => this.storeMedicalDevicePiecesAdapter.adapt(dms)));
	}

	editStore(values: editStoreParams, storeId: number): Observable<void> {
		const dataToSend = this.storeEditRequestAdapter.adapt(values);
		return this.http.put<void>(`${this.apiUrl}Stock/Magasin/${storeId}`, dataToSend);
	}

	getStoreDefinition(id: number): Observable<Article[]> {
		return this.http
			.get<DTO_store_definition_item[]>(`${this.apiUrl}Stock/Magasin/${id}/MagasinDefinition`)
			.pipe(map((items: DTO_store_definition_item[]) => items.map(item => this.storeDefinitionAdapter.adapt(item))));
	}

	addStoreDefinitionItem(id: number, params: addStoreDefinitionItemParams): Observable<void> {
		const dataToSend = this.storeArticleCreationAdapter.adapt(params);
		return this.http.post<void>(`${this.apiUrl}Stock/Magasin/${id}/MagasinDefinition`, dataToSend, {
			headers: {
				'X-Loader-Key': 'add-store-article',
			},
		});
	}

	deleteStoreDefinitionItem(storeId: number, articleId: number): Observable<void> {
		return this.http.delete<void>(`${this.apiUrl}Stock/Magasin/${storeId}/MagasinDefinition/${articleId}`);
	}

	addStoreDM(storeId: number, params: addStoreDMParams): Observable<void> {
		const dataToSend = this.storeMedicalArticleCreationrequestAdapter.adapt({
			params: params,
			storeId: storeId,
		});
		return this.http.post<void>(`${this.apiUrl}Stock/Magasin/${storeId}/PiecesDispositifsMedicaux`, dataToSend, {
			headers: {
				'X-Loader-Key': 'add-medical-device',
			},
			context: new HttpContext().set(CUSTOM_ERROR_MSG, true),
		});
	}

	transfertDM(params: transfertDMParams): Observable<void> {
		const dataToSend = this.storeTransferMedicalDevicerequestAdapter.adapt(params);
		return this.http.post<void>(`${this.apiUrl}Stock/medical-devices/movements`, dataToSend, {
			headers: {
				'X-Loader-Key': 'transfert-medical-device',
			},
		});
	}	

	editStoreDefinitionItem(storeId: number, params: addStoreDefinitionItemParams): Observable<void> {
		const dataToSend = this.storeArticleCreationAdapter.adapt(params);
		return this.http.put<void>(`${this.apiUrl}Stock/Magasin/${storeId}/MagasinDefinition/${dataToSend.articleId}`, dataToSend);
	}

	createStore(request: createStoreParams): Observable<number> {
		const dataToSend = this.storeCreationRequestAdapter.adapt(request);
		return this.http.post<number>(`${this.apiUrl}Stock/Magasin`, dataToSend);
	}

	getPieceMedicalDeviceDetails(serialNumberOrCodeParc: string): Observable<MedicalDevicePiece> {
		return this.http
			.get<DTO_piece_medical_device>(`${this.apiUrl}Stock/PiecesDispositifsMedicaux/${serialNumberOrCodeParc}`, {
				headers: { 'X-Loader-Key': 'pdm-historic' },
			})
			.pipe(map((pdm: DTO_piece_medical_device) => this.medicalDevicePieceAdapter.adapt(pdm)));
	}

	getPieceMedicalDeviceHistory(serialNumberOrCodeParc: string): Observable<MedicalDeviceTransfer[]> {
		return this.http
			.get<DTO_piece_medical_device_history[]>(`${this.apiUrl}Stock/PiecesDispositifsMedicaux/${serialNumberOrCodeParc}/History`)
			.pipe(map((pdms: DTO_piece_medical_device_history[]) => pdms.map(pdm => this.medicalDevicePieceHistoryAdapter.adapt(pdm))));
	}

	disableStore(storeId: number, value: boolean): Observable<boolean> {
		return this.http.put<boolean>(`${this.apiUrl}Stock/Magasin/${storeId}/Enable`, value);
	}
}
