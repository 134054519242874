import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_delete_template_store_article } from '../../types/DTO_template-store.types';

@Injectable({
	providedIn: 'root',
})
export default class TemplateStoreDeleteRequestAdapter
	implements
		BaseAdapter<
			{
				templateStoreId: number;
				articleId: number;
			},
			DTO_delete_template_store_article
		>
{
	adapt(data: { templateStoreId: number; articleId: number }): DTO_delete_template_store_article {
		const dto: DTO_delete_template_store_article = {
			magasinTemplateId: data.templateStoreId,
			articleId: data.articleId,
		};

		return dto;
	}
}
