import { BaseModel } from '../../../core/base.model';

export type medicalConsommable = {
	articleId: number;
	name: string;
	location: string;
	min: number;
	max: number;
	quantity: number;
	price: number;
	peremptionDate: string;
};

export class MedicalConsommable implements BaseModel<medicalConsommable> {
	constructor(conso: medicalConsommable) {
		this._articleId = conso.articleId;
		this._name = conso.name;
		this._location = conso.location;
		this._quantity = conso.quantity;
		this._price = conso.price;
		this._min = conso.min;
		this._max = conso.max;
		this._peremptionDate = conso.peremptionDate;
	}

	private _articleId: number;

	get articleId(): number {
		return this._articleId;
	}

	set articleId(value: number) {
		this._articleId = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	private _location: string;

	get location(): string {
		return this._location;
	}

	set location(value: string) {
		this._location = value;
	}

	private _quantity: number;

	get quantity(): number {
		return this._quantity;
	}

	set quantity(value: number) {
		this._quantity = value;
	}

	private _price: number;

	get price(): number {
		return this._price;
	}

	set price(value: number) {
		this._price = value;
	}

	private _min: number;

	get min(): number {
		return this._min;
	}

	set min(value: number) {
		this._min = value;
	}

	private _max: number;

	get max(): number {
		return this._max;
	}

	set max(value: number) {
		this._max = value;
	}

	private _peremptionDate: string;

	get peremptionDate(): string {
		return this._peremptionDate;
	}

	set peremptionDate(value: string) {
		this.peremptionDate = value;
	}

	public get getMinMax() {
		return '' + this.min + '/' + this.max;
	}

	asObject(): medicalConsommable {
		return {
			articleId: this.articleId,
			name: this.name,
			location: this.location,
			quantity: this.quantity,
			price: this.price,
			min: this.min,
			max: this.max,
			peremptionDate: this.peremptionDate,
		};
	}
}
