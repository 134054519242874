import { BaseModel } from '../../../core/base.model';
import { Agency } from '../../Agency/Agency';
import { OrderItem } from './orderItem';

export type order = {
	id: number;
	intervenantId: number;
	agency: Agency;

	deliveryAddress: {
		street: string;
		building: string;
		cityCode: string;
		cityName: string;
	}

	comment: string;
	items: OrderItem[]

};

export class Order implements BaseModel<order> {
	constructor(store: order) {
		this._id = store.id;
		this._intervenantId = store.intervenantId;
		this._agency = store.agency;
		this._deliveryAddress = {
			street: store.deliveryAddress ? store.deliveryAddress.street : "",
			building: store.deliveryAddress ? store.deliveryAddress.building : "",
			cityCode: store.deliveryAddress ? store.deliveryAddress.cityCode : "",
			cityName: store.deliveryAddress ? store.deliveryAddress.cityName : ""
		};
		this._comment = store.comment;
		this._items = store.items;

	}

	private _id: number;
	private _intervenantId: number;
	private _agency: Agency;
	private _deliveryAddress: {
		street: string;
		building: string;
		cityCode: string;
		cityName: string;
	}
	private _comment: string;

	private _items: OrderItem[];

	get id(): number {
		return this._id;
	}
	get intervenantId(): number {
		return this._intervenantId;
	}
	get agency(): Agency {
		return this._agency;
	}
	get deliveryAddress(): { street: string; building: string; cityCode: string; cityName: string; } {
		return this._deliveryAddress;
	}
	
	get deliveryAddressDisplay(): string {
		return this._deliveryAddress ? (this._deliveryAddress.street + " " + this._deliveryAddress.building + " " + this._deliveryAddress.cityCode + " " + this._deliveryAddress.cityName) : "";
	}
	get comment(): string {
		return this._comment;
	}
	get items(): OrderItem[] {
		return this._items;
	}

	set id(value: number) {
		this._id = value;
	}
	set intervenantId(value: number) {
		this._intervenantId = value;
	}
	set agency(value: Agency) {
		this._agency = value;
	}
	set deliveryAddress(value: { street: string; building: string; cityCode: string; cityName: string; }) {
		this._deliveryAddress = value;
	}
	set comment(value: string) {
		this._comment = value;
	}
	set items(value: OrderItem[]) {
		this._items = value;
	}


	asObject(): order {
		return {
			id: this.id,
			intervenantId: this.intervenantId,
			agency: this.agency,
			deliveryAddress: this.deliveryAddress,
			comment: this.comment,
			items: this.items,
		};
	}


}
