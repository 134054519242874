import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DocumentType } from '@domain';
import { DTO_patient_document_types } from '../types/DTO_patient-document.types';

@Injectable({
	providedIn: 'root',
})
export class PatientDocumentTypesAdapter implements BaseAdapter<DTO_patient_document_types, DocumentType> {
	adapt(data: DTO_patient_document_types): DocumentType {
		return new DocumentType({
			id: data.id,
			label: data.label,
			value: data.slug,
		});
	}
}
