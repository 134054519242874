<div class="text-field" intranetUtilities size="md">
	@if (config().isRequired) {

	}

	@if (isError && config().errorMessages) {
		<small>{{ config().errorMessages }}</small>
	}

	<label class="floating">
		{{ config().label }}
		<span class="field-is-require">*</span>
	</label>

	<input
		(input)="getChange($event)"
		[disabled]="disabled"
		class="sos-form-control_input"
		[value]="control.value"
		type="date" />

	@if (isNotEmpty) {
		<fa-icon
			(click)="clearField()"
			[icon]="faCircleXmark"
			class="clear-field">
		</fa-icon>
	}

	<fa-icon [icon]="faCalendar"></fa-icon>
</div>
