import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_patient_address } from '../types/DTO_patient-address.types';
import { address, PatientAddress } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientAddressAdapter implements BaseAdapter<DTO_patient_address, PatientAddress> {
	adapt(data: DTO_patient_address): PatientAddress {
		const isOpen = data.addressTypes.some(type => type.toLowerCase() === 'administrative');

		const address: address = {
			migrationId: data.migrationId,
			addressBuilding: data.addressBuilding,
			addressStreet: data.addressStreet,
			addressComplement: data.addressComplement,
			cityName: data.cityName,
			zipCode: data.zipCode,
			indicationOfVisit: data.indicationOfVisit,
			email: data.email,
			phone: data.phone,
			phoneBis: data.phoneBis,
			mobile: data.mobile,
			mobileBis: data.mobileBis,
			fax: data.fax,
			faxBis: data.faxBis,
			phoneOwner: data.phoneOwner,
			phoneBisOwner: data.phoneBisOwner,
			mobileOwner: data.mobileOwner,
			mobileBisOwner: data.mobileBisOwner,
			hasRefusedMarketingSms: data.hasRefusedMarketingSms,
			hasRefusedMarketingSmsBis: data.hasRefusedMarketingSmsBis,
			hasNotProvidedEmail: data.hasNotProvidedEmail,
			isSupportOnly: data.isSupportOnly,
			addressTypes: data.addressTypes,
			isOpen,
		};

		return new PatientAddress(address);
	}
}
