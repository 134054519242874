import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DTO_article_family, DTO_fia } from '../types/DTO_fia.types';
import { ToastrService } from 'ngx-toastr';
import ArticleFamilyAdapter from '../adapters/article/articleFamily.adapter';
import FiaSearchResultAdapter from '../adapters/fia/fia-search-result.adapter';
import FiaSearchRequestAdapter from '../adapters/fia/fia-search-request.adapter';
import { FiaAdapter } from '../adapters/fia/fia.adapter';
import { paginatedResult, pagination } from '@application';
import { ArticleFamily, Fia, searchFiaParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class FiaRepository extends BaseHttpRepository {
	constructor(
		private toastrService: ToastrService,
		private fiaSearchResultAdapter: FiaSearchResultAdapter,
		private fiaSheetAdapter: FiaAdapter,
		private articleFamilyAdapter: ArticleFamilyAdapter,
		private fiaSearchParamAdapter: FiaSearchRequestAdapter,
	) {
		super();
	}

	listFias(searchParams: searchFiaParams, withPagination: boolean, pagination: pagination): Observable<paginatedResult<Fia[]>> {
		// BUILD DATA TO SEND
		const dataToSend = this.fiaSearchParamAdapter.adapt({
			searchParams: searchParams,
			withPagination: withPagination,
			pagination: pagination,
		});

		return this.paginatedGet<DTO_fia[]>(`${this.apiUrl}Stock/FicheInformations`, dataToSend, { 'X-Loader-Key': 'fias' }).pipe(
			map((data: paginatedResult<DTO_fia[]>) => {
				// SPECIFIC MESSAGE WHEN NO DATA
				if (data.data.length == 0 && withPagination) {
					this.toastrService.warning("Aucune Fiche Information Article correspondant à vos critères de recherche n'a été trouvé", '', {
						timeOut: 3000,
						closeButton: true,
						progressBar: true,
					});
				}

				return {
					data: data.data.map(fia => this.fiaSearchResultAdapter.adapt(fia)),
					pagination: data.pagination,
				};
			}),
		);
	}

	listArticleFamilies(): Observable<ArticleFamily[]> {
		return this.http.get<ArticleFamily[]>(`${this.apiUrl}Stock/FamillesArticles`, {}).pipe(
			map((data: DTO_article_family[]) => {
				return data.map(family => this.articleFamilyAdapter.adapt(family));
			}),
		);
	}

	findFiaById(id: number): Observable<Fia> {
		return this.http.get<DTO_fia>(`${this.apiUrl}Stock/FicheInformations/${id}`).pipe(map((data: DTO_fia) => this.fiaSheetAdapter.adapt(data)));
	}
}
