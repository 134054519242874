import { BaseModel } from '../../core/base.model';
import { DocumentType } from '../document/document-type';

export type patientDocument = {
	dossierId: number;
	fileId: string;
	intervenantFullName: string;
	depositDate: string;
	fileName: string;
	documentType: DocumentType;
	source: string;
	vu: boolean;
};

export class PatientDocument implements BaseModel<patientDocument> {
	constructor(data: patientDocument) {
		this._dossierId = data.dossierId;
		this._fileId = data.fileId;
		this._intervenantFullName = data.intervenantFullName;
		this._depositDate = data.depositDate;
		this._fileName = data.fileName;
		this._documentType = data.documentType;
		this._vu = data.vu;
		this._source = data.source;
	}

	private _source: string;

	get source(): string {
		return this._source;
	}

	set source(value: string) {
		this._source = value;
	}

	private _vu: boolean;

	get vu(): boolean {
		return this._vu;
	}

	set vu(value: boolean) {
		this._vu = value;
	}

	private _dossierId: number;

	get dossierId() {
		return this._dossierId;
	}

	set dossierId(value: number) {
		this._dossierId = value;
	}

	private _fileId: string;

	get fileId() {
		return this._fileId;
	}

	set fileId(value: string) {
		this._fileId = value;
	}

	private _intervenantFullName: string;

	get intervenantFullName() {
		return this._intervenantFullName;
	}

	set intervenantFullName(value: string) {
		this._intervenantFullName = value;
	}

	private _depositDate: string;
	get depositDate() {
		return this._depositDate;
	}

	set depositDate(value: string) {
		this._depositDate = value;
	}

	private _fileName: string;

	get fileName() {
		return this._fileName;
	}

	set fileName(value: string) {
		this._fileName = value;
	}

	private _documentType: DocumentType;

	get documentType() {
		return this._documentType;
	}

	set documentType(value: DocumentType) {
		this._documentType = value;
	}

	asObject(): patientDocument {
		return {
			dossierId: this._dossierId,
			depositDate: this._depositDate,
			fileId: this._fileId,
			intervenantFullName: this._intervenantFullName,
			fileName: this._fileName,
			documentType: this._documentType,
			source: this._source,
			vu: this._vu,
		};
	}
}
