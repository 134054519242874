import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_document_technicien } from '../types/DTO_document_technicien.types';
import { TechnicienDocument } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class DocumentTechnicienAdapter implements BaseAdapter<DTO_document_technicien, TechnicienDocument> {
	adapt(data: DTO_document_technicien): TechnicienDocument {
		return new TechnicienDocument({
			fileId: data.fileId,
			fileName: data.fileName,
			depositDate: data.depositDate,
			vu: data.vu,
			documentType: data.documentType,
			dossierId: data.dossierId,
			patientId: data.patientId,
			patientLastName: data.patientLastName,
			patientFirstName: data.patientFirstName,
			patientFullName: data.patientFullName,
			intervenantId: data.intervenantId,
			intervenantLastName: data.intervenantLastName,
			intervenantFirstName: data.intervenantFirstName,
			intervenantFullName: data.intervenantFullName,
			source: data.source,
		});
	}
}
