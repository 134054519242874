import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { faPenToSquare, faSave } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { OverlayComponent } from '../../../modal';
import { BtnDirective } from '../../../../directives/button/btn.directive';

@Component({
	selector: 'intranet-data-box',
	standalone: true,
	imports: [CommonModule, FaIconComponent, OverlayComponent, BtnDirective],
	templateUrl: './data-box.component.html',
	styleUrl: './data-box.component.scss',
})
export class DataBoxComponent {
	@Input() isEditable!: boolean;

	@Output() onSave: EventEmitter<void> = new EventEmitter();
	@Output() onEditMode: EventEmitter<boolean> = new EventEmitter();

	faPenToSquare = faPenToSquare;
	@Input() editMode = false;

	protected readonly faSave = faSave;

	edit() {
		this.editMode = true;
		this.onEditMode.emit(this.editMode)
	}

	save() {
		this.editMode = false;
		this.onSave.emit();
	}

	cancel() {
		this.editMode = false;
		this.onEditMode.emit(this.editMode)
	}
}
