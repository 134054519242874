import { BaseModel } from '../../core/base.model';
import { Agency } from '../Agency/Agency';

export type preference = {
	navigation: {
		isCollapsed: boolean;
	};
	agency: Agency | null;
};

export class Preference implements BaseModel<preference> {
	constructor(data: preference) {
		this._navigation = data.navigation;
		this._agency = data.agency;
	}

	private _navigation: {
		isCollapsed: boolean;
	};

	get navigation(): { isCollapsed: boolean } {
		return this._navigation;
	}

	set navigation(value: { isCollapsed: boolean }) {
		this._navigation = value;
	}

	private _agency: Agency | null;

	get agency(): Agency | null {
		return this._agency;
	}

	set agency(value: Agency) {
		this._agency = value;
	}

	asObject(): preference {
		return {
			navigation: this._navigation,
			agency: this._agency,
		};
	}
}
