import { Component, EventEmitter, input, InputSignal, Output, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCalendar, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ClickOutsideDirective } from '../../../directives';
import { options } from '../core/form.types';

@Component({
	selector: 'intranet-dropdown-list',
	standalone: true,
	imports: [CommonModule, FaIconComponent, ClickOutsideDirective],
	templateUrl: './dropdown-list.component.html',
	styleUrl: './dropdown-list.component.scss',
})
export class DropdownListComponent {

	readonly faChevronUp = faChevronUp;
	readonly faChevronDown = faChevronDown;
	readonly faCalendar = faCalendar;

	displayList = false;
	label: WritableSignal<string> = signal('Selectionner une donnée');

	data: InputSignal<options[]> = input.required();
	@Output() itemSelected: EventEmitter<options> = new EventEmitter<options>();

	selectedItem(data: options): void {
		this.itemSelected.emit(data);
		this.label.set(data.label);
		this.displayList = false;
	}
}
