<h2>{{ config().title }}</h2>

<div class="flex">
@for (item of config().options; track item.value) {
	<div class="radio-form-group mr-medium">
		<input
			type="radio"
			class="radio-form-group_input"
			[checked]="item.state"
			(click)="updateRadio(item)" />

		<label
			[ngClass]="{'radio-form-group_label': true, 'checked': item.state == true}"
			[for]="item.value">
			{{ item.label }}
		</label>
	</div>
}
</div>