import { pagination } from './pagination-types';
import { PaginationEvent } from '@components';

export class PaginationHelpers {
	public static changePage(direction: PaginationEvent, currentPagination: pagination): pagination {
		if (direction === PaginationEvent.PREVIOUS) {
			return {
				...currentPagination,
				page: this.canChangePage(direction, currentPagination) ? currentPagination.page - 1 : 1,
			};
		} else {
			return {
				...currentPagination,
				page: this.canChangePage(direction, currentPagination) ? currentPagination.page + 1 : currentPagination.page,
			};
		}
	}

	public static changePageSize(pageSize: number): pagination {
		return {
			total: 0,
			page: 1,
			limit: pageSize,
			nbTotalPage: 0,
		};
	}

	public static canChangePage(direction: PaginationEvent, currentPagination: pagination): boolean {
		if (direction === PaginationEvent.PREVIOUS) {
			return currentPagination.page > 1;
		} else {
			return currentPagination.total > currentPagination.page * currentPagination.limit;
		}
	}
}
