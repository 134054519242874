<div class="checkbox">
	<label (click)="updateState()" class="label-checkbox" >
		<input
			[(ngModel)]="state"
			[disabled]="disabled"
			[id]="config().label"
			type="checkbox">
		<div>
			<svg aria-hidden="true" class="mcui-check" viewBox="-2 -2 35 35">
				<title>checkmark-circle</title>
				<polyline points="7.57 15.87 12.62 21.07 23.43 9.93" />
			</svg>
		</div>
		<div> {{ config().label }}</div>
	</label>
</div>