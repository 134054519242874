import { BaseModel } from '../../core/base.model';
import { patient_observance } from '../../types/observance';
import { DTO_dataPoint } from '@infrastructure';

export class Observance implements BaseModel<patient_observance> {
	constructor(data: patient_observance) {
		this._count = data.count;
		this._countUsed = data.countUsed;
		this._durationAverageByMinutes = data.durationAverageByMinutes;
		this._durationAverageTotalByMinutes = data.durationAverageTotalByMinutes;
		this._durationTotalByMinutes = data.durationTotalByMinutes;
		this._iahAverage = data.iahAverage;
		this._iahAverageTotal = data.iahAverageTotal;
		this._iahTotal = data.iahTotal;
		this._iahXDurationTotal = data.iahXDurationTotal;
		this._iahAverageOverDuration = data.iahAverageOverDuration;
		this._pressureAverage = data.pressureAverage;
		this._pressureAverageTotal = data.pressureAverageTotal;
		this._pressureTotal = data.pressureTotal;
		this._pressureXDurationTotal = data.pressureXDurationTotal;
		this._pressureAverageOverDuration = data.pressureAverageOverDuration;
		this._leaksAverage = data.leaksAverage;
		this._leaksAverageTotal = data.leaksAverageTotal;
		this._leaksTotal = data.leaksTotal;
		this._leaksXDurationTotal = data.leaksXDurationTotal;
		this._leaksAverageOverDuration = data.leaksAverageOverDuration;
	}

	private _dataPoints: DTO_dataPoint[] = [];

	get dataPoints(): DTO_dataPoint[] {
		return this._dataPoints;
	}

	set dataPoints(value: DTO_dataPoint[]) {
		this._dataPoints = value;
	}

	private _count: number;

	get count(): number {
		return this._count;
	}

	set count(value: number) {
		this._count = value;
	}

	private _countUsed: number;

	get countUsed(): number {
		return this._countUsed;
	}

	set countUsed(value: number) {
		this._countUsed = value;
	}

	private _durationAverageByMinutes: number;

	get durationAverageByMinutes(): number {
		return this._durationAverageByMinutes;
	}

	set durationAverageByMinutes(value: number) {
		this._durationAverageByMinutes = value;
	}

	private _durationAverageTotalByMinutes: number;

	get durationAverageTotalByMinutes(): number {
		return this._durationAverageTotalByMinutes;
	}

	set durationAverageTotalByMinutes(value: number) {
		this._durationAverageTotalByMinutes = value;
	}

	private _durationTotalByMinutes: number;

	get durationTotalByMinutes(): number {
		return this._durationTotalByMinutes;
	}

	set durationTotalByMinutes(value: number) {
		this._durationTotalByMinutes = value;
	}

	private _iahAverage: number;

	get iahAverage(): number {
		return this._iahAverage;
	}

	set iahAverage(value: number) {
		this._iahAverage = value;
	}

	private _iahAverageTotal: number;

	get iahAverageTotal(): number {
		return this._iahAverageTotal;
	}

	set iahAverageTotal(value: number) {
		this._iahAverageTotal = value;
	}

	private _iahTotal: number;

	get iahTotal(): number {
		return this._iahTotal;
	}

	set iahTotal(value: number) {
		this._iahTotal = value;
	}

	private _iahXDurationTotal: number;

	get iahXDurationTotal(): number {
		return this._iahXDurationTotal;
	}

	set iahXDurationTotal(value: number) {
		this._iahXDurationTotal = value;
	}

	private _iahAverageOverDuration: number;

	get iahAverageOverDuration(): number {
		return this._iahAverageOverDuration;
	}

	set iahAverageOverDuration(value: number) {
		this._iahAverageOverDuration = value;
	}

	private _pressureAverage: number;

	get pressureAverage(): number {
		return this._pressureAverage;
	}

	set pressureAverage(value: number) {
		this._pressureAverage = value;
	}

	private _pressureAverageTotal: number;

	get pressureAverageTotal(): number {
		return this._pressureAverageTotal;
	}

	set pressureAverageTotal(value: number) {
		this._pressureAverageTotal = value;
	}

	private _pressureTotal: number;

	get pressureTotal(): number {
		return this._pressureTotal;
	}

	set pressureTotal(value: number) {
		this._pressureTotal = value;
	}

	private _pressureXDurationTotal: number;

	get pressureXDurationTotal(): number {
		return this._pressureXDurationTotal;
	}

	set pressureXDurationTotal(value: number) {
		this._pressureXDurationTotal = value;
	}

	private _pressureAverageOverDuration: number;

	get pressureAverageOverDuration(): number {
		return this._pressureAverageOverDuration;
	}

	set pressureAverageOverDuration(value: number) {
		this._pressureAverageOverDuration = value;
	}

	private _leaksAverage: number;

	get leaksAverage(): number {
		return this._leaksAverage;
	}

	set leaksAverage(value: number) {
		this._leaksAverage = value;
	}

	private _leaksAverageTotal: number;

	get leaksAverageTotal(): number {
		return this._leaksAverageTotal;
	}

	set leaksAverageTotal(value: number) {
		this._leaksAverageTotal = value;
	}

	private _leaksTotal: number;

	get leaksTotal(): number {
		return this._leaksTotal;
	}

	set leaksTotal(value: number) {
		this._leaksTotal = value;
	}

	private _leaksXDurationTotal: number;

	get leaksXDurationTotal(): number {
		return this._leaksXDurationTotal;
	}

	set leaksXDurationTotal(value: number) {
		this._leaksXDurationTotal = value;
	}

	private _leaksAverageOverDuration: number;

	get leaksAverageOverDuration(): number {
		return this._leaksAverageOverDuration;
	}

	set leaksAverageOverDuration(value: number) {
		this._leaksAverageOverDuration = value;
	}

	getAverageObservance(): string {
		const hour = '';
		const minutes = '';
		return `${hour}h${minutes}`;
	}

	asObject(): patient_observance {
		return {
			count: this._count,
			countUsed: this._countUsed,
			durationAverageByMinutes: this._durationAverageByMinutes,
			durationAverageTotalByMinutes: this._durationAverageTotalByMinutes,
			durationTotalByMinutes: this._durationTotalByMinutes,
			iahAverage: this._iahAverage,
			iahAverageTotal: this._iahAverageTotal,
			iahTotal: this._iahTotal,
			iahXDurationTotal: this._iahXDurationTotal,
			iahAverageOverDuration: this._iahAverageOverDuration,
			pressureAverage: this._pressureAverage,
			pressureAverageTotal: this._pressureAverageTotal,
			pressureTotal: this._pressureTotal,
			pressureXDurationTotal: this._pressureXDurationTotal,
			pressureAverageOverDuration: this._pressureAverageOverDuration,
			leaksAverage: this._leaksAverage,
			leaksAverageTotal: this._leaksAverageTotal,
			leaksTotal: this._leaksTotal,
			leaksXDurationTotal: this._leaksXDurationTotal,
			leaksAverageOverDuration: this._leaksAverageOverDuration,
			dataPoints: this._dataPoints,
		};
	}
}
