import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_patient } from '../types/DTO_patient.types';
import { Patient, patient } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientAdapter implements BaseAdapter<DTO_patient, Patient> {
	adapt(data: DTO_patient): Patient {
		const patient: patient = {
			id: data.patientIdV1,
			civility: data.civility,
			firstname: data.firstName,
			lastname: data.lastName,
			agencyId: data.agencyId,
			patronym: data.patronym,
			birthDate: data.birthDate,
			isActive: data.isActive,
			insee: data.insee,
			size: data.size,
			hasTelesuivieConsentement: data.hasTelesuivieConsentement,
			hasMobileAccess: data.hasMobileAccess,
			hasGroupingVisibility: data.hasGroupingVisibility,
			hasAld: data.hasAld,
			hasLivretFFAIR: data.hasLivretFFAIR,
			hasNoPrescription: data.hasNoPrescription,
			age: data.age,
			agencyIdV1: data.agencyIdV1,
			agencyName: data.agencyName,
			birthDateAtypic: data.birthDateAtypic,
			comment: data.comment,
			dateCreation: data.dateCreation,
			deathDate: data.deathDate,
			dossierId: data.dossierId,
			emailMobile: data.emailMobile,
			familyDoctorId: data.familyDoctorId,
			finalized: data.finalized,
			gender: data.gender,
			iahValue: data.iahValue,
			isHidden: data.isHidden,
			isMainDossier: data.isMainDossier,
			isMobilePatient: data.isMobilePatient,
			patientIdV2: data.patientIdV2,
			prescriberDoctorId: data.prescriberDoctorId,
			typeId: data.typeId,
			typeLabel: data.typeLabel,
			typeSpecificId: data.typeSpecificId,
			typeSpecificLabel: data.typeSpecificLabel,
			treatment: [],
			addresses: [],
		};
		return new Patient(patient);
	}
}
