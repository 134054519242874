import { BaseModel } from '../../core/base.model';
import { treatment } from '../../types/patient-treatment';
import { PatientAddress } from './patient-address';
import { addressType } from '../../enums/address-type.enum';
import { DateTime } from 'luxon';

export type patient = {
	id: number | null;
	lastname: string | null;
	firstname: string | null;
	patronym: string | null;
	birthDate: string | null;
	birthDateAtypic: string | null;
	deathDate: string | null;
	age: number | null;
	size: number | null;
	insee: string | null;
	civility: string | null;
	gender: string | null;
	comment: string | null;
	isActive: boolean | null;
	isHidden: boolean | null;
	typeId: number | null;
	typeLabel: string | null;
	typeSpecificId: number | null;
	typeSpecificLabel: string | null;
	hasTelesuivieConsentement: boolean | null;
	hasAld: boolean | null;
	hasLivretFFAIR: boolean | null;
	hasNoPrescription: boolean | null;
	iahValue: number | null;
	emailMobile: string | null;
	isMobilePatient: boolean | null;
	hasMobileAccess: boolean | null;
	dossierId: number | null;
	dateCreation: string | null;
	familyDoctorId: number | null;
	prescriberDoctorId: number | null;
	isMainDossier: boolean | null;
	agencyIdV1: number | null;
	agencyName: string | null;
	patientIdV2: string | null;
	finalized: boolean | null;
	hasGroupingVisibility: boolean | null;
	agencyId: string | null;
	treatment: treatment[];
	addresses: PatientAddress[];
};

export class Patient implements BaseModel<patient> {
	constructor(data: patient) {
		this._id = data.id;
		this._civility = data.civility;
		this._firstname = data.firstname;
		this._lastname = data.lastname;
		this._agencyId = data.agencyId;
		this._patronym = data.patronym;
		this._birthDate = data.birthDate;
		this._isActive = data.isActive;
		this._insee = data.insee;
		this._gender = data.gender;
		this._size = data.size;
		this._hasGroupingVisibility = data.hasGroupingVisibility;
		this._hasMobileAccess = data.hasMobileAccess;
		this._hasTelesuivieConsentement = data.hasTelesuivieConsentement;
		this._hasAld = data.hasAld;
		this._hasLivretFFAIR = data.hasLivretFFAIR;
		this._hasNoPrescription = data.hasNoPrescription;
		this._iahValue = data.iahValue;
		this._age = data.age;
		this._agencyIdV1 = data.agencyIdV1;
		this._agencyName = data.agencyName;
		this._birthDateAtypic = data.birthDateAtypic;
		this._comment = data.comment;
		this._dateCreation = data.dateCreation;
		this._deathDate = data.deathDate;
		this._dossierId = data.dossierId;
		this._emailMobile = data.emailMobile;
		this._familyDoctorId = data.familyDoctorId;
		this._finalized = data.finalized;
		this._isMainDossier = data.isMainDossier;
		this._isMobilePatient = data.isMobilePatient;
		this._patientIdV2 = data.patientIdV2;
		this._prescriberDoctorId = data.prescriberDoctorId;
		this._typeId = data.typeId;
		this._typeLabel = data.typeLabel;
		this._typeSpecificId = data.typeSpecificId;
		this._typeSpecificLabel = data.typeSpecificLabel;
	}

	private _hasAld: boolean | null = null;

	get hasAld(): boolean | null {
		return this._hasAld;
	}

	set hasAld(value: boolean | null) {
		this._hasAld = value;
	}

	private _hasLivretFFAIR: boolean | null = null;

	get hasLivretFFAIR(): boolean | null {
		return this._hasLivretFFAIR;
	}

	set hasLivretFFAIR(value: boolean | null) {
		this._hasLivretFFAIR = value;
	}

	private _hasNoPrescription: boolean | null = null;

	get hasNoPrescription(): boolean | null {
		return this._hasNoPrescription;
	}

	set hasNoPrescription(value: boolean | null) {
		this._hasNoPrescription = value;
	}

	private _id: number | null = null;

	get id(): number | null {
		return this._id;
	}

	set id(value: number | null) {
		this._id = value;
	}

	private _civility: string | null = null;

	get civility(): string | null {
		return this._civility;
	}

	set civility(value: string | null) {
		this._civility = value;
	}

	private _firstname: string | null = null;

	get firstname(): string | null {
		return this._firstname;
	}

	set firstname(value: string | null) {
		this._firstname = value;
	}

	private _lastname: string | null = null;

	get lastname(): string | null {
		return this._lastname;
	}

	set lastname(value: string | null) {
		this._lastname = value;
	}

	private _agencyId: string | null = null;

	get agencyId(): string | null {
		return this._agencyId;
	}

	set agencyId(value: string | null) {
		this._agencyId = value;
	}

	private _addresses: PatientAddress[] = [];

	get addresses() {
		return this._addresses;
	}

	set addresses(value: PatientAddress[]) {
		this._addresses = value;
	}

	get addressAdministrative() {
		return this._addresses.filter(address => address.addressTypes.findIndex(type => type === addressType.ADMINISTRATIVE) > -1)[0];
	}

	get addressFacturation() {
		return this._addresses.filter(address => address.addressTypes.findIndex(type => type === addressType.FACTURATION) > -1)[0];
	}

	get addressLivraison() {
		return this._addresses.filter(address => address.addressTypes.findIndex(type => type === addressType.LIVRAISON) > -1)[0];
	}

	get addressLivraisonBis() {
		return this._addresses.filter(address => address.addressTypes.findIndex(type => type === addressType.LIVRAISONBIS) > -1)[0];
	}

	get addressAssure() {
		return this._addresses.filter(address => address.addressTypes.findIndex(type => type === addressType.ASSURE) > -1)[0];
	}

	get addressAny() {
		return this._addresses.filter(address => address.addressTypes.findIndex(type => type === addressType.ANY) > -1)[0];
	}

	private _patronym: string | null = null;

	get patronym(): string | null {
		return this._patronym;
	}

	set patronym(value: string | null) {
		this._patronym = value;
	}

	private _birthDate: string | null = null;

	get birthDate(): string | null {
		return this._birthDate;
	}

	set birthDate(value: string | null) {
		this._birthDate = value;
	}

	get birthDateDisplay(): string | null {
		return  this._birthDate ? DateTime.fromISO(this._birthDate).toFormat('dd/MM/yyyy') : "-";
	}

	private _isActive: boolean | null = null;

	get isActive(): boolean | null {
		return this._isActive;
	}

	set isActive(value: boolean | null) {
		this._isActive = value;
	}

	private _insee: string | null = null;

	get insee(): string | null {
		return this._insee;
	}

	set insee(value: string | null) {
		this._insee = value;
	}

	private _size: number | null = null;

	get size(): number | null {
		return this._size;
	}

	set size(value: number | null) {
		this._size = value;
	}

	private _hasMobileAccess: boolean | null = null;

	get hasMobileAccess(): boolean | null {
		return this._hasMobileAccess;
	}

	set hasMobileAccess(value: boolean | null) {
		this._hasMobileAccess = value;
	}

	private _hasTelesuivieConsentement: boolean | null = null;

	get hasTelesuivieConsentement(): boolean | null {
		return this._hasTelesuivieConsentement;
	}

	set hasTelesuivieConsentement(value: boolean | null) {
		this._hasTelesuivieConsentement = value;
	}

	private _hasGroupingVisibility: boolean | null = null;

	get hasGroupingVisibility(): boolean | null {
		return this._hasGroupingVisibility;
	}

	set hasGroupingVisibility(value: boolean | null) {
		this._hasGroupingVisibility = value;
	}

	private _treatment: treatment[] = [];

	get treatment(): treatment[] {
		return this._treatment;
	}

	set treatment(value: treatment[]) {
		this._treatment = value;
	}

	private _agencyIdV1: number | null = null;

	get agencyIdV1(): number | null {
		return this._agencyIdV1;
	}

	set agencyIdV1(value: number | null) {
		this._agencyIdV1 = value;
	}

	private _agencyName: string | null = null;

	get agencyName(): string | null {
		return this._agencyName;
	}

	set agencyName(value: string | null) {
		this._agencyName = value;
	}

	private _birthDateAtypic: string | null = null;

	get birthDateAtypic(): string | null {
		return this._birthDateAtypic;
	}

	set birthDateAtypic(value: string | null) {
		this._birthDateAtypic = value;
	}

	private _comment: string | null = null;

	get comment(): string | null {
		return this._comment;
	}

	set comment(value: string | null) {
		this._comment = value;
	}

	private _dateCreation: string | null = null;

	get dateCreation(): string | null {
		return this._dateCreation;
	}

	set dateCreation(value: string | null) {
		this._dateCreation = value;
	}

	private _deathDate: string | null = null;

	get deathDate(): string | null {
		return this._deathDate;
	}

	set deathDate(value: string | null) {
		this._deathDate = value;
	}

	private _dossierId: number | null = null;

	get dossierId(): number | null {
		return this._dossierId;
	}

	set dossierId(value: number | null) {
		this._dossierId = value;
	}

	private _emailMobile: string | null = null;

	get emailMobile(): string | null {
		return this._emailMobile;
	}

	set emailMobile(value: string | null) {
		this._emailMobile = value;
	}

	private _familyDoctorId: number | null = null;

	get familyDoctorId(): number | null {
		return this._familyDoctorId;
	}

	set familyDoctorId(value: number | null) {
		this._familyDoctorId = value;
	}

	private _finalized: boolean | null = null;

	get finalized(): boolean | null {
		return this._finalized;
	}

	set finalized(value: boolean | null) {
		this._finalized = value;
	}

	private _gender: string | null = null;

	get gender(): string | null {
		return this._gender;
	}

	set gender(value: string | null) {
		this._gender = value;
	}

	private _iahValue: number | null = null;

	get iahValue(): number | null {
		return this._iahValue;
	}

	set iahValue(value: number | null) {
		this._iahValue = value;
	}

	private _isHidden: boolean | null = null;

	get isHidden(): boolean | null {
		return this._isHidden;
	}

	set isHidden(value: boolean | null) {
		this._isHidden = value;
	}

	private _isMainDossier: boolean | null = null;

	get isMainDossier(): boolean | null {
		return this._isMainDossier;
	}

	set isMainDossier(value: boolean | null) {
		this._isMainDossier = value;
	}

	private _isMobilePatient: boolean | null = null;

	get isMobilePatient(): boolean | null {
		return this._isMobilePatient;
	}

	set isMobilePatient(value: boolean | null) {
		this._isMobilePatient = value;
	}

	private _patientIdV2: string | null = null;

	get patientIdV2(): string | null {
		return this._patientIdV2;
	}

	set patientIdV2(value: string | null) {
		this._patientIdV2 = value;
	}

	private _prescriberDoctorId: number | null = null;

	get prescriberDoctorId(): number | null {
		return this._prescriberDoctorId;
	}

	set prescriberDoctorId(value: number | null) {
		this._prescriberDoctorId = value;
	}

	private _typeId: number | null = null;

	get typeId(): number | null {
		return this._typeId;
	}

	set typeId(value: number | null) {
		this._typeId = value;
	}

	private _typeLabel: string | null = null;

	get typeLabel(): string | null {
		return this._typeLabel;
	}

	set typeLabel(value: string | null) {
		this._typeLabel = value;
	}

	private _typeSpecificId: number | null = null;

	get typeSpecificId(): number | null {
		return this._typeSpecificId;
	}

	set typeSpecificId(value: number | null) {
		this._typeSpecificId = value;
	}

	private _typeSpecificLabel: string | null = null;

	get typeSpecificLabel(): string | null {
		return this._typeSpecificLabel;
	}

	set typeSpecificLabel(value: string | null) {
		this._typeSpecificLabel = value;
	}

	private _age: number | null = null;

	get age(): number | null {
		return this._age;
	}

	set age(value: number | null) {
		this._age = value;
	}

	asObject(): patient {
		return {
			age: this._age,
			agencyIdV1: this._agencyIdV1,
			agencyName: this._agencyName,
			birthDateAtypic: this._birthDateAtypic,
			comment: this._comment,
			dateCreation: this._dateCreation,
			deathDate: this._deathDate,
			dossierId: this._dossierId,
			emailMobile: this._emailMobile,
			familyDoctorId: this._familyDoctorId,
			finalized: this._finalized,
			gender: this._gender,
			iahValue: this._iahValue,
			isHidden: this._isHidden,
			isMainDossier: this._isMainDossier,
			isMobilePatient: this._isMobilePatient,
			patientIdV2: this._patientIdV2,
			prescriberDoctorId: this._prescriberDoctorId,
			typeId: this._typeId,
			typeLabel: this._typeLabel,
			typeSpecificId: this._typeSpecificId,
			typeSpecificLabel: this._typeSpecificLabel,
			id: this._id,
			civility: this._civility,
			firstname: this._firstname,
			lastname: this._lastname,
			patronym: this._patronym,
			agencyId: this._agencyId,
			birthDate: this._birthDate,
			isActive: this._isActive,
			insee: this._insee,
			size: this._size,
			hasGroupingVisibility: this._hasGroupingVisibility,
			hasAld: this._hasAld,
			hasLivretFFAIR: this._hasLivretFFAIR,
			hasNoPrescription: this._hasNoPrescription,
			hasMobileAccess: this._hasMobileAccess,
			hasTelesuivieConsentement: this._hasTelesuivieConsentement,
			treatment: this.treatment,
			addresses: this._addresses,
		};
	}
}
