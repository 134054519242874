import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { map, Observable, skipWhile, take } from 'rxjs';
import { select } from '@ngneat/elf';
import { UserModule } from '@domain';
import { AuthenticationManager } from '../authentication.manager';

@Injectable({
	providedIn: 'root',
})
class ModulePermissionService {
	constructor(
		private authenticationManager: AuthenticationManager,
		private router: Router,
	) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.authenticationManager.store.pipe(select(state => state.userModules)).pipe(
			skipWhile(val => val == null),
			take(1),
			map(modules => {
				return this.checkUserCanAccessModules(next, modules);
			}),
		);
	}

	private checkUserCanAccessModules(next: ActivatedRouteSnapshot, modules: UserModule[] | null): boolean {
		try {
			// NO MODULE REDIRECT TO DENIED PAGE
			if (modules == null) {
				this.router.navigate(['/access-denied']);
				return false;
			}
			// USER HAS ACCESS TO SOME MODULES
			else {
				// CHECK IF THE PAGE IS PUBLIC (route's slug='') --> NO NEED TO CHECK PERMISSIONS
				if (next.data['slug'] == '') {
					this.authenticationManager.store.update(state => {
						return {
							...state,
							access: true,
						};
					});
					return true;
				}

				// HOMEPAGE CASE
				if (next.data['slug'] == 'TableauDeBord') {
					//ALLOW THE USER TO SEE THE PAGE ANYWAY
					this.authenticationManager.store.update(state => {
						return {
							...state,
							access: true,
						};
					});

					// RETRIEVE THE "Tableau de bord" PERMISSIONS
					let userIsAllowed = modules.find(module => module.slug == next.data['slug']);
					if (userIsAllowed != null) {
						this.retrievePermissions(userIsAllowed!);
					}
					return true;
				} else {
					let userIsAllowed = modules.find(module => module.slug == next.data['slug']);

					// IF USER IS NOT ALLOWED TO ACCESS THIS PAGE
					if (userIsAllowed == undefined) {
						// BUT HAS RIGHTT TO ACCESS USER MANAGEMENT PAGE (SUPER ADMIN) --> REDIRECT TO USER MANAGEMENT PAGE (NO NEED TO RETRIEVE THE PERMISSIONS FOR THS PAGE)
						if (modules.length == 1 && modules[0].slug == 'AccesUtilisateur') {
							this.router.navigate(['/user']);
							return true;
						}
						// USER HAS NO RIGHT TO ACCESS THIS PAGE AND IS NOT A SUPER ADMIN --> REDIRECT TO DENIED PAGE
						else {
							this.router.navigate(['/access-denied']);
							this.authenticationManager.store.update(state => {
								return {
									...state,
									access: false,
								};
							});
							return false;
						}
					}
					// USER IS ALLOWED TO ACCESS THIS PAGE
					else {
						this.authenticationManager.store.update(state => {
							return {
								...state,
								access: true,
							};
						});
						// RETRIEVE THE PERMISSIONS FOR THIS MODULE IF DOES NOT EXIST
						this.retrievePermissions(userIsAllowed);
						return true;
					}
				}
			}
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	private retrievePermissions(userIsAllowed: UserModule) {
		if (
			(this.authenticationManager.store.state.permissions == null || this.authenticationManager.store.state.permissions.get(userIsAllowed.slug) == undefined) &&
			this.authenticationManager.store.state.currentUserAgency
		) {
			this.authenticationManager.retrieveUserModulePermissions(this.authenticationManager.store.state.currentUserAgency!.id, userIsAllowed.slug);
		}
	}
}

export const ModuleGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
	return inject(ModulePermissionService).canActivate(next, state);
};
