import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_order_creation, DTO_order_search_request } from '../../types/DTO_order.types';


@Injectable({
	providedIn: 'root',
})
export class OrderCreationAdapter implements BaseAdapter<{ intervenantId: number, agencyId: number }, DTO_order_creation> {
	adapt(data: { intervenantId: number, agencyId: number, status: string }): DTO_order_creation {
		return {
			intervenantId: data.intervenantId,
			orderStatus: data.status,
			agencyId: data.agencyId
		}
	}
}
