import { DateTime } from 'luxon';
import { BaseModel } from '../../../core/base.model';

export type medicalDeviceTransfer = {
	date: string;
	state: string;
	destinationStore: string;
	intervenantName: string;
	comment: string;
};

export class MedicalDeviceTransfer implements BaseModel<medicalDeviceTransfer> {
	constructor(transfer: medicalDeviceTransfer) {
		this._date = transfer.date;
		this._state = transfer.state;
		this._destinationStore = transfer.destinationStore;
		this._intervenantName = transfer.intervenantName;
		this._comment = transfer.comment;
	}

	private _date: string;

	get date(): string {
		return this._date;
	}

	set date(value: string) {
		this._date = value;
	}

	private _state: string;

	get state(): string {
		return this._state;
	}

	set state(value: string) {
		this._state = value;
	}

	private _destinationStore: string;

	get destinationStore(): string {
		return this._destinationStore;
	}

	set destinationStore(value: string) {
		this._destinationStore = value;
	}

	private _intervenantName: string;

	get intervenantName(): string {
		return this._intervenantName;
	}

	set intervenantName(value: string) {
		this._intervenantName = value;
	}

	private _comment: string;

	get comment(): string {
		return this._comment;
	}

	set comment(value: string) {
		this._comment = value;
	}

	get displayDate(): string {
		return DateTime.fromISO(this._date).toFormat('dd MMMM yyyy');
	}

	asObject(): medicalDeviceTransfer {
		return {
			date: this.date,
			state: this.state,
			destinationStore: this.destinationStore,
			intervenantName: this.intervenantName,
			comment: this.comment,
		};
	}
}
