import { storeType } from '@domain';
import { medicalDeviceStatus } from 'core/domain/src/lib/enums/medical-device-status-enum';

export const getEnumKeyValues = <E>(enumObject: E) => {
	return Object.keys(enumObject as never).map(key => {
		return {
			key: key,
			value: enumObject[key as keyof E],
		};
	});
};

export const getStoretypeEnumKeyValues = <E>(enumObject: E) => {
	return Object.keys(enumObject as never).map(key => {
		return {
			key: key,
			value: enumObject[key as keyof E],
		};
	});
};

export const getEnumKeyByValue = <T extends object>(enumValue: string, enumObj: T): keyof T | undefined => {
	const enumKeys = Object.keys(enumObj).filter(key => isNaN(Number(key)));

	for (const key of enumKeys) {
		// @ts-ignore
		if (enumObj[key] === enumValue) {
			return key as keyof T;
		}
	}

	return undefined;
};

export const getEnumStoreTypeValueByKey = (str: string): storeType | undefined => {
	return storeType[str as keyof typeof storeType];
};

export const getEnumTransferableMedicalDeviceStatusValueByKey = (str: string): medicalDeviceStatus | undefined => {
	return medicalDeviceStatus[str as keyof typeof medicalDeviceStatus];
};
