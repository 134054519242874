import { BaseModel } from '../../core/base.model';

export type intervenant = {
	id: number;
	fullName: string;
	agencyId: number;
	agencyName: string;
	profile: string;
};

export class Intervenant implements BaseModel<intervenant> {
	constructor(intervenant: intervenant) {
		this._id = intervenant.id;
		this._fullName = intervenant.fullName;
		this._agencyId = intervenant.agencyId;
		this._agencyName = intervenant.agencyName;
		this._profile = intervenant.profile;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _fullName: string;

	get fullName(): string {
		return this._fullName;
	}

	set fullName(value: string) {
		this._fullName = value;
	}

	private _agencyId: number;

	get agencyId(): number {
		return this._agencyId;
	}

	set agencyId(value: number) {
		this._agencyId = value;
	}

	private _agencyName: string;

	get agencyName(): string {
		return this._agencyName;
	}

	set agencyName(value: string) {
		this._agencyName = value;
	}

	private _profile: string;

	get profile(): string {
		return this._profile;
	}

	set profile(value: string) {
		this._profile = value;
	}

	asObject(): intervenant {
		throw new Error('Method not implemented.');
	}
}
