import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { DTO_transfert_medical_device } from '../../types/DTO_store.types';
import { Injectable } from '@angular/core';
import { transfertDMParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class StoreTransferMedicalDeviceRequestAdapter implements BaseAdapter<transfertDMParams, DTO_transfert_medical_device> {
	adapt(data: transfertDMParams): DTO_transfert_medical_device {
		// BUILD DATA TO SEND
		const dto: DTO_transfert_medical_device = {
			toStorageAreaId: data.toStorageAreaId,
			parcState: data.parcState,
			intervenantId: data.intervenantId,
			codeParcOrSerialNumber: data.codeParcOrSerialNumber,
			comment: data.comment ? data.comment : '',

		};

		return dto;
	}
}
