import { Injectable } from '@angular/core';
import { HasStore } from '../core/store/has-store';
import { map, take } from 'rxjs';
import { Preference } from '@domain';
import { PreferenceRepository } from '@infrastructure';

export type preferenceState = {
	preference: Preference | null;
};

@Injectable({
	providedIn: 'root',
})
export class PreferenceManager extends HasStore<preferenceState> {
	constructor(private readonly preferenceRepository: PreferenceRepository) {
		super({ preference: null }, 'preference');
	}

	getUserPreference() {
		return this.preferenceRepository.getPreferences().pipe(
			take(1),
			map(preference => {
				this.store.update((state: preferenceState) => {
					return {
						...state,
						preference: preference,
					};
				});
			}),
		);
	}

	updatePreference(data: string) {
		this.preferenceRepository.savePreferences(data);
		this.getUserPreference().subscribe();
	}
}
