import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_followUp } from '../types/DTO_follow_up.type';
import { followUp, PatientFollowUp } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientFollowUpAdapter implements BaseAdapter<DTO_followUp, PatientFollowUp> {
	adapt(data: DTO_followUp): PatientFollowUp {
		const followUp: followUp = {
			interventionId: data.interventionId,
			date: data.date,
			parameters: data.parameters,
		};

		return new PatientFollowUp(followUp);
	}
}
