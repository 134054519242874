import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_user_roles } from '../types/DTO_user-management.types';
import { Agency, role } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class UserAgencyRoleAdapter implements BaseAdapter<DTO_user_roles[], Map<Agency, role[]>> {
	adapt(dto_roles: DTO_user_roles[]): Map<Agency, role[]> {
		let ret = new Map<Agency, role[]>();

		dto_roles.forEach(role => {
			// AGENCY ALREADY EXIST
			let found = null;
			ret.forEach((value, key) => {
				if (key.id == role.agenceId) {
					found = key;
				}
			});
			if (found != null) {
				// GET THE MAP ENTRY AND ADD THE ROLE
				ret.get(found)?.push({
					id: role.roleId,
					name: role.role,
				});
				// SORT THE ROLE BY NAME
				ret.get(found)?.sort((a, b) => a.name.localeCompare(b.name));
				found = null;
			}

			// NEW AGENCY
			else {
				// CREATE THE MAP ENTRY
				ret.set(
					new Agency({
						id: role.agenceId,
						name: role.agenceId == null ? 'Toutes agences' : role.agence,
					}),
					[{ id: role.roleId, name: role.role }],
				);
			}
		});

		//SORT THE MAP BY AGENCY NAME
		ret = new Map([...ret.entries()].sort((a, b) => a[0].name.localeCompare(b[0].name)));
		return ret;
	}
}
