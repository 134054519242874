import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DTO_template_store, DTO_template_store_article } from '../types/DTO_template-store.types';
import ArticleAdapter from '../adapters/article/article.adapter';
import TemplateStoreSearchRequestAdapter from '../adapters/template-store/template-store-search-request-adapter';
import TemplateStoreSearchCreationAdapter from '../adapters/template-store/template-store-creation-request-adapter';
import TemplateStoreEditRequestAdapter from '../adapters/template-store/template-store-edit-request-adapter';
import TemplateStoreArticleRequestAdapter from '../adapters/template-store/template-store-article-request-adapter';
import { paginatedResult, pagination } from '@application';
import { addTemplateStoreArticle, Article, createTemplateStoresParams, editTemplateStoreParams, searchTemplateStoresParams, TemplateStore } from '@domain';
import { TemplateStoreAdapter } from '../adapters/template-store/template-store.adapter';

@Injectable({ providedIn: 'root' })
export class TemplateStoreRepository extends BaseHttpRepository {
	constructor(
		private readonly templateStoreAdapter: TemplateStoreAdapter,
		private readonly templateStoreSearchRequestAdapter: TemplateStoreSearchRequestAdapter,
		private readonly templateStoreCreationRequestAdapter: TemplateStoreSearchCreationAdapter,
		private readonly templateStoreEditRequestAdapter: TemplateStoreEditRequestAdapter,
		private readonly templateStoreArticleRequestAdapter: TemplateStoreArticleRequestAdapter,
		private readonly articleAdapter: ArticleAdapter,
		private readonly toastrService: ToastrService,
	) {
		super();
	}

	listTemplateStores(params: searchTemplateStoresParams, pagination: pagination): Observable<paginatedResult<TemplateStore[]>> {
		const dataToSend = this.templateStoreSearchRequestAdapter.adapt({ params: params, pagination: pagination });

		return this.paginatedGet<DTO_template_store[]>(`${this.apiUrl}Stock/MagasinTemplate/Search`, dataToSend, { 'X-Loader-Key': 'template-stores' }).pipe(
			map((data: paginatedResult<DTO_template_store[]>) => {
				// SPECIFIC MESSAGE WHEN NO DATA
				if (data.data.length == 0) {
					this.toastrService.warning("Aucun magasin modèle correspondant à vos critères de recherche n'a été trouvé", '', {
						timeOut: 3000,
						closeButton: true,
						progressBar: true,
					});
				}

				return {
					data: data.data.map(template => this.templateStoreAdapter.adapt(template)),
					pagination: data.pagination,
				};
			}),
		);
	}

	createTemplateStore(params: createTemplateStoresParams): Observable<number> {
		const dataTosend = this.templateStoreCreationRequestAdapter.adapt(params);
		return this.http.post<number>(`${this.apiUrl}Stock/MagasinTemplate`, dataTosend, {
			headers: {
				'X-Loader-Key': 'create-template-store',
			},
		});
	}

	editTemplateStore(params: editTemplateStoreParams, storeTypeId: number): Observable<void> {
		const dataToSend = this.templateStoreEditRequestAdapter.adapt({ params: params, storeTypeId: storeTypeId });
		return this.http.put<void>(`${this.apiUrl}Stock/MagasinTemplate/${params.id}`, dataToSend, { headers: { 'X-Loader-Key': 'edit-template-store' } });
	}

	findTemplateStoreById(id: number): Observable<TemplateStore> {
		return this.http.get<DTO_template_store>(`${this.apiUrl}Stock/MagasinTemplate/${id}`).pipe(map((data: DTO_template_store) => this.templateStoreAdapter.adapt(data)));
	}

	// ARTICLES

	addTemplateStoreArticle(params: addTemplateStoreArticle): Observable<void> {
		const dataToSend = this.templateStoreArticleRequestAdapter.adapt(params);
		return this.http.post<void>(`${this.apiUrl}Stock/MagasinTemplateDefinition`, dataToSend);
	}

	getTemplateStoreArticles(id: number): Observable<Article[]> {
		return this.http
			.get<DTO_template_store_article[]>(`${this.apiUrl}Stock/MagasinTemplateDefinition/Search?MagasinTemplateId=${id}`)
			.pipe(map((definition: DTO_template_store_article[]) => definition.map(item => this.articleAdapter.adapt(item))));
	}

	editTemplateStoreArticle(templateStoreId: number, params: addTemplateStoreArticle): Observable<void> {
		const dataToSend = this.templateStoreArticleRequestAdapter.adapt(params);
		return this.http.put<void>(`${this.apiUrl}Stock/MagasinTemplateDefinition/${templateStoreId}`, dataToSend);
	}

	deleteTemplateStoreArticle(data: { templateStoreId: number; articleId: number }): Observable<void> {
		return this.http.delete<void>(`${this.apiUrl}Stock/MagasinTemplateDefinition/${data.templateStoreId}?articleId=${data.articleId}&magasinTemplateId=${data.templateStoreId}`);
	}
}
