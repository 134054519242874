<div class="cell-actions">
	<button
		(click)="openDocument()"
		[disabled]="!params.value"
		class="btn-sos btn-primary">
		<fa-icon [icon]="faEye"></fa-icon>
	</button>
	<button
		(click)="downloadDocument()"
		[disabled]="!params.value"
		class="btn-sos btn-primary">
		<fa-icon [icon]="faArrowDown"></fa-icon>
	</button>
</div>
