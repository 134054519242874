import { AfterViewInit, Component, ElementRef, Input, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormComponent } from '../../core';
import { selectField } from './select.type';
import { options } from '../core/form.types';
import { ClickOutsideDirective } from '../../../directives';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { sizeDirective } from '../../../directives/utility/size.directive';

@Component({
	selector: 'intranet-select-field',
	standalone: true,
	imports: [CommonModule, ClickOutsideDirective, FaIconComponent, sizeDirective],
	templateUrl: './select-field.component.html',
	styleUrl: './select-field.component.scss',
})
export class SelectFieldComponent extends BaseFormComponent<selectField> implements OnInit, AfterViewInit {
	@ViewChild('list') list!: ElementRef;
	@ViewChild('selectField') selectField!: ElementRef;

	label: WritableSignal<string> = signal('');
	@Input() size: 'sm' | 'md' | 'lg' = 'md';
	@Input() clearable: boolean = false;

	listIsVisible = false;
	readonly faChevronDown = faChevronDown;
	readonly faClose = faCircleXmark;

	displayList() {
		this.listIsVisible = !this.listIsVisible;
		if (this.listIsVisible) {
			this.adjustListPosition();
		}
	}

	adjustListPosition() {
		const selectFieldRect = this.selectField.nativeElement.getBoundingClientRect();
		const viewportHeight = window.innerHeight;
		const spaceBelow = viewportHeight - selectFieldRect.bottom;
		const spaceAbove = selectFieldRect.top;

		if (this.list !== undefined) {
			if (spaceBelow < 300 && spaceAbove > spaceBelow) {
				this.list.nativeElement.classList.add('position-top');
				this.list.nativeElement.classList.remove('position-bottom');
			} else {
				this.list.nativeElement.classList.add('position-bottom');
				this.list.nativeElement.classList.remove('position-top');
			}
		}
	}

	selectListItem(item: options) {
		this.label.set(item.label);
		this.onChange(item.value);
		this.onTouched();
		this.listIsVisible = false;
		this.displayList();
	}

	ngOnInit() {
		this.label.set(this.config().label);		
	}

	ngAfterViewInit() {
		if (this.listIsVisible) {
			this.adjustListPosition();
		}
	}

	clear() {
		this.control.reset();
		this.label.set(this.config().label);
	}
}
