import { BaseModel } from '../../core/base.model';
import { Agency } from '../Agency/Agency';

export type user = {
	id: string;
	firstname: string;
	lastname: string;
	email: string;
};

export type profile = {
	agency: Agency;
	role: role;
};

export type role = {
	id: number;
	name: string;
};

export class User implements BaseModel<user> {
	constructor(user: user) {
		this._id = user.id;
		this._firstname = user.firstname;
		this._lastname = user.lastname;
		this._email = user.email;
	}

	private _id: string;

	get id(): string {
		return this._id;
	}

	set id(value: string) {
		this._id = value;
	}

	private _firstname: string;

	get firstname(): string {
		return this._firstname;
	}

	set firstname(value: string) {
		this._firstname = value;
	}

	private _lastname: string;

	get lastname(): string {
		return this._lastname;
	}

	set lastname(value: string) {
		this._lastname = value;
	}

	private _email: string;

	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
	}

	asObject(): user {
		return {
			id: this._id,
			firstname: this._firstname,
			lastname: this._lastname,
			email: this._email,
		};
	}
}
