import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_article_family } from '../../types/DTO_fia.types';
import { ArticleFamily } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class ArticleFamilyAdapter implements BaseAdapter<DTO_article_family, ArticleFamily> {
	adapt(data: DTO_article_family): ArticleFamily {
		return new ArticleFamily({
			id: data.id,
			label: data.label,
		});
	}
}
