import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_order_search_request } from '../../types/DTO_order.types';


@Injectable({
	providedIn: 'root',
})
export class OrderSearchRequestAdapter implements BaseAdapter<{ intervenantId: number, agencyId: number }, DTO_order_search_request> {
	adapt(data: { intervenantId: number, agencyId: number }): DTO_order_search_request {
		return {
			intervenantId: data.intervenantId,
			agencyId: data.agencyId
		}
	}
}
