export enum DocumentSource {
	ApplicationPatient = 'ApplicationPatient',
	ApplicationTechnicien = 'ApplicationTechnicien',
}

export type DTO_document_technicien = {
	fileId: string;
	fileName: string;
	depositDate: string;
	vu: boolean;
	documentType: string;
	dossierId: number;
	patientId: number;
	patientLastName: string;
	patientFirstName: string;
	patientFullName: string;
	intervenantId: number;
	source: DocumentSource;
	intervenantLastName: string;
	intervenantFirstName: string;
	intervenantFullName: string;
};
