import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { DTO_patient_consommable } from '../types/DTO_patient-consommable.types';
import { map } from 'rxjs';
import { PatientConsommablesAdapter } from '../adapter/patient-consomable.adapter';

@Injectable({
	providedIn: 'root',
})
export class PatientConsommablesRepository extends BaseHttpRepository {
	constructor(private readonly patientConsommableAdapter: PatientConsommablesAdapter) {
		super();
	}

	getPatientConsommables(patientId: number) {
		return this.http
			.get<DTO_patient_consommable[]>(`${this.apiUrl}patient/${patientId}/article/consumables`, { headers: { 'X-Loader-Key': 'get-patient-consumables' } })
			.pipe(map((consos: DTO_patient_consommable[]) => consos.map(conso => this.patientConsommableAdapter.adapt(conso))));
	}

	getEstablishmentConsommables(establishmentId: number) {
		return this.http
			.get<DTO_patient_consommable[]>(`${this.apiUrl}establishments/${establishmentId}/articles/consumables`, { headers: { 'X-Loader-Key': 'get-establishment-consumables' } })
			.pipe(map((consos: DTO_patient_consommable[]) => consos.map(conso => this.patientConsommableAdapter.adapt(conso))));
	}
}
