import { Component, EventEmitter, input, Input, InputSignal, Output } from '@angular/core';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent, GridSizeChangedEvent, RowClassParams, SizeColumnsToContentStrategy } from 'ag-grid-community';
import { DataTableService } from './data-table.service';
import { AG_GRID_LOCALE_FR } from './local.fr';
import { AgGridModule } from 'ag-grid-angular';
import { NgStyle } from '@angular/common';
import { NoRowsOverlayComponent, NoRowsOverlayParamsExtra } from './no-rows-overlay/no-rows-overlay.component';

@Component({
	selector: 'intranet-data-table',
	standalone: true,
	templateUrl: './data-table.component.html',
	imports: [AgGridModule, NgStyle],
	styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent<T> {
	noRowsOverlayParams: InputSignal<NoRowsOverlayParamsExtra> = input<NoRowsOverlayParamsExtra>({});

	@Input() public columnDefs: ColDef[] = [];
	@Input() public rowData: unknown[] = [];
	@Input() paginationState = false;
	@Input() height: number = 100;
	@Input() autoSizing: boolean = false;
	@Input() rowStyle!: (params: RowClassParams) => any;
	@Input() rowClass!: (params: RowClassParams) => string | string[] | undefined;

	@Output() gridIsReady: EventEmitter<GridReadyEvent<unknown>> = new EventEmitter<GridReadyEvent<unknown>>();
	@Output() rowIsClicked: EventEmitter<T> = new EventEmitter<T>();
	@Output() colIsClicked: EventEmitter<{ data: T; colId: string }> = new EventEmitter<{ data: T; colId: string }>();
	@Output() actionsDownloadIsCliked: EventEmitter<T> = new EventEmitter<T>();
	@Output() actionsOpenIsCliked: EventEmitter<T> = new EventEmitter<T>();
	@Output() actionIsTrigger: EventEmitter<T> = new EventEmitter<T>();

	public autoSizeStrategy: SizeColumnsToContentStrategy = {
		type: 'fitCellContents',
	};

	public context: GridOptions;
	public localeText: { [key: string]: string } = AG_GRID_LOCALE_FR;
	public defaultColDef: ColDef = {
		editable: false,
		sortable: true,
		resizable: true,
		filter: true,
		minWidth: 100,
		cellStyle: { fontSize: '13px', fontWeight: 400 },
	};

	constructor(private dataTableService: DataTableService) {
		this.context = {
			context: {
				componentParent: this,
			},
		} as GridOptions;

		this.rowClass = params => {
			if (params.node && params.node.rowIndex !== null && params.node.rowIndex % 2 !== 0) {
				return 'stripped-row';
			}
			return '';
		};
	}

	public get style(): { width: string; height: string } {
		return { width: '100%', height: `${this.height}%` };
	}

	public onGridReady(params: GridReadyEvent<unknown>) {
		this.dataTableService.gridContext = params.api;
		this.gridIsReady.emit(params);
		if (this.autoSizing == true) {
			params.api.autoSizeAllColumns();
		} else {
			params.api.sizeColumnsToFit();
		}
	}

	public onGridChange(params: GridReadyEvent<unknown>) {
		this.dataTableService.gridContext = params.api;
		if (this.autoSizing == true) {
			params.api.autoSizeAllColumns();
		} else {
			params.api.sizeColumnsToFit();
		}
	}

	public onGridSizeChanged(event: GridSizeChangedEvent) {
		if (this.autoSizing == true) {
			event.api.autoSizeAllColumns();
		}
	}

	public onCellClicked(event: CellClickedEvent) {
		this.rowIsClicked.emit(event.data);
		this.colIsClicked.emit({ data: event.data, colId: event.column.getColId() });
	}

	public actionTrigger(data: T) {
		this.actionIsTrigger.emit(data);
	}

	public noRowsOverlayComponent = NoRowsOverlayComponent;
}
