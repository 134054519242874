import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { DTO_techIntervention, DTO_technician, DTO_weeklyPlanning } from '../types/DTO-planning-tech.types';
import { ToastrService } from 'ngx-toastr';
import { HttpContext } from '@angular/common/http';
import { CUSTOM_ERROR_MSG } from '../../core/interceptors/http-errors.interceptor';
import { TechnicianAdapter } from '../adapters/technician.adapter';
import { TechInterventionAdapter } from '../adapters/techInterventiondapter';
import { WeeklyPlanningAdapter } from '../adapters/weeklyPlanning.adapter';
import { searchTechniciansParams, TechIntervention, techInterventionEditrequestParams, Technician, weeklyTechPlanningRequestParams } from '@domain';

@Injectable({ providedIn: 'root' })
export class PlanningTechRepository extends BaseHttpRepository {
	constructor(
		private technicianAdapter: TechnicianAdapter,
		private techInterventionAdapter: TechInterventionAdapter,
		private readonly toastrService: ToastrService,
		private weeklyPlaningAdapter: WeeklyPlanningAdapter,
	) {
		super();
	}

	listTechs(params: searchTechniciansParams): Observable<Technician[]> {
		return this.http.get<DTO_technician[]>(`${this.apiUrl}Agency/${params.agencyId}/technicians`).pipe(
			map((data: DTO_technician[]) => {
				return data.map(technician => this.technicianAdapter.adapt(technician));
			}),
		);
	}

	getPlannings(params: weeklyTechPlanningRequestParams): Observable<TechIntervention[]> {
		const intervenantsIds = this.buildIntervenantIds(params.intervenantIds);
		return this.http
			.get<DTO_weeklyPlanning[]>(`${this.apiUrl}Intervention/?startDate=${params.startDate}&endDate=${params.endDate}&agencyId=${params.agencyId}${intervenantsIds}`, {
				headers: { 'X-Loader-Key': 'tech-plannings' },
			})
			.pipe(
				map((data: DTO_weeklyPlanning[]) => {
					return data.map(planning => this.weeklyPlaningAdapter.adapt(planning));
				}),
			);
	}

	getTechIntervention(techInterventionId: number): Observable<TechIntervention> {
		return this.http
			.get<DTO_techIntervention>(`${this.apiUrl}Intervention/${techInterventionId}/DetailIntervention`, {
				headers: { 'X-Loader-Key': 'tech-intervention-details' },
			})
			.pipe(
				map((data: DTO_techIntervention) => {
					return this.techInterventionAdapter.adapt(data);
				}),
			);
	}

	editTechIntervention(techinInterventionID: number, params: techInterventionEditrequestParams): Observable<void> {
		return this.http
			.put<void>(`${this.apiUrl}Intervention/${techinInterventionID}`, params, {
				context: new HttpContext().set(CUSTOM_ERROR_MSG, true),
			})
			.pipe(
				take(1),
				map(() => {
					this.toastrService.success("L'intervention a bien été modifiée", '', {
						timeOut: 3000,
						closeButton: true,
						progressBar: true,
					});
					return;
				}),
			);
	}

	private buildIntervenantIds(strIds: string) {
		let ids = strIds.split(',');
		if (ids && ids.length > 0) {
			let ret = '';
			ids.forEach(id => {
				ret += '&intervenantIds=' + id;
			});
			return ret;
		} else {
			return '';
		}
	}
}
