import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModalComponent } from '../header-modal/header-modal.component';

@Component({
	selector: 'intranet-modal',
	standalone: true,
	imports: [CommonModule, HeaderModalComponent],
	templateUrl: './modal.component.html',
	styleUrl: './modal.component.scss',
})
export class ModalComponent {
	@Output() isClosed: EventEmitter<void> = new EventEmitter();

	closeModal() {
		this.isClosed.emit();
	}
}
