import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_dosage } from '../types/DTO_treatment.types';
import { PatientDosage } from '@domain';

export class PatientDosageAdapter implements BaseAdapter<DTO_dosage, PatientDosage> {
	adapt(data: DTO_dosage): PatientDosage {
		return new PatientDosage({
			id: data.id,
			title: data.title,
			prescribingDoctor: data.prescribingDoctor,
			debit: data.debit,
			duration: data.duration,
			endDate: data.endDate,
			startDate: data.startDate,
		});
	}
}
