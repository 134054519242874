import { HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { DTO_available_roles, DTO_user_roles, DTO_users, DTO_usersDetails } from '../types/DTO_user-management.types';
import { Injectable } from '@angular/core';
import UserAdapter from '../adapters/user.adapter';
import UserAgencyRoleAdapter from '../adapters/user-agency-role.adapter';
import AvailableRoleAdapter from '../adapters/available-role.adapter';
import UserRoleCreationAdapter from '../adapters/user-role.adapter';
import UserDetailsAdapter from '../adapters/user-details.adapter';
import { Agency, role, searchUsersParams, User, userRoleParams } from '@domain';
import { environment } from '../../../../../../global-environment/environment';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';

@Injectable({
	providedIn: 'root',
})
export class UserManagementRepository extends BaseHttpRepository  {
	constructor(
		private userAdapter: UserAdapter,
		private userDetailsapter: UserDetailsAdapter,
		private readonly userAgencyRole: UserAgencyRoleAdapter,
		private readonly userRoleCreationAdapter: UserRoleCreationAdapter,
		private availableRoleAdapter: AvailableRoleAdapter,
	) {
		super();
	}

	getUsers(params: searchUsersParams): Observable<User[]> {
		const httpParams: HttpParams = this.generateHttpParams(params);

		return this.http
			.get<DTO_users[]>(`${this.apiUrl}UserManagement/Users`, {
				params: httpParams,
				headers: { 'X-Loader-Key': 'users' },
			})
			.pipe(map((users: DTO_users[]) => users.map(user => this.userAdapter.adapt(user))));
	}

	getUserById(userID: string): Observable<User> {
		return this.http.get<DTO_usersDetails>(`${this.apiUrl}UserManagement/User/${userID}`).pipe(map((user: DTO_usersDetails) => this.userDetailsapter.adapt(user)));
	}

	getUserRoles(userId: string): Observable<Map<Agency, role[]>> {
		return this.http.get<DTO_user_roles[]>(`${this.apiUrl}UserManagement/Users/${userId}/Roles`).pipe(map((roles: DTO_user_roles[]) => this.userAgencyRole.adapt(roles)));
	}

	getAvailableRoles(): Observable<role[]> {
		return this.http
			.get<DTO_available_roles[]>(`${this.apiUrl}UserManagement/${environment.userType}/AvailableRoles`)
			.pipe(map((roles: DTO_available_roles[]) => roles.sort((a, b) => a.label.localeCompare(b.label)).map(role => this.availableRoleAdapter.adapt(role))));
	}

	addRole(params: userRoleParams): Observable<void> {
		return this.http.post<void>(`${this.apiUrl}UserManagement/Users/${params.userId}/Roles`, this.userRoleCreationAdapter.adapt(params));
	}

		removeRole(params: userRoleParams): Observable<void> {
		return this.http.delete<void>(`${this.apiUrl}UserManagement/Users/${params.userId}/Roles`, { body: this.userRoleCreationAdapter.adapt(params) });
	}
}
