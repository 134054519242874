import { BaseModel } from '../../core/base.model';
import { TechIntervention } from './techIntervention';

export type technician = {
	id: number;
	name: string;
};

export type slot = {
	hour: number;
	displayedHour: string;
	interventionDates: Array<{
		date: number;
		interventions: Array<TechIntervention>;
	}>;
};

export type TechnicianPlanning = {
	technician: Technician;
	slots: Array<slot>;
};

export class Technician implements BaseModel<technician> {
	constructor(tech: technician) {
		this._id = tech.id;
		this._name = tech.name;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	asObject(): technician {
		return {
			id: this.id,
			name: this.name,
		};
	}
}
