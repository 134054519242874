import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { pagination, PaginationHelpers } from '@application';

export enum PaginationEvent {
	PREVIOUS = 'previous',
	NEXT = 'next',
}

@Component({
	selector: 'intranet-pagination',
	standalone: true,
	imports: [CommonModule, FaIconComponent, FormsModule],
	templateUrl: './pagination.component.html',
	styleUrl: './pagination.component.scss',
})
export class PaginationComponent implements OnInit {
	@Input()
	paginationData: pagination = {
		total: 0,
		page: 1,
		limit: 10,
		nbTotalPage: 0,
	};

	@Output() changePageNumber: EventEmitter<number> = new EventEmitter<number>();
	@Output() changePageSize: EventEmitter<number> = new EventEmitter<number>();

	readonly faChevronLeft: IconDefinition = faChevronLeft;
	readonly faChevronRight: IconDefinition = faChevronRight;
	readonly pageSizeOptions = [10, 20, 40, 60, 80, 100];

	currentPageSize = 10;
	protected readonly PaginationHelpers = PaginationHelpers;
	protected readonly PaginationEvent = PaginationEvent;

	changeSize(value: number) {
		this.changePageSize.emit(value);
	}

	previous() {
		if (this.paginationData.page > 1) {
			this.changePageNumber.emit(this.paginationData.page - 1);
		}
	}

	next() {
		if (this.paginationData.page < this.paginationData.nbTotalPage) {
			this.changePageNumber.emit(this.paginationData.page + 1);
		}
	}

	getPaginationLabels() {
		const labelList: number[] = [];
		if (this.paginationData.nbTotalPage > 6) {
			let i = this.paginationData.page === 1 ? 2 : this.paginationData.page;

			while (i <= this.paginationData.nbTotalPage && labelList.length < 4) {
				if (i < this.paginationData.nbTotalPage) {
					labelList.push(i);
				}
				i++; // Incrémentation pour éviter une boucle infinie
			}
			if (labelList.length < 4) {
				let iBack = this.paginationData.page - 1;
				while (labelList.length < 4) {
					// console.warn('iBack', iBack);
					labelList.unshift(iBack);
					iBack--;
				}
			}
		} else {
			for (let i = 2; i <= this.paginationData.nbTotalPage - 1; i++) {
				labelList.push(i);
			}
		}
		return labelList;
	}

	selectPage(page: number) {
		if (page !== this.paginationData.page) {
			this.changePageNumber.emit(page);
		}
	}

	ngOnInit() {
		this.currentPageSize = this.paginationData.limit;
	}
}
