import { Component, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientAddress } from '@domain';
import { DataBoxPhoneNumberComponent } from '../data-box-phone-number/data-box-phone-number.component';

@Component({
	selector: 'intranet-data-box-address',
	standalone: true,
	imports: [CommonModule, DataBoxPhoneNumberComponent],
	templateUrl: './data-box-address.component.html',
	styleUrl: './data-box-address.component.scss',
})
export class DataBoxAddressComponent {
	address: InputSignal<PatientAddress> = input.required();
}
