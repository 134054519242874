import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import DocumentTechnicienAdapter from '../../document-technicien/adapter/document-technicien.adapter';
import { DTO_document_technicien } from '../../document-technicien/types/DTO_document_technicien.types';
import { DTO_agency } from '../types/DTO_agency.types';
import { map, Observable } from 'rxjs';
import { DTO_agencyEmployeeInfo } from '../types/DTO_agencyEmployeeInfo.types';
import { AgencyAdapter } from '../adapters/agency.adapter';
import { AgencyEmployeeAdapter } from '../adapters/agencyEmployee.adapter';
import { TechnicienAdapter } from '../adapters/technicien.adapter';
import { documentsParamSearch, Technicien, TechnicienDocument } from '@domain';
import { paginatedResult } from '@application';

@Injectable({
	providedIn: 'root',
})
export class AgencyRepository extends BaseHttpRepository {
	constructor(
		private readonly documentTechnicienAdapter: DocumentTechnicienAdapter,
		private readonly agencyAdapter: AgencyAdapter,
		private readonly agencyEmployeeAdapter: AgencyEmployeeAdapter,
		private readonly technicienAdapter: TechnicienAdapter,
	) {
		super();
	}

	listAgencies() {
		return this.http.get<DTO_agency[]>(`${this.apiUrl}agency/list`).pipe(
			map(agencies => {
				return agencies.map(agency => {
					return this.agencyAdapter.adapt(agency);
				});
			}),
		);
	}

	listIntervenants(agencyId?: number) {
		let url = this.apiUrl + 'Agency/Employees/search';
		if (agencyId) {
			url += '?AgencyId=' + agencyId;
		}
		return this.http
			.get<DTO_agencyEmployeeInfo>(url, {
				params: {
					Profile: 'INTERVENANT',
					PageSize: 200,
					PageNumber: 1,
				},
			})
			.pipe(
				map(intervenantsInfo => {
					return intervenantsInfo.items.map(intervenant => {
						return this.agencyEmployeeAdapter.adapt(intervenant);
					});
				}),
			);
	}

	listAgencyTechniciens(agencyId: number): Observable<Technicien[]> {
		let url = `${this.apiUrl}Agency/${agencyId}/technicians`;
		return this.http.get<Technicien[]>(url).pipe(
			map(techniciens => {
				return techniciens.map(technicien => {
					return this.technicienAdapter.adapt(technicien);
				});
			}),
		);
	}

	searchDocumentsTechniciens(param: documentsParamSearch, agencyId: number): Observable<paginatedResult<TechnicienDocument[]>> {
		return this.paginatedGet<DTO_document_technicien[]>(`${this.apiUrl}agency/${agencyId}/PatientDocumentSearch`, param).pipe(
			map((data: paginatedResult<DTO_document_technicien[]>) => {
				return {
					data: data.data.map((documentTechnicien: DTO_document_technicien) => this.documentTechnicienAdapter.adapt(documentTechnicien)),
					pagination: data.pagination,
				};
			}),
		);
	}

	getAgencyById(agencyId: number) {
		return this.http.get<DTO_agency>(`${this.apiUrl}agency/${agencyId}`).pipe(
			map(agency => {
				return this.agencyAdapter.adapt(agency);
			}),
		);
	}
}
