import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { DTO_store_definition_item } from '../../types/DTO_store.types';
import { Injectable } from '@angular/core';
import { Article } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class StoreDefinitionAdapter implements BaseAdapter<DTO_store_definition_item, Article> {
	adapt(data: DTO_store_definition_item): Article {
		return new Article({
			id: data.articleId,
			name: data.label,
			location: data.location,
			min: data.min,
			max: data.max,
		});
	}
}
