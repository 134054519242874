import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_order } from '../../types/DTO_order.types';
import { Agency, order, Order, OrderItem } from '@domain';


@Injectable({
	providedIn: 'root',
})
export class OrderSearchResultAdapter implements BaseAdapter<DTO_order, Order> {
	adapt(data: DTO_order): Order {
		return new Order({
			id: data.id,
			intervenantId: data.intervenantId,
			agency: new Agency({ id: data.agencyId, name: data.agencyName }),
			deliveryAddress: {
				street: data.deliveryAddress.street,
				building: data.deliveryAddress.building,
				cityCode: data.deliveryAddress.cityCode,
				cityName: data.deliveryAddress.cityName,
			},
			comment: data.comments,
			items: data.articles.map((item => new OrderItem({ articleId: item.articleId, name: item.label, quantity: item.quantity })))
		});
	}
}
