import { Injectable } from '@angular/core';
import { BaseAdapter, DTO_doctor_patient } from '@infrastructure';
import { DoctorPatient, doctorPatient } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class DoctorPatientAdapter implements BaseAdapter<DTO_doctor_patient, DoctorPatient> {
	adapt(data: DTO_doctor_patient): DoctorPatient {
		const doctorPatient: doctorPatient = {
			id: data.id,
			firstname: data.firstName,
			lastname: data.lastName,
			civility: data.civility,
			birthDate: data.birthDate,
			insee: data.insee,
			hasTelesuivie: data.hasTelesuive,
			isActive: data.isActive,
			isDeceased: data.isDeceased,
			agencyName: data.agencyName,
		};

		return new DoctorPatient(doctorPatient);
	}
}
