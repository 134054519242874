import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { DateTime } from 'luxon';

@Injectable({
	providedIn: 'root',
})
export class ColdefManager {
	public generateColdef(data: { [key: string]: any }, mapTraduction: Map<string, string>): ColDef[] {
		const colDefs: ColDef[] = [];
		Object.keys(data).forEach((key: string) => {
			colDefs.push({
				field: key,
				headerName: mapTraduction.get(key) ? mapTraduction.get(key) : key,
				valueFormatter: data => (data.value && key.includes('Date') ? DateTime.fromISO(data.value).toLocaleString(DateTime.DATE_FULL) : data.value),
			});
		});
		return colDefs;
	}
}
