import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_agency } from '../types/DTO_agency.types';
import { Injectable } from '@angular/core';
import { Agency } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class AgencyAdapter implements BaseAdapter<DTO_agency, Agency> {
	adapt(data: DTO_agency): Agency {
		return new Agency({
			id: data.migrationId,
			name: data.name,
			idV2: data.id,
		});
	}
}
