import { BaseModel } from '../../core/base.model';

export type modulePermission = {
	slug: string;
	create: boolean;
	update: boolean;
	read: boolean;
	delete: boolean;
};

export class ModulePermission implements BaseModel<modulePermission> {
	constructor(modulePermission: modulePermission) {
		this._slug = modulePermission.slug;
		this._create = modulePermission.create;
		this._update = modulePermission.update;
		this._read = modulePermission.read;
		this._delete = modulePermission.delete;
	}

	private _slug: string;

	get slug(): string {
		return this._slug;
	}

	set slug(value: string) {
		this._slug = value;
	}

	private _create: boolean;

	get create(): boolean {
		return this._create;
	}

	set create(value: boolean) {
		this._create = value;
	}

	private _update: boolean;

	get update(): boolean {
		return this._update;
	}

	set update(value: boolean) {
		this._update = value;
	}

	private _read: boolean;

	get read(): boolean {
		return this._read;
	}

	set read(value: boolean) {
		this._read = value;
	}

	private _delete: boolean;

	get delete(): boolean {
		return this._delete;
	}

	set delete(value: boolean) {
		this._delete = value;
	}

	asObject(): modulePermission {
		return {
			slug: this.slug,
			create: this.create,
			update: this.update,
			read: this.read,
			delete: this.delete,
		};
	}
}
