import { Injectable } from '@angular/core';
import { DTO_establishment } from '../types/dto_establishment';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { Establishment } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class EstablishmentAdapter implements BaseAdapter<DTO_establishment, Establishment[]> {
	adapt(data: DTO_establishment): Establishment[] {
		let ret: Establishment[] = [];
		data.establishmentServices.forEach(service => {
			ret.push(
				new Establishment({
					immatriculation: data.immatriculation,
					name: data.name,
					addressBuilding: data.addressBuilding,
					addressStreet: data.addressStreet,
					addressComplement: data.addressComplement,
					addressCityCode: data.addressCityCode,
					addressCityName: data.addressCityName,
					agencyId: service.agency.id,
					agencyName: service.agency.name,
					isActive: service.isActive,
				}),
			);
		});

		return ret;
	}
}
