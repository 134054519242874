import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormComponent } from '../../core';
import { radio } from './radio.types';
import { FormsModule } from '@angular/forms';
import { options } from '../core/form.types';

@Component({
	selector: 'intranet-radio',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './radio.component.html',
	styleUrl: './radio.component.scss',
})
export class RadioComponent extends BaseFormComponent<radio> implements OnInit {
	radioConf: WritableSignal<radio> = signal({} as radio);

	updateRadio(item: options) {
		const conf = this.radioConf();
		conf.options.forEach(option => {
			option.state = false;
		});
		this.radioConf.set(conf);

		item.state = true;
		this.onChange(item.value);
		this.onTouched();
	}

	ngOnInit() {
		this.radioConf.set(this.config());
	}
}
