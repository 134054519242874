import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { DTO_store_search_request } from '../../types/DTO_store.types';
import { Injectable } from '@angular/core';
import { pagination } from '@application';
import { searchStoresParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class StoreSearchRequestAdapter
	implements
		BaseAdapter<
			{
				params: searchStoresParams;
				pagination: pagination;
			},
			DTO_store_search_request
		>
{
	adapt(data: { params: searchStoresParams; pagination: pagination }): DTO_store_search_request {
		const ret: DTO_store_search_request = {
			PageNumber: data.pagination.page,
			PageSize: data.pagination.limit,
		};
		if (data.params.magasinTypeEnum) ret.magasinTypeEnum = data.params.magasinTypeEnum;
		if (data.params.agencyId) ret.agencyId = data.params.agencyId;
		if (data.params.storeName) ret.magasinName = data.params.storeName;
		if (data.params.isDisabled != null) ret.isDisabled = data.params.isDisabled;

		return ret;
	}
}
