import { Injectable } from '@angular/core';
import { paginatedResult } from '@application';
import { searchSupplyRequestParams, SupplyRequest, supplyRequestStatus } from '@domain';
import { map, Observable, of } from 'rxjs';
import { DTO_supply_request, DTO_supply_request_search_request, DTO_supply_request_search_result } from '../types/DTO_supply-requests.types';
import { ToastrService } from 'ngx-toastr';
import SupplyRequestSearchresultAdapter from '../adapters/supply-requests/supply-request-search-result.adapter';
import SupplyRequestSearchAdapter from '../adapters/supply-requests/supply-request-search.adapter';
import SupplyRequestAdapter from '../adapters/supply-requests/supply-request.adapter';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { getEnumKeyByValue } from '../../core/types/enums-helpers';

@Injectable({ providedIn: 'root' })
export class SupplyRequestRepository extends BaseHttpRepository {
	constructor(
		private supplyRequestAdapter: SupplyRequestAdapter,
		private supplyRequestSearchAdapter: SupplyRequestSearchAdapter,
		private supplyRequestSearchResultAdapter: SupplyRequestSearchresultAdapter,
		private toastrService: ToastrService
	) {
		super();
	}

	listSupplyRequest(params: searchSupplyRequestParams): Observable<paginatedResult<SupplyRequest[]>> {
		const dataToSend: DTO_supply_request_search_request = this.supplyRequestSearchAdapter.adapt(params);

		return this.paginatedGet<DTO_supply_request_search_result[]>(`${this.apiUrl}Supplies/SupplyRequests`, dataToSend, { 'X-Loader-Key': 'supply-requests' }).pipe(
			map((data: paginatedResult<DTO_supply_request_search_result[]>) => {
				// SPECIFIC MESSAGE WHEN NO DATA
				if (data.data.length == 0) {
					this.toastrService.warning('Aucune demande de fourniture ne correspondant à vos critères de recherche n\'a été trouvé', '', {
						timeOut: 3000,
						closeButton: true,
						progressBar: true,
					});
				}

				return {
					data: data.data.map(supplyRequest => this.supplyRequestSearchResultAdapter.adapt(supplyRequest)),
					pagination: data.pagination,
				};
			}),
		);
	}

	retrieveSupplyrequest(agencyId: number, userId: number, status: supplyRequestStatus): Observable<SupplyRequest> {
		let params = this.generateHttpParams({ agencyId: agencyId, intervenantId: userId, status: getEnumKeyByValue(status, supplyRequestStatus) });
		return this.http
			.get<DTO_supply_request>(`${this.apiUrl}Supplies/SupplyRequest`, { params })
			.pipe(map((supplyRequest: DTO_supply_request) => this.supplyRequestAdapter.adapt(supplyRequest)));
	}

}
