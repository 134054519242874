import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_agency_v2 } from '../types/DTO_auth.types';
import { Agency } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class AgencyV2Adapter implements BaseAdapter<DTO_agency_v2, Agency> {
	adapt(data: DTO_agency_v2): Agency {
		return new Agency({
			id: data.id,
			name: data.name,
		});
	}
}
