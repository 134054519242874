import { BaseModel } from '../../core/base.model';

export type userModule = {
	id: number;
	slug: string;
};

export class UserModule implements BaseModel<userModule> {
	constructor(store: userModule) {
		this._id = store.id;
		this._slug = store.slug;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _slug: string;

	get slug(): string {
		return this._slug;
	}

	set slug(value: string) {
		this._slug = value;
	}

	asObject(): userModule {
		return {
			id: this.id,
			slug: this.slug,
		};
	}
}
