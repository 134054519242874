import { BaseModel } from '../../core/base.model';

export type patientCase = {
	id: number;
	rdvMedecinDate: string;
	doctorFullName: string;
};

export class PatientCase implements BaseModel<patientCase> {
	constructor(data: patientCase) {
		this._id = data.id;
		this._rdvMedecinDate = data.rdvMedecinDate;
		this._doctorFullName = data.doctorFullName;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _rdvMedecinDate: string;

	get rdvMedecinDate(): string {
		return this._rdvMedecinDate;
	}

	set rdvMedecinDate(value: string) {
		this._rdvMedecinDate = value;
	}

	private _doctorFullName: string;

	get doctorFullName(): string {
		return this._doctorFullName;
	}

	set doctorFullName(value: string) {
		this._doctorFullName = value;
	}

	asObject(): patientCase {
		return {
			id: this._id,
			rdvMedecinDate: this._rdvMedecinDate,
			doctorFullName: this._doctorFullName,
		};
	}
}
