import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_technician } from '../../planning-tech/types/DTO-planning-tech.types';
import { Technicien } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class TechnicienAdapter implements BaseAdapter<DTO_technician, Technicien> {
	adapt(data: DTO_technician): Technicien {
		return new Technicien({
			id: data.id,
			fullName: data.fullName,
		});
	}
}
