import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_treatment } from '../types/DTO_treatment.types';
import { DTO_prescription } from '../../prescription/type/DTO_prescription.types';
import { PrescriptionAdapter } from '../../prescription/adapter/prescription.adapter';
import { TreatmentTypeAdapter } from './treatment-type.adapter';
import { Prescription, prescription, Treatment, treatment } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class TreatmentAdapter implements BaseAdapter<DTO_treatment, Treatment> {
	private prescriptionAdapter = new PrescriptionAdapter();
	private treatmentTypeAdapter = new TreatmentTypeAdapter();

	constructor() {}

	adapt(data: DTO_treatment): Treatment {
		const dataTreatment: treatment = {
			initialDate: data.initialDate,
			initialPrescribingDoctor: data.initialPrescribingDoctor,
			labels: data.labels,
			order: data.order,
			prescriptions: data.prescriptions.map((prescription: DTO_prescription) => this.prescriptionAdapter.adapt(prescription)),
			treatmentTypes: data.treatmentTypes.map(DTOTreatmentType => this.treatmentTypeAdapter.adapt(DTOTreatmentType)),
		};

		const treatment = new Treatment(dataTreatment);

		dataTreatment.prescriptions.forEach((prescription: prescription) => {
			treatment.prescriptions.push(new Prescription(prescription));
		});

		return treatment;
	}
}
