import { ViewContainerRef } from '@angular/core';

export abstract class BaseInjectorManager {
	private _anchor!: ViewContainerRef;

	get anchor(): ViewContainerRef {
		return this._anchor;
	}

	set anchor(value: ViewContainerRef) {
		this._anchor = value;
	}
}
