import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { inputFieldTypes } from './input-field.types';
import { BaseFormComponent } from '../../core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCircleXmark, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { sizeDirective } from '../../../directives/utility/size.directive';
import { ClickOutsideDirective } from '../../../directives';

@Component({
	selector: 'intranet-text-field',
	standalone: true,
	imports: [CommonModule, FaIconComponent, sizeDirective, ClickOutsideDirective],
	templateUrl: './text-field.component.html',
	styleUrl: './text-field.component.scss',
})
export class TextFieldComponent extends BaseFormComponent<inputFieldTypes> implements OnInit {
	@Input() size: 'sm' | 'md' | 'lg' = 'md';

	@ViewChild('label') label!: ElementRef;
	@ViewChild('input') input!: ElementRef;

	readonly faCircleXmark: IconDefinition = faCircleXmark;

	addFocusToInput() {
		this.label.nativeElement.classList.add('floating');
		this.input.nativeElement.focus();
	}

	removeFocusInput() {
		if (!this.isNotEmpty) {
			this.label.nativeElement.classList.remove('floating');
		}
	}

	clearField() {
		this.control.reset();
	}

	ngOnInit() {
		this.disabled = this.config().isDisabled;
	}

	protected getChange(event: any) {
		this.onChange(event.target.value);
		this.onTouched();
	}
}
