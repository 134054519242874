<div class="list">

	@if (displayResult()) {
		<div class="list-header">
			<h4>Résultats</h4>
			<div class="data-count" *ngIf="dataSource.length > 0">
				<span class="badge-count">{{ dataSource.length }}</span>
			</div>
		</div>
	}

	<div class="body" [class.border]="displayBorder()" >
		@if (isLoading()) {
			<div class="col">
				<ngx-skeleton-loader appearance="line" count="16" />
			</div>
		} @else {

			@if (dataSource.length === 0) {
				<div class="col">
					<p class="no-data">Aucunes données disponibles</p>
				</div>
			} @else {
				@for (col of colDef(); track col; let i = $index; let f = $first) {
					<div class="col">
						@for (item of getDataFromCol(i, col); track $index) {
							<div *ngIf="$first" [class.border]="displayBorder()" class="col-header">
								{{ col.headerName }}
								<fa-icon (click)="sortByColumn(col)" *ngIf="isSortable()" [icon]="faSort" size="1x"></fa-icon>
							</div>
							<intranet-list-item
								(rowIsClicked)="selectItem($event, col)"
								[componentId]="componentId"
								[dataSource]="dataSource"
								[first]="f"
								[index]="$index"
								[isCollapsible]="isCollapsible()"
								[isStripped]="isStripped()"
								[item]="item"
								[odd]="$odd"
								[col]="col"
								[isClickable]="isClickable">
							</intranet-list-item>
						}
					</div>
				}
			}
		}
	</div>

</div>

