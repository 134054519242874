import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export default class SessionStorageService {
	setItem<V>(key: string, value: V, force = true) {
		if (!this.getIem(key) || (this.getIem(key) && force)) {
			window.sessionStorage.setItem(key, JSON.stringify(value));
		}
	}

	getIem<T>(key: string): T | null {
		const result = window.sessionStorage.getItem(key);
		return result ? JSON.parse(result) : null;
	}

	removeItem(key: string) {
		window.sessionStorage.removeItem(key);
	}

	clear() {
		sessionStorage.clear();
	}
}
