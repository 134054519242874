import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { DTO_store } from '../../types/DTO_store.types';
import { Injectable } from '@angular/core';
import { Store, storeType } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class StoreAdapter implements BaseAdapter<DTO_store, Store> {
	adapt(data: DTO_store): Store {
		return new Store({
			id: data.id,
			name: data.name,
			storeType: storeType[data.magasinType as keyof typeof storeType],
			operator: {
				id: data.intervenantId,
				fullName: data.intervenantName,
			},
			storeAgency: {
				id: data.agencyId,
				name: data.agencyName,
			},
			phone1: data.phoneNumber1,
			phone2: data.phoneNumber2,
			address: data.address,
			contactLastname: data.contactLastName,
			contactFirstname: data.contactFirstName,
			contactEmail: data.contactEmail,
			immat: data.plaqueImmatriculation,
			isDisabled: data.isDisabled,
		});
	}
}
