import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_patient_document } from '../types/DTO_patient-document.types';
import { Injectable } from '@angular/core';
import { PatientDocument, patientDocument } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientDocumentAdapter implements BaseAdapter<DTO_patient_document, PatientDocument> {
	adapt(data: DTO_patient_document): PatientDocument {
		const document: patientDocument = {
			dossierId: data.dossierId,
			depositDate: data.depositDate,
			fileId: data.fileId,
			fileName: data.fileName ? data.fileName : '',
			intervenantFullName: data.intervenantFullName ? data.intervenantFullName : '',
			documentType: data.documentType,
			source: data.source,
			vu: data.vu,
		};

		return new PatientDocument(document);
	}
}
