import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_user_role } from '../types/DTO_user-management.types';
import { userRoleParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class UserRoleAdapter implements BaseAdapter<userRoleParams, DTO_user_role> {
	adapt(roleParam: userRoleParams): DTO_user_role {
		return {
			userId: roleParam.userId,
			agencyId: roleParam.agencyId!,
			roleId: roleParam.roleId,
		};
	}
}
