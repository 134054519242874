import { Injectable } from '@angular/core';
import { BaseAdapter, DTO_typeIntervention } from '@infrastructure';
import { TypeIntervention, typeIntervention } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class TypeInterventionAdapter implements BaseAdapter<DTO_typeIntervention, TypeIntervention> {
	adapt(data: DTO_typeIntervention): TypeIntervention {
		const typeIntervention: typeIntervention = {
			id: data.id,
			label: data.label,
			isMep: data.isMep,
			enterDatePlanned: data.enterDatePlanned,
			interventionPatient: data.interventionPatient,
			categoryId: data.categoryId,
			rayonId: data.rayonId,
			idNew: data.idNew,
			delayNew: data.delayNew,
			delayEchangeNew: data.delayEchangeNew,
		};

		return new TypeIntervention(typeIntervention);
	}
}
