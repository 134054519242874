import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { DTO_intervention } from '../types/DTO_intervention';
import { map, Observable } from 'rxjs';
import { InterventionAdapter } from '../adapter/intervention.adapter';
import { paginatedResult } from '@application';
import { Intervention } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class InterventionRepository extends BaseHttpRepository {
	constructor(private readonly interventionAdapter: InterventionAdapter) {
		super();
	}

	getInterventionByPatientId(params: { [key: string]: any }): Observable<paginatedResult<Intervention[]>> {
		return this.paginatedGet<DTO_intervention[]>(`${this.apiUrl}intervention/search`, params, { 'X-Loader-Key': 'patient-interventions' }).pipe(
			map((result: paginatedResult<DTO_intervention[]>) => {
				return {
					data: result.data.map(intervention => this.interventionAdapter.adapt(intervention)),
					pagination: result.pagination,
				};
			}),
		);
	}
}
