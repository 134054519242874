import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_order_supply_request } from '../../types/DTO_order.types';


@Injectable({
	providedIn: 'root',
})
export class OrderSupplyRequestAdapter implements BaseAdapter<number[], DTO_order_supply_request> {
	adapt(ids: number[]): DTO_order_supply_request {
		return {
			supplyRequestIds: ids
		}
	}
}
