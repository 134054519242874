import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '@components';
import { AuthenticationManager, pagination } from '@application';

@Component({
	selector: 'intranet-base',
	standalone: true,
	imports: [CommonModule],
	template: '',
})
export abstract class BasePaginatedPageComponent extends BaseComponent {

	protected readonly authenticationManager: AuthenticationManager = inject(AuthenticationManager);

	protected pagination: pagination = {
		total: 0,
		page: 1,
		limit: 10,
		nbTotalPage: 0,
	};

	set page(pageNumber: number) {
		this.pagination.page = pageNumber;
	}

	set limit(limit: number) {
		this.pagination.limit = limit;
	}

	protected setPagination(pageNumber: number | null, agency: string | number | null = null) {
		const pageNumberToUse = pageNumber || this.pagination.page;
		const userAgency = (agency !== null) ? agency : this.authenticationManager.store.value.currentUserAgency!.idV2;
		return {
			PageNumber: pageNumberToUse,
			PageSize: this.pagination.limit,
			AgencyId: userAgency,
		};
	}

}
