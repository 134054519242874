import { BaseModel } from '../../../core/base.model';
import { storeType } from '../../../enums/store-type-enum';
import { medicalDeviceStatus } from '../../../enums/medical-device-status-enum';
import { Article } from './article';
import { MedicalDevice } from './medicalDevice';
import { MedicalConsommable } from './medicalConsommable';
import { storeAgency, storeOperator } from './store-types';

export type store = {
	id: number;
	name: string;
	storeType: storeType | null;
	operator: storeOperator | null;
	storeAgency: storeAgency | null;
	medicalDevices?: MedicalDevice[];
	medicalConsommables?: MedicalConsommable[];
	definitionItems?: Article[];

	immat?: string;
	phone1?: string;
	phone2?: string;
	address?: string;
	contactLastname?: string;
	contactFirstname?: string;
	contactEmail?: string;

	isDisabled: boolean;
};

export class Store implements BaseModel<store> {
	constructor(store: store) {
		this._id = store.id;
		this._name = store.name;
		this._storeType = store.storeType;
		this._operator = store.operator;
		this._storeAgency = store.storeAgency;
		this._medicalDevices = [];
		this._medicalConsommables = [];
		this._definitionItems = [];
		this._immat = store.immat;
		this._phone1 = store.phone1;
		this._phone2 = store.phone2;
		this._address = store.address;
		this._contactLastname = store.contactLastname;
		this._contactFirstname = store.contactFirstname;
		this._contactEmail = store.contactEmail;
		this._isDisabled = store.isDisabled;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	private _storeType: storeType | null;

	get storeType(): storeType | null {
		return this._storeType;
	}

	set storeType(value: storeType | null) {
		this._storeType = value;
	}

	private _operator: storeOperator | null;

	get operator(): storeOperator | null {
		return this._operator;
	}

	set operator(value: storeOperator | null) {
		this._operator = value;
	}

	private _storeAgency: storeAgency | null;

	get storeAgency(): storeAgency | null {
		return this._storeAgency;
	}

	set storeAgency(value: storeAgency | null) {
		this._storeAgency = value;
	}
	get storeName(): string {
		return this.storeAgency ? this.storeAgency.name : '';
	}

	private _medicalDevices?: MedicalDevice[];

	get medicalDevices(): MedicalDevice[] | undefined {
		return this._medicalDevices;
	}

	set medicalDevices(value: MedicalDevice[]) {
		this._medicalDevices = value;
	}

	private _medicalConsommables?: MedicalConsommable[];

	get medicalConsommables(): MedicalConsommable[] | undefined {
		return this._medicalConsommables;
	}

	set medicalConsommables(value: MedicalConsommable[]) {
		this._medicalConsommables = value;
	}

	private _definitionItems?: Article[];

	get definitionItems(): Article[] | undefined {
		return this._definitionItems;
	}

	set definitionItems(value: Article[]) {
		this._definitionItems = value;
	}

	private _immat?: string;

	get immat(): string | undefined {
		return this._immat;
	}

	set immat(value: string) {
		this._immat = value;
	}

	private _phone1?: string;

	get phone1(): string | undefined {
		return this._phone1;
	}

	set phone1(value: string) {
		this._phone1 = value;
	}

	private _phone2?: string;

	get phone2(): string | undefined {
		return this._phone2;
	}

	set phone2(value: string) {
		this._phone2 = value;
	}

	private _address?: string;

	get address(): string | undefined {
		return this._address;
	}

	set address(value: string) {
		this._address = value;
	}

	private _contactLastname?: string;

	get contactLastname(): string | undefined {
		return this._contactLastname;
	}

	set contactLastname(value: string) {
		this._contactLastname = value;
	}

	private _contactFirstname?: string;

	get contactFirstname(): string | undefined {
		return this._contactFirstname;
	}

	set contactFirstname(value: string) {
		this._contactFirstname = value;
	}

	private _contactEmail?: string;

	get contactEmail(): string | undefined {
		return this._contactEmail;
	}

	set contactEmail(value: string) {
		this._contactEmail = value;
	}

	private _isDisabled: boolean;

	get isDisabled(): boolean {
		return this._isDisabled;
	}

	set isDisabled(value: boolean) {
		this._isDisabled = value;
	}

	get isSale(): boolean {
		return this._storeType == storeType.Sale;
	}

	// RETURN THE TOTAL AMOUNT OF ALL CONSOMMABLES
	get totalAmount(): number {
		let ret = 0;
		if (this.medicalConsommables) {
			this.medicalConsommables.forEach(conso => {
				if (conso != null && conso.price) {
					ret += conso.price;
				}
			});
			return ret;
		} else {
			return 0;
		}
	}

	get totalDevicePieces() {
		let ret = 0;

		if (this.medicalDevices) {
			this.medicalDevices.forEach(device => {
				ret += device.pieces.length;
			});
		}
		return ret;
	}

	get transferableStatuses(): medicalDeviceStatus[] {
		let ret: medicalDeviceStatus[] = [];
		switch (this.storeType) {
			case storeType.Agence:
			case storeType.Etablissement:
			case storeType.Patient:
				ret = [medicalDeviceStatus.Actif];
				break;

			case storeType.Vehicule:
				ret = [medicalDeviceStatus.Actif, medicalDeviceStatus.Defectueux, medicalDeviceStatus.RetourPatient, medicalDeviceStatus.Quarantaine];
				break;

			case storeType.Quarantaine:
				ret = [medicalDeviceStatus.Quarantaine];
				break;

			case storeType.Sale:
				ret = [medicalDeviceStatus.Sale, medicalDeviceStatus.Defectueux, medicalDeviceStatus.RetourPatient];
				break;

			case storeType.Desinfection:
				ret = [medicalDeviceStatus.Desinfection, medicalDeviceStatus.Defectueux];
				break;

			case storeType.Test:
				ret = [medicalDeviceStatus.Desinfection, medicalDeviceStatus.Defectueux];
				break;

			case storeType.SAV:
				ret = [medicalDeviceStatus.Desinfection];
				break;
			case storeType.Rebus:
				ret = [medicalDeviceStatus.RetireDuParc];
				break;

			default:
				break;
		}
		return ret;
	}

	get displayedStatus(): string {
		return this._isDisabled ? 'Désactivé' : 'Activé';
	}

	get storeTypeDisplay(): string {
		switch (this.storeType) {
			case storeType.Agence:
				return 'Agence';
			case storeType.Vehicule:
				return 'Véhicule';
			case storeType.Etablissement:
				return 'Établissement';
			case storeType.Quarantaine:
				return 'Quarantaine';
			case storeType.Sale:
				return 'Sale';
			case storeType.Desinfection:
				return 'Désinfection';
			case storeType.Test:
				return 'Test';
			case storeType.SAV:
				return 'SAV';
			case storeType.Patient:
				return 'Patient';
			case storeType.Rebus:
				return 'Rebus';
			default:
				return '';
		}
	}

	asObject(): store {
		return {
			id: this.id,
			name: this.name,
			storeType: this.storeType,
			operator: this.operator,
			storeAgency: this.storeAgency,
			medicalDevices: this.medicalDevices,
			medicalConsommables: this.medicalConsommables,
			definitionItems: this.definitionItems,
			immat: this.immat,
			phone1: this.phone1,
			phone2: this.phone2,
			address: this.address,
			contactLastname: this.contactLastname,
			contactFirstname: this.contactFirstname,
			contactEmail: this.contactEmail,
			isDisabled: this.isDisabled,
		};
	}
}
