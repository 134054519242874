import { Observable } from 'rxjs';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

type categoryFile = 'Prescription' | 'InterventionObservance';

@Injectable({ providedIn: 'root' })
export class FileRepository extends BaseHttpRepository {
	uploadDocument(file: File): Observable<string> {
		const formData = new FormData();
		formData.append('file', file);
		const headers = new HttpHeaders({
			Accept: 'application/json',
			'X-Loader-Key': 'upload-document',
		});

		return this.http.post<string>(`${this.apiUrl}File/Upload`, formData, { headers });
	}

	getDocumentFromDocumentType(id: number, categoryFile: categoryFile) {
		return this.http.get(`${this.apiUrl}File/legacy/${id}?categoryFile=${categoryFile}`, {
			responseType: 'blob',
		});
	}
}
