import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_piece_medical_device } from '../../types/DTO_store.types';
import { MedicalDevicePiece, Store } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class MedicalDevicePieceAdapter implements BaseAdapter<DTO_piece_medical_device, MedicalDevicePiece> {
	adapt(dto: DTO_piece_medical_device): MedicalDevicePiece {
		return new MedicalDevicePiece({
			name: dto.label,
			serialNumber: dto.serialNumber,
			parkCode: dto.codeParc,
			idParc: dto.idParc,
			familleCode: dto.codeFamille,
			familleArticleId: dto.familleArticleId,
			familleArticleLabel: dto.familleArticleLabel,
			magasin: new Store({
				id: -1,
				name: dto.magasin,
				storeAgency: null,
				storeType: null,
				operator: null,
				isDisabled: false,
			}),
			state: dto.state,
			fournisseurId: dto.fournisseurId,
			fournisseurName: dto.fournisseurName,
			purchaseDate: dto.purchaseDate,
			firstCommissioning: dto.firstCommissioning,
			warrantyEndDate: dto.warrantyEndDate,
			controlDate: dto.controlDate,
			transferHistory: null,
			patient: null,
			articleId: dto.idArticle,
		});
	}
}
