import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_fia } from '../../types/DTO_fia.types';
import { Fia } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class FiaAdapter implements BaseAdapter<DTO_fia, Fia> {
	adapt(data: DTO_fia): Fia {
		return new Fia({
			idArticle: data.idArticle,
			articleCategory: data.articleCategory,
			name: data.name,
			familyName: data.familyName,
			familyCode: data.familyCode,
			comments: data.comments,
			providerName: data.providerName,
			familyId: null,
			tva: data.tva,
			providerReference: data.providerReference,
			noticeUrl: data.noticeUrl,
			codeIMD: data.codeIMD,
		});
	}
}
