import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-data-box-custom-item',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './data-box-custom-item.component.html',
	styleUrl: './data-box-custom-item.component.scss',
})
export class DataBoxCustomItemComponent {}
