import { FormControl, FormGroup } from '@angular/forms';
import { toggleOption } from '@components';

export type docsTechniciensFormFields = {
	startDate: FormControl<string | null>;
	endDate: FormControl<string | null>;
	intervenantId: FormControl<{ label: string; value: any } | null>;
	patientDocumentType: FormControl<string | null>;
	vu: FormControl<boolean | null>;
	source: FormControl<string | null>;
};

export const docsTechniciensFormGroup: FormGroup<docsTechniciensFormFields> = new FormGroup<docsTechniciensFormFields>({
	startDate: new FormControl<string | null>(null, []),
	endDate: new FormControl<string | null>(null, []),
	intervenantId: new FormControl<{ label: string; value: any } | null>(null, []),
	patientDocumentType: new FormControl<string | null>(null, []),
	vu: new FormControl<boolean | null>(null, []),
	source: new FormControl<string | null>(null, []),
});

export const acquittementToggleConfig: toggleOption[] = [
	{
		value: null,
		state: true,
		label: 'Tous',
	},
	{
		value: true,
		state: false,
		label: 'Oui',
	},
	{
		value: false,
		state: false,
		label: 'Non',
	},
];

export const sourceToggleConfig: toggleOption[] = [
	{
		value: null,
		state: true,
		label: 'Tous',
	},
	{
		value: 'ApplicationPatient',
		state: false,
		label: 'Patient',
	},
	{
		value: 'ApplicationTechnicien',
		state: false,
		label: 'Tech',
	},
];
