import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DocumentFile, DocumentImage, DocumentPdf } from '@domain';

export type documentBlob = {
	type: 'image' | 'pdf' | 'other';
	blob: Blob;
};

@Injectable({
	providedIn: 'root',
})
export class DocumentAdapter implements BaseAdapter<Blob, DocumentFile> {
	adapt(blob: Blob): DocumentFile {
		const imageExtensions = ['jpg', 'jpeg', 'png', 'svg', 'gif'];
		const pdfExtension = ['pdf'];
		const fileData = blob.type.split('/');
		const extension = fileData[fileData.length - 1];
		if (imageExtensions.indexOf(extension) > -1) {
			return new DocumentImage(blob);
		} else if (pdfExtension.indexOf(extension) > -1) {
			return new DocumentPdf(blob);
		} else {
			return new DocumentFile(blob);
		}
	}
}
