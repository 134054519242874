import { BaseModel } from '../../../core/base.model';
import { treatment, treatmentType } from '../../../types/patient-treatment';
import { Prescription } from '../../prescription/prescription';

export class Treatment implements BaseModel<treatment> {
	constructor(data: treatment) {
		this._order = data.order;
		this._labels = data.labels;
		this._initialDate = data.initialDate;
		this._initialPrescribingDoctor = data.initialPrescribingDoctor;
		this._prescriptions = data.prescriptions;
		this._treatmentTypes = data.treatmentTypes;
	}

	private _labels: string[];

	get labels(): string[] {
		return this._labels;
	}

	set labels(value: string[]) {
		this._labels = value;
	}

	private _initialDate: string;

	get initialDate(): string {
		return this._initialDate;
	}

	set initialDate(value: string) {
		this._initialDate = value;
	}

	private _initialPrescribingDoctor: string;

	get initialPrescribingDoctor(): string {
		return this._initialPrescribingDoctor;
	}

	set initialPrescribingDoctor(value: string) {
		this._initialPrescribingDoctor = value;
	}

	private _order: number;

	get order(): number {
		return this._order;
	}

	set order(value: number) {
		this._order = value;
	}

	private _prescriptions: Prescription[];

	get prescriptions(): Prescription[] {
		return this._prescriptions;
	}

	set prescriptions(value: Prescription[]) {
		this._prescriptions = value;
	}

	private _treatmentTypes: treatmentType[];

	get treatmentTypes(): treatmentType[] {
		return this._treatmentTypes;
	}

	set treatmentTypes(value: treatmentType[]) {
		this._treatmentTypes = value;
	}

	asObject(): treatment {
		return {
			labels: this._labels,
			initialDate: this._initialDate,
			initialPrescribingDoctor: this._initialPrescribingDoctor,
			order: this._order,
			prescriptions: this._prescriptions,
			treatmentTypes: this._treatmentTypes,
		};
	}
}
