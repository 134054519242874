import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstablishmentAdapter } from '../adapter/establishment.adapter';
import { DTO_establishment } from '../types/dto_establishment';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Establishment } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class EstablishmentRepository extends BaseHttpRepository {
	constructor(private readonly establishmentAdapter: EstablishmentAdapter) {
		super();
	}

	getDoctorEstablishments(rpps: string): Observable<Establishment[]> {
		return this.http.get<DTO_establishment[]>(`${this.apiUrl}doctors/${rpps}/establishments`).pipe(
			map((data: DTO_establishment[]) => {
				let ret: Establishment[] = [];
				data.map(establishment => (ret = ret.concat(this.establishmentAdapter.adapt(establishment))));
				return ret;
			}),
		);
	}
}
