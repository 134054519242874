import { Component, input, InputSignal, Optional, Self } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'intranet-base-form',
	standalone: true,
	imports: [CommonModule],
	template: '',
})
export abstract class BaseFormComponent<T> implements ControlValueAccessor {
	config: InputSignal<T> = input.required<T>();

	value!: any;
	disabled = false;

	constructor(@Optional() @Self() public ngControl: NgControl) {
		if (this.ngControl != null) {
			this.ngControl.valueAccessor = this;
		}
	}

	get isError() {
		return this.control?.invalid && ((this.control?.dirty || this.control?.touched) as boolean);
	}

	protected get control() {
		return this.ngControl.control as AbstractControl;
	}

	protected get isNotEmpty() {
		return this.control.value !== null && this.control.value !== '';
	}

	onChange: any = (value: any) => {};

	onTouched: any = () => {};

	writeValue(val: any): void {
		this.value = val;
		this.onChange(val);
		this.onTouched(val);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
