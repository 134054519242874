import { DateTime } from 'luxon';
import { BaseModel } from '../../../core/base.model';
import { Technician } from '../../planning-tech/technician';
import { Article } from './article';
import { Agency } from '../../Agency/Agency';

export type supplyRequest = {
	number: number;
	agency: Agency;
	technician: Technician | null;
	creationDate: string;
	deliveryDate: string;
	submissionDate: string;
	orderId: number | null;
	articles: { article: Article, quantity: number }[];
	selected: boolean;
};

export class SupplyRequest implements BaseModel<supplyRequest> {
	constructor(request: supplyRequest) {
		this._number = request.number;
		this._agency = request.agency;
		this._technician = request.technician;
		this._creationDate = request.creationDate;
		this._deliveryDate = request.deliveryDate;
		this._submissionDate = request.submissionDate;
		this._orderId = request.orderId;
		this._articles = request.articles;
		this._selected = request.selected;
	}

	private _number: number;

	get number(): number {
		return this._number;
	}

	set number(value: number) {
		this._number = value;
	}

	private _agency: Agency;

	get agency(): Agency {
		return this._agency;
	}

	set agency(value: Agency) {
		this._agency = value;
	}

	private _technician: Technician | null;

	get technician(): Technician | null {
		return this._technician;
	}

	set technician(value: Technician | null) {
		this._technician = value;
	}

	private _creationDate: string;

	get creationDate(): string {
		return this._creationDate;
	}

	set creationDate(value: string) {
		this._creationDate = value;
	}

	get displayedCreationDate(): string {
		return this.creationDate ? DateTime.fromFormat(this.creationDate, 'yyyy-MM-dd\'T\'HH:mm:ss.S').toFormat("dd MMM yyyy 'à' HH:mm") : '-';
	}

	private _deliveryDate: string;

	get deliveryDate(): string {
		return this._deliveryDate;
	}

	set deliveryDate(value: string) {
		this._deliveryDate = value;
	}

	get displayedDeliveryDate(): string {
		return this.deliveryDate ? DateTime.fromFormat(this.deliveryDate, 'yyyy-MM-dd\'T\'HH:mm:ss.S').toFormat("dd MMM yyyy 'à' HH:mm") : '-';
	}	

	private _submissionDate: string;

	get submissionDate(): string {
		return this._submissionDate;
	}

	set submissionDate(value: string) {
		this._submissionDate = value;
	}

	get displayedSubmissionDate(): string {
		return this.submissionDate ? DateTime.fromFormat(this.submissionDate, 'yyyy-MM-dd\'T\'HH:mm:ss.S').toFormat("dd MMM yyyy 'à' HH:mm") : '-';
	}

	private _orderId: number | null;

	get orderId(): number | null{
		return this._orderId;
	}

	set orderId(value: number | null) {
		this._orderId = value;
	}

	private _articles: { article: Article, quantity: number }[];

	get articles(): { article: Article, quantity: number }[] {
		return this._articles;
	}

	set articles(value: { article: Article, quantity: number }[]) {
		this._articles = value;
	}

	private _selected: boolean;

	get selected(): boolean {
		return this._selected;
	}

	set selected(value: boolean) {
		this._selected = value;
	}

	asObject(): supplyRequest {
		return {
			number: this.number,
			agency: this.agency,
			technician: this.technician,
			creationDate: this.creationDate,
			deliveryDate: this.deliveryDate,
			submissionDate: this.submissionDate,
			orderId: this.orderId,
			articles: this.articles,
			selected: this.selected
		};
	}

}
