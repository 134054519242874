import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_store_consommable } from '../../types/DTO_store.types';
import { MedicalConsommable } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class StoreMedicalConsommableAdapter implements BaseAdapter<DTO_store_consommable, MedicalConsommable> {
	adapt(dto: DTO_store_consommable): MedicalConsommable {
		return new MedicalConsommable({
			articleId: dto.articleId,
			name: dto.label,
			location: dto.location,
			quantity: dto.quantity,
			price: dto.totalPrice,
			min: dto.minLimit,
			max: dto.maxLimit,
			peremptionDate: dto.peremptionThreshold,
		});
	}
}
