<div (click)="displayList = !displayList" class="container-control">

	<div class="control-start-icon">
		<fa-icon [icon]="faCalendar"></fa-icon>
	</div>

	<button class="input-control">{{ label() }}</button>

	<div class="control-end-icon">
		<fa-icon [icon]="faChevronUp"></fa-icon>
		<fa-icon [icon]="faChevronDown"></fa-icon>
	</div>

</div>

@if (displayList) {
	<div class="container-list"
			 intranetClickOutside
			 (outSideClick)="displayList = false">
		<ul>
			@for (item of data(); track item.label) {
				<li (click)="selectedItem(item)">{{ item.label }}</li>
			}
		</ul>
	</div>
}