import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import AgencyV2Adapter from '../adapters/agencyv2.adapter';
import { map, Observable } from 'rxjs';
import { Agency, ModulePermission, UserModule } from '@domain';
import { DTO_agency_v2, DTO_employee, DTO_module, DTO_permission } from '../types/DTO_auth.types';
import { UserModuleAdapter } from '../../agency/adapters/userModule.adapter';
import { UserAgencyModulePermissionAdapter } from '../../agency/adapters/userAgencyModulePermission.adapter';
import { UserEmployee } from 'core/domain/src/lib/model/user/employee';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationRepository extends BaseHttpRepository {
	constructor(
		private readonly agencyAdapterV2: AgencyV2Adapter,
		private readonly userModuleAdapter: UserModuleAdapter,
		private readonly userAgencyModulePermissionAdapter: UserAgencyModulePermissionAdapter,
	) {
		super();
	}

	getUserAgencies(): Observable<Agency[]> {
		return this.http.get<DTO_agency_v2[]>(`${this.apiUrl}Users/Agencies`).pipe(
			map(agencies => {
				return agencies.map(agency => {
					return this.agencyAdapterV2.adapt(agency);
				});
			}),
		);
	}

	getUserModules(agencyId?: number): Observable<UserModule[]> {
		let params = this.generateHttpParams({ agencyId: agencyId });
		return this.http.get<DTO_module[]>(`${this.apiUrl}Users/Modules`, { params }).pipe(
			map(modules => {
				return modules.map(agency => {
					return this.userModuleAdapter.adapt(agency);
				});
			}),
		);
	}

	getEmployeeIds(): Observable<{ employeeIds: Array<{ agencyId: number; employeeId: number }> }> {
		return this.http.get<DTO_employee>(`${this.apiUrl}Users/Employee`).pipe(
			map(dto => {
				return { employeeIds: dto.employeeIds };
			}),
		);
	}

	getUserAgencyModulePermissions(agencyId: number, moduleSlug: string): Observable<ModulePermission[]> {
		let params = this.generateHttpParams({ agenceId: agencyId, moduleSlug: moduleSlug });
		return this.http.get<DTO_permission[]>(`${this.apiUrl}Users/Permissions`, { params }).pipe(
			map(permissions => {
				return permissions.map(permission => {
					return this.userAgencyModulePermissionAdapter.adapt(permission);
				});
			}),
		);
	}

	getCurrentEmployee(): Observable<UserEmployee> {
		return this.http.get<UserEmployee>(`${this.apiUrl}Users/Employee`);
	}
}
