import { BaseAdapter } from '../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_module } from '../../authentication/types/DTO_auth.types';
import { UserModule } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class UserModuleAdapter implements BaseAdapter<DTO_module, UserModule> {
	adapt(data: DTO_module): UserModule {
		return new UserModule({
			id: data.id,
			slug: data.slug,
		});
	}
}
