import { Injectable } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable } from 'rxjs';
import AuthenticatedUserAdapter from '../adapters/authenticated-user.adapter';
import { AuthenticatedUserData } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class OidcService {
	constructor(
		private readonly oidcClient: OidcSecurityService,
		private readonly authenticatedUserAdapter: AuthenticatedUserAdapter,
	) {}

	authenticate() {
		return this.oidcClient.authorize();
	}

	checkAuth(): Observable<AuthenticatedUserData | null> {
		return this.oidcClient.checkAuth().pipe(
			map((loginResponse: LoginResponse) => {
				if (loginResponse.isAuthenticated) {
					return this.authenticatedUserAdapter.adapt(loginResponse);
				} else {
					this.authenticate();
					return null;
				}
			}),
		);
	}

	logout() {
		this.oidcClient.logoff().subscribe();
	}
}
