import { Injectable } from '@angular/core';
import { uploadPatientDocumentParams } from '@domain';
import { BaseAdapter, DTO_patient_document_request } from '@infrastructure';

@Injectable({
	providedIn: 'root',
})
export class PatientUploadDocumentRequestAdapter implements BaseAdapter<uploadPatientDocumentParams, DTO_patient_document_request> {
	adapt(params: uploadPatientDocumentParams): DTO_patient_document_request {
		const dto: DTO_patient_document_request = {
			fileId: params.fileId,
			patientDocumentType: params.document.documentType,
			intervenantId: params.intervenantId,
		};

		return dto;
	}
}
