import { BaseModel } from '../../core/base.model';

export type documentType = {
	id: number;
	label: string;
	value: string;
};

export class DocumentType implements BaseModel<documentType> {
	constructor(doc: documentType) {
		this._id = doc.id;
		this._label = doc.label;
		this._value = doc.value;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _label: string;

	get label(): string {
		return this._label;
	}

	set label(value: string) {
		this._label = value;
	}

	private _value: string;

	get value(): string {
		return this._value;
	}

	set value(value: string) {
		this._value = value;
	}

	asObject(): documentType {
		return {
			id: this.id,
			label: this.label,
			value: this.value,
		};
	}
}
