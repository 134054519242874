import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_store_dm } from '../../types/DTO_store.types';
import { MedicalDevice, MedicalDevicePiece } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class StoreMedicalDevicePiecesAdapter implements BaseAdapter<DTO_store_dm[], MedicalDevice[]> {
	adapt(dto: DTO_store_dm[]): MedicalDevice[] {
		let ret = new Array<MedicalDevice>();

		dto.forEach(dm => {
			// CHECK IF THE FAMILY NAME HAS BEEN ALREADY CREATED
			let familyIndex = ret.findIndex(device => {
				return device.familyId == dm.familleArticleId;
			});

			// IF DOES NOT EXIST --> CREATE THE FAMILY AND ADD THE PIECE
			if (familyIndex == -1) {
				ret.push(
					new MedicalDevice({
						name: dm.label,
						familyName: dm.familleArticleLabel,
						familyId: dm.familleArticleId,
						pieces: [
							new MedicalDevicePiece({
								name: dm.label,
								parkCode: dm.codeParc,
								serialNumber: dm.serialNumber,
								idParc: null,
								familleCode: null,
								familleArticleId: null,
								familleArticleLabel: null,
								magasin: null,
								state: null,
								fournisseurId: null,
								fournisseurName: null,
								purchaseDate: null,
								firstCommissioning: null,
								warrantyEndDate: null,
								controlDate: null,
								transferHistory: null,
								patient: null,
								articleId: null,
							}),
						],
					}),
				);
			}
			// ADD ONLY THE PIECE TO THE FAMILY
			else {
				ret[familyIndex].pieces.push(
					new MedicalDevicePiece({
						name: dm.label,
						parkCode: dm.codeParc,
						serialNumber: dm.serialNumber,
						idParc: null,
						familleCode: null,
						familleArticleId: null,
						familleArticleLabel: null,
						magasin: null,
						state: null,
						fournisseurId: null,
						fournisseurName: null,
						purchaseDate: null,
						firstCommissioning: null,
						warrantyEndDate: null,
						controlDate: null,
						transferHistory: null,
						patient: null,
						articleId: null,
					}),
				);
			}
		});

		return ret;
	}
}
