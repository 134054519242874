import { BaseModel } from '../../../core/base.model';

export type fia = {
	idArticle: number;
	name: string;
	familyId: number | null;
	familyCode: string | null;
	familyName: string;
	articleCategory: string;
	providerName: string | null;
	providerReference: string | null;
	tva: number | null;
	comments: string | null;
	noticeUrl: string | null;
	codeIMD: string | null;
};

export class Fia implements BaseModel<fia> {
	constructor(fia: fia) {
		this._idArticle = fia.idArticle;
		this._name = fia.name;
		this._familyId = fia.familyId;
		this._familyCode = fia.familyCode;
		this._familyName = fia.familyName;
		this._articleCategory = fia.articleCategory;
		this._providerName = fia.providerName;
		this._providerReference = fia.providerReference;
		this._tva = fia.tva;
		this._comments = fia.comments;
		this._noticeUrl = fia.noticeUrl;
		this._codeIMD = fia.codeIMD;
	}

	private _idArticle: number;

	get idArticle(): number {
		return this._idArticle;
	}

	set idArticle(value: number) {
		this._idArticle = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	private _familyId: number | null;

	get familyId(): number | null {
		return this._familyId;
	}

	set familyId(value: number) {
		this._familyId = value;
	}

	private _familyCode: string | null;

	get familyCode(): string | null {
		return this._familyCode;
	}

	set familyCode(value: string) {
		this._familyCode = value;
	}

	private _familyName: string;

	get familyName(): string {
		return this._familyName;
	}

	set familyName(value: string) {
		this._familyName = value;
	}

	private _articleCategory: string;

	get articleCategory(): string {
		return this._articleCategory;
	}

	set articleCategory(value: string) {
		this._articleCategory = value;
	}

	private _providerName: string | null;

	get providerName(): string | null {
		return this._providerName;
	}

	set providerName(value: string) {
		this._providerName = value;
	}

	private _providerReference: string | null;

	get providerReference(): string | null {
		return this._providerReference;
	}

	set providerReference(value: string) {
		this._providerReference = value;
	}

	private _tva: number | null;

	get tva(): number | null {
		return this._tva;
	}

	set tva(value: number) {
		this._tva = value;
	}

	private _comments: string | null;

	get comments(): string | null {
		return this._comments;
	}

	set comments(value: string) {
		this._comments = value;
	}

	private _noticeUrl: string | null;

	get noticeUrl(): string | null {
		return this._noticeUrl;
	}

	set noticeUrl(value: string) {
		this._noticeUrl = value;
	}

	private _codeIMD: string | null;

	get codeIMD(): string | null {
		return this._codeIMD;
	}

	set codeIMD(value: string) {
		this._codeIMD = value;
	}

	get categoryDisplay() {
		return this.articleCategory == 'Consomable' ? 'Consommable' : this.articleCategory == 'DispositifMedical' ? 'Dispositif Médical' : '-';
	}

	asObject(): fia {
		return {
			idArticle: this.idArticle,
			name: this.name,
			familyId: this.familyId,
			familyCode: this.familyCode,
			familyName: this.familyName,
			articleCategory: this.articleCategory,
			providerName: this.providerName,
			providerReference: this.providerReference,
			tva: this.tva,
			comments: this.comments,
			noticeUrl: this.noticeUrl,
			codeIMD: this.codeIMD,
		};
	}
}
