import { BaseModel } from '../../core/base.model';

export type technicien = {
	id: number;
	fullName: string;
};

export class Technicien implements BaseModel<technicien> {
	constructor(technicien: technicien) {
		this._id = technicien.id;
		this._fullName = technicien.fullName;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _fullName: string;

	get fullName(): string {
		return this._fullName;
	}

	set fullName(value: string) {
		this._fullName = value;
	}

	asObject(): technicien {
		return {
			id: this._id,
			fullName: this._fullName,
		};
	}
}
