import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_add_store_dm } from '../../types/DTO_store.types';
import { addStoreDMParams, storeType } from '@domain';
import { DateTime } from 'luxon';

@Injectable({
	providedIn: 'root',
})
export default class StoreMedicalDeviceCreationRequestAdapter
	implements
		BaseAdapter<
			{
				params: addStoreDMParams;
				storeId: number;
			},
			DTO_add_store_dm
		>
{
	constructor() {
	}
	adapt(data: { params: addStoreDMParams; storeId: number }): DTO_add_store_dm {
		const dto: DTO_add_store_dm = {
			magasinId: data.storeId,
			idArticle: data.params.idArticle,
			codeParc: data.params.codeParc,
			numeroSerie: data.params.serialNumber,
			comment: data.params.comment ? data.params.comment : null,
			parcState: storeType.Sale,

			deliveryNoteNumber: data.params.deliveryNumber,
			receptionDate: data.params.deliveryDate ? DateTime.fromFormat(data.params.deliveryDate, "yyyy-MM-dd").toISO() : null,
			endOfWarrantyDate: data.params.warrantyEndDate ? DateTime.fromFormat(data.params.warrantyEndDate, "yyyy-MM-dd").toISO() : null,
			batchNumber: data.params.batchNumber,
			plannedInspectionDate: data.params.controlCheckDate ? DateTime.fromFormat(data.params.controlCheckDate, "yyyy-MM-dd").toISO() : null,
			intervenantId: data.params.intervenantId,
			manufacturingDate: data.params.manufacturingDate ? DateTime.fromFormat(data.params.manufacturingDate, "yyyy-MM-dd").toISO() : null,
			activationNumber: data.params.dnNumber
		};

		return dto;
	}
}
