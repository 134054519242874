import { createStore, Store, withProps } from '@ngneat/elf';

export abstract class HasStore<P> {
	store: Store<{ name: string; state: P; config: undefined }, P>;

	protected constructor(
		private propsValues: P,
		private name: string,
	) {
		this.store = createStore({ name: this.name }, withProps<P>(this.propsValues));
	}
}
