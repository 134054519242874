import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PatientDocumentAdapter } from '../adapter/patient-document.adapter';
import { DTO_patient_document } from '../types/DTO_patient-document.types';
import { paginatedResult } from '@application';
import { PatientDocument } from '@domain';

export type pagination = {
	pageIndex: number;
	pageSize: number;
};

@Injectable({
	providedIn: 'root',
})
export class PatientDocumentRepository extends BaseHttpRepository {
	constructor(private readonly patientDocumentAdapter: PatientDocumentAdapter) {
		super();
	}

	getPatientDocuments(
		patientId: number,
		params: {
			[key: string]: any;
		},
	): Observable<paginatedResult<PatientDocument[]>> {
		return this.paginatedGet<DTO_patient_document[]>(`${this.apiUrl}patient/${patientId}/documents`, params, { 'X-Loader-Key': 'patient-documents' }).pipe(
			map((data: paginatedResult<DTO_patient_document[]>) => {
				return {
					data: data.data.map(document => this.patientDocumentAdapter.adapt(document)),
					pagination: data.pagination,
				};
			}),
		);
	}
}
