import { Component, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-data-box-title',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './data-box-title.component.html',
	styleUrl: './data-box-title.component.scss',
})
export class DataBoxTitleComponent {
	title: InputSignal<string> = input.required();
}
