import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { BaseFormComponent } from '../../core';
import { faCalendar, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { datePickerTypes } from './date-picker.types';
import { sizeDirective } from '../../../directives/utility/size.directive';

@Component({
	selector: 'intranet-date-picker',
	standalone: true,
	imports: [CommonModule, FaIconComponent, sizeDirective],
	templateUrl: './date-picker.component.html',
	styleUrl: './date-picker.component.scss',
})
export class DatePickerComponent extends BaseFormComponent<datePickerTypes> implements OnInit {
	protected readonly faCalendar = faCalendar;
	protected readonly faCircleXmark = faCircleXmark;

	clearField() {
		this.control.reset();
	}

	ngOnInit() {
		this.disabled = this.config().isDisabled;
	}

	protected getChange(event: any) {
		this.onChange(event.target.value);
		this.onTouched();
	}
}
