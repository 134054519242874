import { BaseModel } from '../../core/base.model';
import { detail, deviceDetail, intervention, visitePatient } from '../../types/intervention';
import { categoryInterventionEnum } from '../../enums/category-intervention.enum';

export class Intervention implements BaseModel<intervention> {
	constructor(data: intervention) {
		this._id = data.id;
		this._realizedDate = data.realizedDate;
		this._realizedHour = data.realizedHour;
		this._plannedDate = data.plannedDate;
		this._comment = data.comment;
		this._description = data.description;
		this._echange = data.echange;
		this._patientId = data.patientId;
		this._typeId = data.typeId;
		this._typeLabel = data.typeLabel;
		this._category = data.category;
		this._dossierId = data.dossierId;
		this._dossierDetailId = data.dossierDetailId;
		this._parkId = data.parkId;
		this._parkCode = data.parkCode;
		this._intervenantId = data.intervenantId;
		this._intervenantFullName = data.intervenantFullName;
		this._intervenantAgencyId = data.intervenantAgencyId;
		this._details = data.details;
		this._deviceDetails = data.deviceDetails;
		this._attachmentName = data.attachmentName;
		this._attachmentUrl = data.attachmentUrl;
		this._visitePatient = data.visitePatient;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _realizedDate: string;

	get realizedDate(): string {
		return this._realizedDate;
	}

	set realizedDate(value: string) {
		this._realizedDate = value;
	}

	private _realizedHour: string;

	get realizedHour(): string {
		return this._realizedHour;
	}

	set realizedHour(value: string) {
		this._realizedHour = value;
	}

	private _plannedDate: string;

	get plannedDate(): string {
		return this._plannedDate;
	}

	set plannedDate(value: string) {
		this._plannedDate = value;
	}

	private _comment: string;

	get comment(): string {
		return this._comment;
	}

	set comment(value: string) {
		this._comment = value;
	}

	private _description: string;

	get description(): string {
		return this._description;
	}

	set description(value: string) {
		this._description = value;
	}

	private _echange: boolean;

	get echange(): boolean {
		return this._echange;
	}

	set echange(value: boolean) {
		this._echange = value;
	}

	private _patientId: number;

	get patientId(): number {
		return this._patientId;
	}

	set patientId(value: number) {
		this._patientId = value;
	}

	private _typeId: number;

	get typeId(): number {
		return this._typeId;
	}

	set typeId(value: number) {
		this._typeId = value;
	}

	private _typeLabel: string;

	get typeLabel(): string {
		return this._typeLabel;
	}

	set typeLabel(value: string) {
		this._typeLabel = value;
	}

	private _category: categoryInterventionEnum;

	get category(): categoryInterventionEnum {
		return this._category;
	}

	set category(value: categoryInterventionEnum) {
		this._category = value;
	}

	private _dossierId: number;

	get dossierId(): number {
		return this._dossierId;
	}

	set dossierId(value: number) {
		this._dossierId = value;
	}

	private _dossierDetailId: number;

	get dossierDetailId(): number {
		return this._dossierDetailId;
	}

	set dossierDetailId(value: number) {
		this._dossierDetailId = value;
	}

	private _parkId: number;

	get parkId(): number {
		return this._parkId;
	}

	set parkId(value: number) {
		this._parkId = value;
	}

	private _parkCode: string;

	get parkCode(): string {
		return this._parkCode;
	}

	set parkCode(value: string) {
		this._parkCode = value;
	}

	private _intervenantId: number;

	get intervenantId(): number {
		return this._intervenantId;
	}

	set intervenantId(value: number) {
		this._intervenantId = value;
	}

	private _intervenantFullName: string;

	get intervenantFullName(): string {
		return this._intervenantFullName;
	}

	set intervenantFullName(value: string) {
		this._intervenantFullName = value;
	}

	private _intervenantAgencyId: number;

	get intervenantAgencyId(): number {
		return this._intervenantAgencyId;
	}

	set intervenantAgencyId(value: number) {
		this._intervenantAgencyId = value;
	}

	private _details: detail[];

	get details(): detail[] {
		return this._details;
	}

	set details(value: detail[]) {
		this._details = value;
	}

	private _deviceDetails: deviceDetail[];

	get deviceDetails(): deviceDetail[] {
		return this._deviceDetails;
	}

	set deviceDetails(value: deviceDetail[]) {
		this._deviceDetails = value;
	}

	private _attachmentName: string;

	get attachmentName(): string {
		return this._attachmentName;
	}

	set attachmentName(value: string) {
		this._attachmentName = value;
	}

	private _attachmentUrl: string;

	get attachmentUrl(): string {
		return this._attachmentUrl;
	}

	set attachmentUrl(value: string) {
		this._attachmentUrl = value;
	}

	private _visitePatient: visitePatient;

	get visitePatient(): visitePatient {
		return this._visitePatient;
	}

	set visitePatient(value: visitePatient) {
		this._visitePatient = value;
	}

	asObject(): intervention {
		return {
			id: this.id,
			realizedDate: this.realizedDate,
			realizedHour: this.realizedHour,
			plannedDate: this.plannedDate,
			comment: this.comment,
			description: this.description,
			echange: this.echange,
			patientId: this.patientId,
			typeId: this.typeId,
			typeLabel: this.typeLabel,
			category: this.category,
			dossierId: this.dossierId,
			dossierDetailId: this.dossierDetailId,
			parkId: this.parkId,
			parkCode: this.parkCode,
			intervenantId: this.intervenantId,
			intervenantFullName: this.intervenantFullName,
			intervenantAgencyId: this.intervenantAgencyId,
			details: this.details,
			deviceDetails: this.deviceDetails,
			attachmentName: this.attachmentName,
			attachmentUrl: this.attachmentUrl,
			visitePatient: this.visitePatient,
		};
	}
}
