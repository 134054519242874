import { BaseModel } from '../../core/base.model';

export type agency = {
	id: number;
	name: string;
	idV2?: string;
};

export class Agency implements BaseModel<agency> {
	constructor(agency: agency) {
		this._id = agency.id;
		this._name = agency.name;
		this._idV2 = agency.idV2 ? agency.idV2 : null;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _idV2: string | null;

	get idV2(): string | null {
		return this._idV2;
	}

	set idV2(value: string) {
		this._idV2 = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	asObject(): agency {
		return {
			id: this.id,
			name: this.name,
			idV2: this.idV2 ? this.idV2 : undefined,
		};
	}
}
