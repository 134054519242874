import { Injectable } from '@angular/core';
import { DocumentFile } from './document-file';

@Injectable({
	providedIn: 'root',
})
export class DocumentPdf extends DocumentFile {
	override open() {
		window.open(URL.createObjectURL(this._blob));
	}
}
