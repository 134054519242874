import { Component, input, InputSignal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
	selector: 'intranet-data-box-accordion-container',
	standalone: true,
	imports: [CommonModule, FaIconComponent],
	templateUrl: './data-box-accordion-container.component.html',
	styleUrl: './data-box-accordion-container.component.scss',
})
export class DataBoxAccordionContainerComponent implements OnInit {
	readonly faChevronUp = faChevronUp;
	readonly faChevronDown: IconDefinition = faChevronDown;

	title: InputSignal<string> = input.required();
	isOpen: InputSignal<boolean> = input(false);
	hasCount: InputSignal<boolean> = input(false);
	count: InputSignal<number> = input(0);

	isToggled = true;

	toggleAccordion() {
		const isOpen = this.isToggled;
		this.isToggled = !isOpen;
	}

	ngOnInit() {
		this.isToggled = this.isOpen();
	}
}
