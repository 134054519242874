import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { Injectable } from '@angular/core';
import SessionStorageService from '../../repository/session.storage.service';
import { DTO_authenticationData } from '../types/DTO_auth.types';

@Injectable({
	providedIn: 'root',
})
export class OidcCustomRepository implements AbstractSecurityStorage {
	constructor(private sessionStorage: SessionStorageService) {}

	read(key: string): DTO_authenticationData {
		return this.sessionStorage.getIem<DTO_authenticationData>('auth') as DTO_authenticationData;
	}

	write(key: string, value: unknown): void {
		this.sessionStorage.setItem('auth', value);
	}

	remove(key: string): void {
		this.sessionStorage.removeItem(key);
	}

	clear(): void {
		this.sessionStorage.clear();
	}
}
