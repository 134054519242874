import { BaseModel } from '../base.model';
import { error } from '@angular/compiler-cli/src/transformers/util';

export type error = {
	code: number;
	message: string;
	details: {
		Details: string;
		Title: string;
		TraceIdentifier: string;
		Type: string;
	};
	timestamp: Date;
};

export class AppError implements BaseModel<error> {
	constructor(error: error) {
		this._code = error.code;
		this._message = error.message;
		this._details = error.details;
		this._timestamp = error.timestamp;

		this._id = this._code + this._timestamp.toISOString();
	}

	private _id: string;

	get id(): string {
		return this._id;
	}

	private _code: number;

	get code(): number {
		return this._code;
	}

	set code(value: number) {
		this._code = value;
	}

	private _message: string;

	get message(): string {
		return this._message;
	}

	set message(value: string) {
		this._message = value;
	}

	private _details: {
		Details: string;
		Title: string;
		TraceIdentifier: string;
		Type: string;
	};

	get details(): { Details: string; Title: string; TraceIdentifier: string; Type: string } {
		return this._details;
	}

	set details(value: { Details: string; Title: string; TraceIdentifier: string; Type: string }) {
		this._details = value;
	}

	private _timestamp: Date;

	get timestamp(): Date {
		return this._timestamp;
	}

	set timestamp(value: Date) {
		this._timestamp = value;
	}

	private _hasBeenDeclared = false;

	get hasBeenDeclared(): boolean {
		return this._hasBeenDeclared;
	}

	set hasBeenDeclared(value: boolean) {
		this._hasBeenDeclared = value;
	}

	asObject(): error {
		return {
			code: this._code,
			message: this._message,
			details: this._details,
			timestamp: this._timestamp,
		};
	}
}
