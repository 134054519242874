import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, of, skipWhile, switchMap } from 'rxjs';
import { AuthenticationManager } from '@application';

@Directive({
	selector: '[hasPermission], [hasNoPermission]',
	standalone: true,
})
export class HasPermissionDirective implements OnInit {
	@Input() hasPermission!: { permissionSlug: string; permissionType: string };
	@Input() hasNoPermission!: { permissionSlug: string; permissionType: string };

	constructor(
		private ref: ElementRef<HTMLElement>,
		private authentificationManager: AuthenticationManager,
		public route: ActivatedRoute,
	) {}

	ngOnInit(): void {
		// HIDE THE HTML ELEMENT BY DEFAULT
		this.ref.nativeElement!.style.visibility = 'hidden';

		// TREATMENT TO DISPLAY THE ELEMENT IF THE PERMISSION IS ALLOWED
		if (this.hasPermission != null) {
			this.route.data
				.pipe(
					switchMap(data => {
						// CHECK IF THE MODULE SLUG IS DEFINED
						if (data['slug'] != null) {
							// CHECK IF THE PERMISSION IS ALLOWED
							return this.isAllowed(data['slug'], this.hasPermission.permissionSlug, this.hasPermission.permissionType);
						} else {
							return of(false);
						}
					}),
				)
				.subscribe(isAllowed => {
					if (isAllowed == true) {
						this.ref.nativeElement!.style.visibility = 'visible';
					}
				});
		}

		// TREATMENT TO DISPLAY THE ELEMENT IF THE PERMISSION IS NOT ALLOWED
		if (this.hasNoPermission != null) {
			this.route.data
				.pipe(
					switchMap(data => {
						// CHECK IF THE MODULE SLUG IS NOT DEFINED
						if (data['slug'] == null) {
							this.ref.nativeElement!.style.visibility = 'visible';
						}

						// CHECK IF THE MODULE SLUG IS DEFINED BUT PERMISSION IS NOT ALLOWED
						if (data['slug'] != null) {
							return this.isAllowed(data['slug'], this.hasNoPermission.permissionSlug, this.hasNoPermission.permissionType);
						} else {
							return of(false);
						}
					}),
				)
				.subscribe(isAllowed => {
					if (isAllowed == false) {
						this.ref.nativeElement!.style.visibility = 'visible';
					}
				});
		}
	}

	public isAllowed(moduleSlug: string, permissionSlug: string, permissionType: string): Observable<boolean> {
		// RETRIEVE THE MODULE SLUG'S PERMISSIONS
		return this.authentificationManager.store.asObservable().pipe(
			skipWhile(state => state.permissions == null),
			skipWhile(state => state.permissions?.get(moduleSlug) == undefined),
			map(state => {
				// FIND THE MODULE SLUG
				const moduleslug = state.permissions?.get(moduleSlug);
				if (moduleslug != undefined) {
					// FIND THE PERMISSION IN THE MODULE SLUG'S PERMISSIONS
					const modulePermissions = moduleslug.find(permission => permission.slug == permissionSlug);
					if (modulePermissions != null) {
						// CHECK IF THE PERMISSION TYPE MATCHES
						switch (permissionType) {
							case 'create':
								return modulePermissions.create == true;
							case 'read':
								return modulePermissions.read == true;
							case 'update':
								return modulePermissions.update == true;
							case 'delete':
								return modulePermissions.delete == true;

							default:
								return false;
						}
					}
					// PERMISSION NOT FOUND
					else {
						return false;
					}
				}

				// MODULE SLUG NOT FOUND
				return false;
			}),
		);
	}
}
