export enum storeType {
	Agence = 'Agence',
	Vehicule = 'Véhicule',
	Etablissement = 'Établissement',
	Quarantaine = 'Quarantaine',
	Sale = 'Sale',
	Desinfection = 'Désinfection',
	Test = 'Test',
	SAV = 'SAV',
	Patient = 'Patient',
	Rebus = 'Rebus',
}
