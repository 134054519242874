import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_prescription } from '../type/DTO_prescription.types';
import { Prescription, prescription } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PrescriptionAdapter implements BaseAdapter<DTO_prescription, prescription> {
	adapt(data: DTO_prescription): Prescription {
		return new Prescription(data);
	}
}
