// TO COMPLETE WITH THE OTHER PERMISSION SLUGS
export enum permissionType {
	CREATESTORE = 'CreationDeMagasin',
	UPDATESTORE = 'EditionDeMagasin',
	MEDICAL_DEVICE = 'PieceDispositifMedical',
	PATIENT_INFORMATIONS = 'Informations',
	CREATETEMPLATESTORE = 'CreationDeMagasinModele',
	UPDATETEMPLATESTORE = 'EditionDeMagasinModele',
	TEMPLATESTORE_AGENCY = 'MagasinModeleNational',
	ORTHOP_CARD = 'ErreursOrthop',
	ORDER = 'Commande',
	AMC_CARD = 'ErreursAmc'
}
