import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_supply_request } from '../../types/DTO_supply-requests.types';
import { Agency, Article, SupplyRequest, Technician } from '@domain';


@Injectable({
	providedIn: 'root',
})
export default class SupplyRequestAdapter implements BaseAdapter<DTO_supply_request, SupplyRequest> {

	adapt(dto: DTO_supply_request): SupplyRequest {
		const articles: Array<{ article: Article, quantity: number }> = dto.articles.map(article => {
			return {
				article: new Article({
					id: article.articleId,
					name: article.label,
					location: "",
					min: -1,
					max: -1
				}),
				quantity: article.quantity
			}
		})

		return new SupplyRequest({
			number: dto.id,
			agency: new Agency({ id: dto.agencyId, name: dto.agencyName }),
			technician: new Technician({
				id: -1,
				name: dto.intervenantName

			}),
			creationDate: dto.creationDate,
			deliveryDate: dto.deliveryDate,
			submissionDate: dto.submissionDate,
			orderId: dto.orderId,
			articles: articles,
			selected: true
		});

	}
}
