import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { ICellRendererParams } from 'ag-grid-community';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CheckboxComponent } from '../../../forms';

@Component({
	selector: 'lib-checkbox-action-cell',
	standalone: true,
	imports: [CommonModule, FaIconComponent, CheckboxComponent, ReactiveFormsModule],
	templateUrl: './checkbox-action-cell.component.html',
	styleUrls: ['./checkbox-action-cell.component.scss'],
})
export class CheckboxActionCellComponent implements ICellRendererAngularComp {
	protected params!: ICellRendererParams;

	public agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	public refresh(params: ICellRendererParams): boolean {
		return false;
	}

	public onChange() {
		this.params.context.context.componentParent.actionTrigger({ action: 'readDocument', data: this.params.data });
	}
}
