import { Component, inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { dynamicType } from '../list.component';
import { ListManager } from './list.manager';
import { tap } from 'rxjs';
import { select } from '@ngneat/elf';
import { BaseComponent } from '../../core';

@Component({
	selector: 'intranet-list',
	standalone: true,
	imports: [],
	template: '',
})
export class BaseListComponent extends BaseComponent implements OnInit {
	@Input() rowId!: number;

	protected data: WritableSignal<dynamicType[]> = signal([]);
	protected isCollapsible: WritableSignal<boolean> = signal(false);
	protected readonly listManager: ListManager = inject(ListManager);

	getDataFromKey(key: string) {
		return this.listManager.getDataByKey(key);
	}

	set collapse(value: boolean) {
		this.isCollapsible.set(value);
		this.listManager.saveState(this.isCollapsible());
	}

	ngOnInit() {
		this.listManager.store
			.pipe(
				select(state => state.data),
				tap(data => {
					data[this.rowId] = Object.assign(data[this.rowId], { isCollapsed: false, rowId: this.rowId });
					this.data.set(data);
				}),
			)
			.subscribe();
	}
}
