export type defaultType = {
	label: string;
	type: inputType;
	isRequired: boolean;
	isDisabled: boolean;
	errorMessages?: string;
};

export enum inputType {
	txt = 'text',
	nbr = 'number',
	psswrd = 'password',
	srch = 'search',
	tel = 'tel',
	email = 'email',
	null = 'null',
	date = 'date',
}

export type options = {
	label: string;
	value: any;
	state?: boolean;
};
