import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-data-box-separator',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './data-box-separator.component.html',
	styleUrl: './data-box-separator.component.scss',
})
export class DataBoxSeparatorComponent {}
