import { BaseAdapter } from '../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_permission } from '../../authentication/types/DTO_auth.types';
import { ModulePermission } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class UserAgencyModulePermissionAdapter implements BaseAdapter<DTO_permission, ModulePermission> {
	adapt(data: DTO_permission): ModulePermission {
		return new ModulePermission({
			slug: data.permissionSlug,
			create: data.create,
			update: data.update,
			read: data.read,
			delete: data.delete,
		});
	}
}
