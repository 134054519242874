import { Component, Input, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-data-box-item',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './data-box-item.component.html',
	styleUrl: './data-box-item.component.scss',
})
export class DataBoxItemComponent {
	data: InputSignal<{ label: string; value: any }> = input.required();
	@Input() isEditable = false;
}
