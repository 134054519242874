import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_template_store_article } from '../../types/DTO_template-store.types';
import { addTemplateStoreArticle } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class TemplateStoreArticleRequestAdapter implements BaseAdapter<addTemplateStoreArticle, DTO_template_store_article> {
	adapt(data: addTemplateStoreArticle): DTO_template_store_article {
		const dto: DTO_template_store_article = {
			magasinTemplateId: data.magasinTemplateId,
			articleId: data.articleId,
			articleName: data.articleName ? data.articleName : undefined,
			minLimit: data.minLimit,
			maxLimit: data.maxLimit,
		};

		return dto;
	}
}
