import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
	selector: '[intranetUtilities]',
	standalone: true,
})
export class sizeDirective {
	@Input()
	size: 'sm' | 'md' | 'lg' = 'md';

	@Input()
	shadow = '-';

	@HostBinding('class')
	get elementClass(): string {
		return `h-${this.size}`;
	}
}