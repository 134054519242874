import { BaseModel } from '../../../core/base.model';

import { DateTime } from 'luxon';
import { Patient } from '../../patient/patient';
import { MedicalDeviceTransfer } from './medicalDeviceTransfer';
import { Store } from './store';

export type medicalDevicePieceDetails = {
	name: string;
	serialNumber: string;
	parkCode: string;

	idParc: number | null;
	familleCode: string | null;
	familleArticleId: number | null;
	familleArticleLabel: string | null;
	magasin: Store | null;
	state: string | null;
	fournisseurId: number | null;
	fournisseurName: string | null;
	purchaseDate: string | null;
	firstCommissioning: string | null;
	warrantyEndDate: string | null;
	controlDate: string | null;
	transferHistory: MedicalDeviceTransfer[] | null;

	patient: Patient | null;
	articleId: number | null;
};

export class MedicalDevicePiece implements BaseModel<medicalDevicePieceDetails> {
	constructor(pdm: medicalDevicePieceDetails) {
		this._name = pdm.name;
		this._serialNumber = pdm.serialNumber;
		this._parkCode = pdm.parkCode;
		this._idParc = pdm.idParc;
		this._familleCode = pdm.familleCode;
		this._familleArticleId = pdm.familleArticleId;
		this._familleArticleLabel = pdm.familleArticleLabel;
		this._magasin = pdm.magasin;
		this._state = pdm.state;
		this._fournisseurId = pdm.fournisseurId;
		this._fournisseurName = pdm.fournisseurName;
		this._purchaseDate = pdm.purchaseDate;
		this._firstCommissioning = pdm.firstCommissioning;
		this._warrantyEndDate = pdm.warrantyEndDate;
		this._controlDate = pdm.controlDate;
		this._transferHistory = pdm.transferHistory ? pdm.transferHistory.sort(this.compareLuxonDates) : null;
		this._patient = pdm.patient;
		this._articleId = pdm.articleId;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	private _serialNumber: string;

	get serialNumber(): string {
		return this._serialNumber;
	}

	set serialNumber(value: string) {
		this._serialNumber = value;
	}

	private _parkCode: string;

	get parkCode(): string {
		return this._parkCode;
	}

	set parkCode(value: string) {
		this._parkCode = value;
	}

	private _idParc: number | null;

	get idParc(): number | null {
		return this._idParc;
	}

	set idParc(value: number | null) {
		this._idParc = value;
	}

	private _familleCode: string | null;

	get familleCode(): string | null {
		return this._familleCode;
	}

	set familleCode(value: string | null) {
		this._familleCode = value;
	}

	private _familleArticleId: number | null;

	get familleArticleId(): number | null {
		return this._familleArticleId;
	}

	set familleArticleId(value: number | null) {
		this._familleArticleId = value;
	}

	private _familleArticleLabel: string | null;

	get familleArticleLabel(): string | null {
		return this._familleArticleLabel;
	}

	set familleArticleLabel(value: string | null) {
		this._familleArticleLabel = value;
	}

	private _magasin: Store | null;

	get magasin(): Store | null {
		return this._magasin;
	}

	set magasin(value: Store | null) {
		this._magasin = value;
	}

	private _state: string | null;

	get state(): string | null {
		return this._state;
	}

	set state(value: string | null) {
		this._state = value;
	}

	private _fournisseurId: number | null;

	get fournisseurId(): number | null {
		return this._fournisseurId;
	}

	set fournisseurId(value: number | null) {
		this._fournisseurId = value;
	}

	private _fournisseurName: string | null;

	get fournisseurName(): string | null {
		return this._fournisseurName;
	}

	set fournisseurName(value: string | null) {
		this._fournisseurName = value;
	}

	private _purchaseDate: string | null;

	get purchaseDate(): string | null {
		return this._purchaseDate;
	}

	set purchaseDate(value: string | null) {
		this._purchaseDate = value;
	}

	private _firstCommissioning: string | null;

	get firstCommissioning(): string | null {
		return this._firstCommissioning;
	}

	set firstCommissioning(value: string | null) {
		this._firstCommissioning = value;
	}

	private _warrantyEndDate: string | null;

	get warrantyEndDate(): string | null {
		return this._warrantyEndDate;
	}

	set warrantyEndDate(value: string | null) {
		this._warrantyEndDate = value;
	}

	private _controlDate: string | null;

	get controlDate(): string | null {
		return this._controlDate;
	}

	set controlDate(value: string | null) {
		this._controlDate = value;
	}

	private _transferHistory: MedicalDeviceTransfer[] | null;

	get transferHistory(): MedicalDeviceTransfer[] | null {
		return this._transferHistory;
	}

	set transferHistory(value: MedicalDeviceTransfer[] | null) {
		this._transferHistory = value ? value.sort(this.compareLuxonDates) : null;
	}

	private _patient: Patient | null;

	get patient(): Patient | null {
		return this._patient;
	}

	set patient(value: Patient | null) {
		this._patient = value;
	}

	private _articleId: number | null;

	get articleId(): number | null {
		return this._articleId;
	}

	set articleId(value: number | null) {
		this._articleId = value;
	}

	get currentLocation(): string | null {
		return this.magasin ? 'Magasin : ' + this.magasin.name : this.patient != null ? 'Patient: ' + this.patient.firstname! + ' ' + this.patient.lastname! : null;
	}

	asObject(): medicalDevicePieceDetails {
		return {
			name: this.name,
			serialNumber: this.serialNumber,
			parkCode: this.parkCode,
			idParc: this.idParc,
			familleCode: this.familleCode,
			familleArticleId: this.familleArticleId,
			familleArticleLabel: this.familleArticleLabel,
			magasin: this.magasin,
			state: this.state,
			fournisseurId: this.fournisseurId,
			fournisseurName: this.fournisseurName,
			purchaseDate: this.purchaseDate,
			firstCommissioning: this.firstCommissioning,
			warrantyEndDate: this.warrantyEndDate,
			controlDate: this.controlDate,
			transferHistory: this.transferHistory ? this.transferHistory.sort(this.compareLuxonDates) : null,
			patient: this.patient,
			articleId: this.articleId,
		};
	}

	private compareLuxonDates(a: MedicalDeviceTransfer, b: MedicalDeviceTransfer) {
		return DateTime.fromISO(b.date).toMillis() - DateTime.fromISO(a.date).toMillis();
	}
}
