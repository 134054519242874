import { BaseModel } from '../../../core/base.model';
import { MedicalDevicePiece } from './pieceMedicalDevice';

export type medicalDevice = {
	name: string;
	familyName: string;
	familyId: number;
	pieces: MedicalDevicePiece[];
};

export class MedicalDevice implements BaseModel<medicalDevice> {
	constructor(dm: medicalDevice) {
		this._name = dm.name;
		this._familyName = dm.familyName;
		this._familyId = dm.familyId;
		this._pieces = dm.pieces;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	private _familyName: string;

	get familyName(): string {
		return this._familyName;
	}

	set familyName(value: string) {
		this._familyName = value;
	}

	private _familyId: number;

	get familyId(): number {
		return this._familyId;
	}

	set familyId(value: number) {
		this._familyId = value;
	}

	private _pieces: MedicalDevicePiece[];

	get pieces(): MedicalDevicePiece[] {
		return this._pieces;
	}

	set pieces(value: MedicalDevicePiece[]) {
		this._pieces = value;
	}

	asObject(): medicalDevice {
		return {
			name: this.name,
			familyId: this.familyId,
			familyName: this.familyName,
			pieces: this.pieces,
		};
	}
}
