import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { TypeInterventionAdapter } from '../adapter/type-intervention.adapter';
import { map, Observable } from 'rxjs';
import { DTO_typeIntervention } from '@infrastructure';

@Injectable({
	providedIn: 'root',
})
export class TypeInterventionRepository extends BaseHttpRepository {
	constructor(private readonly typeInterventionAdapter: TypeInterventionAdapter) {
		super();
	}

	getTypeIntervention(): Observable<DTO_typeIntervention[]> {
		return this.http
			.get<DTO_typeIntervention[]>(`${this.apiUrl}Intervention/Type/Search?PageNumber=1&PageSize=1000000`)
			.pipe(map((data: DTO_typeIntervention[]) => data.map(type => this.typeInterventionAdapter.adapt(type))));
	}
}
