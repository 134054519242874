import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-overlay',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './overlay.component.html',
	styleUrl: './overlay.component.scss',
})
export class OverlayComponent {}
