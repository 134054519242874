import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { DTO_store_definition_item } from '../../types/DTO_store.types';
import { Injectable } from '@angular/core';
import { addStoreDefinitionItemParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class StoreArticleCreationRequestAdapter implements BaseAdapter<addStoreDefinitionItemParams, DTO_store_definition_item> {
	adapt(data: addStoreDefinitionItemParams): DTO_store_definition_item {
		const dto: DTO_store_definition_item = {
			articleId: data.articleId,
			label: data.label!,
			location: data.location!,
			min: data.min!,
			max: data.max!,
		};

		return dto;
	}
}
