import { Directive, HostBinding, input, InputSignal } from '@angular/core';

@Directive({
	selector: '[intranetBtn]',
	standalone: true,
})
export class BtnDirective {
	readonly color: InputSignal<'success' | 'error' | 'warning' | 'primary' | 'secondary' | 'light'> = input.required();
	readonly size: InputSignal<'sm' | 'md' | 'lg'> = input.required();
	readonly expand: InputSignal<boolean> = input(false);


	@HostBinding('class')
	get elementClass(): string {
		const expandClass: string = this.expand() ? 'btn-full' : 'btn-normal';
		return `btn-sos btn-${this.color()} btn-${this.size()} ${expandClass}`;
	}
}
