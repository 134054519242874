import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_treatmentType } from '../types/DTO_treatment.types';
import { TreatmentType, treatmentType } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class TreatmentTypeAdapter implements BaseAdapter<DTO_treatmentType, treatmentType> {
	adapt(data: DTO_treatmentType): TreatmentType {
		return new TreatmentType({
			label: data.label,
			dosages: data.dosages,
			consumables: data.consumables,
			devices: data.devices,
			followUps: data.followUps,
		});
	}
}
