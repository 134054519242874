<div class="accordion-container">

    <div (click)="toggleAccordion()" class="accordion-container-header" >

        <div class="accordion-container-icon">
					<fa-icon [icon]="isToggled ? faChevronUp : faChevronDown" class="icon"></fa-icon>
        </div>

        <h6 class="accordion-container-title">
					{{ title() }}
				</h6>

				<div class="accordion-container-badge">
					<span *ngIf="hasCount()" class="badge info">{{count()}}</span>
				</div>

    </div>

		<div [class.open]="isToggled" class="accordion-container-body">
				<ng-content></ng-content>
		</div>

</div>