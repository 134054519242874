import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { Agency, Order } from '@domain';
import { map, Observable, of } from 'rxjs';
import { DTO_order } from '../types/DTO_order.types';
import { OrderSearchResultAdapter } from '../adapters/order/order-search-result.adapter';
import { OrderSearchRequestAdapter } from '../adapters/order/order-search-request.adapter';
import { OrderCreationAdapter } from '../adapters/order/order-create.adapter';
import { OrderUpdateAdapter } from '../adapters/order/order-update.adapter';
import { OrderSupplyRequestAdapter } from '../adapters/order/order-supply-request.adapter';
import { OrderAdapter } from '../adapters/order/order.adapter';


@Injectable({ providedIn: 'root' })
export class OrderRepository extends BaseHttpRepository {
	constructor(
		private orderSearchResultAdapter: OrderSearchResultAdapter,
		private orderSearchRequestAdapter: OrderSearchRequestAdapter,
		private orderCreationAdapter: OrderCreationAdapter,
		private orderUpdateAdapter: OrderUpdateAdapter,
		private orderSupplyRequestAdapter: OrderSupplyRequestAdapter,
		private orderAdapter: OrderAdapter

	) {
		super();
	}

	findOrderById(id: number): Observable<Order> {
		return this.http.get<DTO_order>(`${this.apiUrl}Supplies/Orders/${id}`).pipe(map((data: DTO_order) => this.orderAdapter.adapt(data)));
	}

	searchCurrentOrder(intervenantId: number, agency: Agency): Observable<Order | null> {
		// ADAPT THE DATA TO SEND
		const dataToSend = this.orderSearchRequestAdapter.adapt({ intervenantId: intervenantId, agencyId: agency.id });

		return this.http.get<DTO_order>(`${this.apiUrl}Supplies/Orders/Intervenant/${intervenantId}`, { params: dataToSend })
			.pipe(map((data: DTO_order) => {
				if (data != null) {
					let order = this.orderSearchResultAdapter.adapt(data);
					order.agency = agency;
					order.intervenantId = intervenantId;
					return order;
				}
				else {
					return null;
				}
			}));

	}

	createEmptyOrder(intervenantId: number, agencyId: number): Observable<number> {
		// ADAPT THE DATA TO SEND
		const dataToSend = this.orderCreationAdapter.adapt({ intervenantId: intervenantId, agencyId: agencyId, status: 'In Progress' });
		return this.http.post<number>(`${this.apiUrl}Supplies/Orders`, dataToSend);
	}


	updateOrder(order: Order): Observable<boolean> {
		// ADAPT THE DATA TO SEND
		const dataToSend = this.orderUpdateAdapter.adapt(order);
		return this.http.put<DTO_order>(`${this.apiUrl}Supplies/Orders/${order.id}`, dataToSend).pipe(map((data: DTO_order) => {
			return true;
		}));
	}

	addSupplyRequestToOrder(orderId: number, ids: number[]): Observable<boolean> {
		// ADAPT THE DATA TO SEND	
		const dataToSend = this.orderSupplyRequestAdapter.adapt(ids);

		return this.http.put<boolean>(`${this.apiUrl}Supplies/Orders/${orderId}/SupplyRequest`, dataToSend).pipe(map((data: boolean) => {
			return true;
		}));
	}
}
