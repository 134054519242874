import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_piece_medical_device_history } from '../../types/DTO_store.types';
import { MedicalDeviceTransfer } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class MedicalDevicePieceHistoryAdapter implements BaseAdapter<DTO_piece_medical_device_history, MedicalDeviceTransfer> {
	adapt(dto: DTO_piece_medical_device_history): MedicalDeviceTransfer {
		return new MedicalDeviceTransfer({
			date: dto.arrivedDate,
			state: dto.arrivedState,
			destinationStore: dto.magasinName,
			intervenantName: dto.intervenantName,
			comment: dto.comment,
		});
	}
}
