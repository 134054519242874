export class StringManager {
	public static concatStrings(strings: string[], separator: string = ' '): string {
		let result = '';
		strings.forEach(str => {
			result = result !== '' && str && str !== '' ? `${result} ${separator}` : result;
			result = str && str !== '' ? `${result} ${str}` : result;
		});
		return result;
	}
}
