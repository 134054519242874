import { BaseModel } from '../../../core/base.model';

export type article = {
	id: number;
	name: string;
	location: string | null;
	min: number;
	max: number;
};

export class Article implements BaseModel<article> {
	constructor(store: article) {
		this._id = store.id;
		this._name = store.name;
		this._location = store.location;
		this._min = store.min;
		this._max = store.max;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	private _location: string | null;

	get location(): string | null {
		return this._location;
	}

	set location(value: string | null) {
		this._location = value;
	}

	private _min: number;

	get min(): number {
		return this._min;
	}

	set min(value: number) {
		this._min = value;
	}

	private _max: number;

	get max(): number {
		return this._max;
	}

	set max(value: number) {
		this._max = value;
	}

	asObject(): article {
		return {
			id: this.id,
			name: this.name,
			location: this.location,
			min: this.min,
			max: this.max,
		};
	}
}
