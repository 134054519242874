import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_create_template_stores_params } from '../../types/DTO_template-store.types';
import { createTemplateStoresParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class TemplateStoreSearchCreationAdapter implements BaseAdapter<createTemplateStoresParams, DTO_create_template_stores_params> {
	adapt(data: createTemplateStoresParams): DTO_create_template_stores_params {
		return {
			name: data.name!,
			agencyId: data.agencyId!,
			magasinTypeId: data.magasinTypeId,
		};
	}
}
