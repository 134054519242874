import { Component, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
	selector: 'intranet-tab-item',
	standalone: true,
	imports: [CommonModule, RouterLink, RouterLinkActive],
	templateUrl: './tab-item.component.html',
	styleUrl: './tab-item.component.scss',
})
export class TabItemComponent {

	tabData: InputSignal<{ path: string, label: string }> = input.required();

}
