import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_template_store_edit_request } from '../../types/DTO_template-store.types';
import { editTemplateStoreParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class TemplateStoreEditRequestAdapter
	implements
		BaseAdapter<
			{
				params: editTemplateStoreParams;
				storeTypeId: number;
			},
			DTO_template_store_edit_request
		>
{
	adapt(data: { params: editTemplateStoreParams; storeTypeId: number }): DTO_template_store_edit_request {
		const dto: DTO_template_store_edit_request = {
			id: data.params.id,
			name: data.params.name,
			agencyId: data.params.agency ? data.params.agency.id : null,
			magasinTypeId: data.storeTypeId,
		};

		return dto;
	}
}
