import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { DTO_store_edit_request } from '../../types/DTO_store.types';
import { Injectable } from '@angular/core';
import { getEnumStoreTypeValueByKey } from '../../../core/types/enums-helpers';
import { editStoreParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class StoreEditRequestAdapter implements BaseAdapter<editStoreParams, DTO_store_edit_request> {
	adapt(data: editStoreParams): DTO_store_edit_request {
		const dto: DTO_store_edit_request = {
			id: data.id,
			name: data.name,
			magasinType: getEnumStoreTypeValueByKey(data.magasinType)!,
			agencyId: data.agencyId,
			agencyName: data.agencyName,
			intervenantId: data.intervenantId,
			intervenantName: data.intervenantName,
			phoneNumber1: data.phone1,
			phoneNumber2: data.phone2,
			address: data.address,
			contactLastName: data.contactLastname,
			contactFirstName: data.contactFirstname,
			contactEmail: data.contactEmail,
			plaqueImmatriculation: data.immat,
		};

		return dto;
	}
}
