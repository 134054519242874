import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_intervention } from '../types/DTO_intervention';
import { Intervention } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class InterventionAdapter implements BaseAdapter<DTO_intervention, Intervention> {
	adapt(data: DTO_intervention): Intervention {
		const intervention = {
			id: data.id,
			realizedDate: data.realizedDate,
			realizedHour: data.realizedHour,
			plannedDate: data.plannedDate,
			comment: data.comment,
			description: data.description,
			echange: data.echange,
			patientId: data.patientId,
			typeId: data.typeId,
			typeLabel: data.typeLabel,
			category: data.category,
			dossierId: data.dossierId,
			dossierDetailId: data.dossierDetailId,
			parkId: data.parkId,
			parkCode: data.parkCode,
			intervenantId: data.intervenantId,
			intervenantFullName: data.intervenantFullName,
			intervenantAgencyId: data.intervenantAgencyId,
			details: data.details,
			deviceDetails: data.deviceDetails,
			attachmentName: data.attachmentName,
			attachmentUrl: data.attachmentUrl,
			visitePatient: data.visitePatient,
		};

		return new Intervention(intervention);
	}
}
