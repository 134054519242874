<div
	(click)="addFocusToInput()"
	(outSideClick)="removeFocusInput()"
	class="text-field"
	intranetClickOutside>
	<label #label>{{config().label}}</label>
	<textarea
		#input
		(input)="getChange($event)"
		[value]="control.value"></textarea>
</div>
