<div
	(click)="addFocusToInput()"
	(outSideClick)="removeFocusInput()"
	[class.error]="isError"
	[size]="size"
	class="text-field"
	intranetClickOutside
	intranetUtilities>

	@if(config().hasIcon) {
		<ng-content></ng-content>
	}

	<label
		#label
		[class.error]="isError"
		[class.hasIcon]="config().hasIcon"
		[class.hide]="isNotEmpty">
		{{ config().label }}
		<span *ngIf="config().isRequired" class="field-is-require">*</span>
	</label>

	<input
		#input
		(input)="getChange($event)"
		[class.error]="isError"
		[disabled]="disabled"
		[type]="config().type"
		[value]="control.value"
		autocomplete="off" />

	@if (isNotEmpty) {
		<fa-icon
			size="sm"
			(click)="clearField()"
			[icon]="faCircleXmark">
		</fa-icon>
	}

</div>

@if (isError && config().errorMessages) {
	<small style="margin-top: 8px; display: block" class="text-error">{{ config().errorMessages }}</small>
}