import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_template_store_search_params } from '../../types/DTO_template-store.types';
import { pagination } from '@application';
import { searchTemplateStoresParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class TemplateStoreSearchRequestAdapter
	implements
		BaseAdapter<
			{
				params: searchTemplateStoresParams;
				pagination: pagination;
			},
			DTO_template_store_search_params
		>
{
	adapt(data: { params: searchTemplateStoresParams; pagination: pagination }): DTO_template_store_search_params {
		const dto: DTO_template_store_search_params = {
			PageNumber: data.pagination.page,
			PageSize: data.pagination.limit,
		};

		if (data.params.name) dto.Name = data.params.name;
		if (data.params.agencyId) dto.AgencyId = data.params.agencyId;
		if (data.params.agencyLevel) dto.AgencyLevel = data.params.agencyLevel;

		return dto;
	}
}
