import { dosage, followUp, treatmentConsumable, treatmentDevice, treatmentType } from '../../../types/patient-treatment';
import { BaseModel } from '../../../core/base.model';

export class TreatmentType implements BaseModel<treatmentType> {
	constructor(data: treatmentType) {
		this._dosages = data.dosages;
		this._label = data.label;
		this._consumables = data.consumables;
		this._devices = data.devices;
		this._followUps = data.followUps;
	}

	private _label: string;

	get label(): string {
		return this._label;
	}

	set label(value: string) {
		this._label = value;
	}

	private _devices: treatmentDevice[];

	get devices(): treatmentDevice[] {
		return this._devices;
	}

	set devices(value: treatmentDevice[]) {
		this._devices = value;
	}

	private _consumables: treatmentConsumable[];

	get consumables(): treatmentConsumable[] {
		return this._consumables;
	}

	set consumables(value: treatmentConsumable[]) {
		this._consumables = value;
	}

	private _dosages: dosage[];

	get dosages(): dosage[] {
		return this._dosages;
	}

	set dosages(value: dosage[]) {
		this._dosages = value;
	}

	private _followUps: followUp[];

	get followUps(): followUp[] {
		return this._followUps;
	}

	set followUps(value: followUp[]) {
		this._followUps = value;
	}

	asObject(): treatmentType {
		return {
			label: this.label,
			devices: this.devices,
			consumables: this.consumables,
			dosages: this.dosages,
			followUps: this.followUps,
		};
	}
}
