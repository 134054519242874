import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { map, Observable } from 'rxjs';
import { DTO_prescription, PrescriptionAdapter } from '@infrastructure';
import { HttpParams } from '@angular/common/http';
import { Prescription } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PrescriptionRepository extends BaseHttpRepository {
	constructor(private readonly prescriptionAdapter: PrescriptionAdapter) {
		super();
	}

	public getPrescriptionsByPatientId(patientId: number): Observable<Prescription[]> {
		const params = new HttpParams().set('patientId', patientId);

		return this.http
			.get<DTO_prescription[]>(`${this.apiUrl}prescriptions`, {
				params,
				headers: { 'X-Loader-Key': 'patient-prescriptions' },
			})
			.pipe(map((prescriptions: DTO_prescription[]) => prescriptions.map((prescription: DTO_prescription) => this.prescriptionAdapter.adapt(prescription))));
	}
}
