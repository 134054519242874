import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import ErrorManager from './error.manager';
import { select } from '@ngneat/elf';

export type toastData = {
	toastType: 'success' | 'info' | 'warning' | 'error';
	toastMessage: string;
	toastTitle: string;
	timeOut: number;
};

@Injectable({
	providedIn: 'root',
})
export class ToasterManager {
	constructor(
		private readonly toasterService: ToastrService,
		private readonly errorManager: ErrorManager,
	) {}

	init(): void {
		this.errorManager.store.pipe(select(state => state.errors)).subscribe(errors => {
			errors.forEach(error => {
				if (!error.hasBeenDeclared && error.code !== 401 && error.code !== 404) {
					this.toasterService.error('Veuillez nous excuser pour la gène occasionnée. Si le problème persiste. Merci de contacter le support', 'Une erreur est survenue', {
						timeOut: 3000,
						closeButton: true,
						progressBar: true,
					});
					this.errorManager.setErrorDisplayed(error);
				}
			});
		});
	}

	triggerToast(data: toastData) {
		this.toasterService[data.toastType](data.toastMessage, data.toastTitle, {
			timeOut: data.timeOut,
			closeButton: true,
			progressBar: true,
		});
	}
}
