import { Injectable } from '@angular/core';
import { LoginResponse } from 'angular-auth-oidc-client';
import { BaseAdapter } from '@infrastructure';
import { AuthenticatedUserData } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class AuthenticatedUserAdapter implements BaseAdapter<LoginResponse, AuthenticatedUserData> {
	adapt(data: LoginResponse): AuthenticatedUserData {
		return new AuthenticatedUserData(data);
	}
}
