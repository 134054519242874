import { Injectable } from '@angular/core';
import { GridApi } from 'ag-grid-community';

@Injectable({
	providedIn: 'root',
})
export class DataTableService {
	private gridApi!: GridApi;

	public get gridContext() {
		return this.gridApi;
	}

	public set gridContext(value: GridApi) {
		this.gridApi = value;
	}

	public set loadingState(value: boolean) {
		if (this.gridApi) {
			if (value) {
				this.gridApi.showLoadingOverlay();
			} else if (this.gridApi && !this.gridApi.isDestroyed()) {
				this.gridApi.hideOverlay();
			}
		}
	}

	public resizeGrid() {
		this.gridApi.sizeColumnsToFit();
	}
}
