import { amc, amo, assure, assureAddress, assureAmc, assureAmo, assureData, BaseModel } from '@domain';

export class Assure implements BaseModel<assureData> {

	get amc(): amc | null {
		return this._amc;
	}

	set amc(value: amc | null) {
		this._amc = value;
	}

	get assureAmc(): assureAmc | null {
		return this._assureAmc;
	}

	set assureAmc(value: assureAmc | null) {
		this._assureAmc = value;
	}

	get amo(): amo | null {
		return this._amo;
	}

	set amo(value: amo | null) {
		this._amo = value;
	}

	get assureAmo(): assureAmo | null {
		return this._assureAmo;
	}

	set assureAmo(value: assureAmo | null) {
		this._assureAmo = value;
	}

	get assure(): assure {
		return this._assure;
	}

	set assure(value: assure) {
		this._assure = value;
	}

	get relationshipLink(): string {
		return this._relationshipLink;
	}

	set relationshipLink(value: string) {
		this._relationshipLink = value;
	}

	get address(): assureAddress | null {
		return this._address;
	}

	set address(value: assureAddress | null) {
		this._address = value;
	}

	private _relationshipLink: string;
	private _assure: assure;
	private _assureAmo: assureAmo | null;
	private _amo: amo | null;
	private _assureAmc: assureAmc | null;
	private _amc: amc | null;
	private _address: assureAddress | null;

	constructor(data: assureData) {
		this._relationshipLink = data.relationshipLink;
		this._assure = data.assure;
		this._assureAmo = data.assureAmo;
		this._amo = data.amo;
		this._assureAmc = data.assureAmc;
		this._amc = data.amc;

		this._address = {
			addressId: data.assure.addressId ?? null,
			addressStreet: data.assure.addressStreet ?? null,
			addressBuilding: data.assure.addressBuilding ?? null,
			addressComplement: data.assure.addressComplement ?? null,
			addressCityCode: data.assure.addressCityCode ?? null,
			addressCityName: data.assure.addressCityName ?? null,
			addressIndication: data.assure.addressIndication ?? null,
			addressPhone: data.assure.addressPhone ?? null,
			addressMobile: data.assure.addressMobile ?? null,
			addressFax: data.assure.addressFax ?? null,
			addressEmail: data.assure.addressEmail ?? null,
		};
	}

	asObject(): assureData {
		return {
			relationshipLink: this._relationshipLink,
			assure: this._assure,
			assureAmo: this._assureAmo,
			amo: this._amo,
			assureAmc: this._assureAmc,
			amc: this._amc,
		};
	}
}
