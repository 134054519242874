import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { DTO_store_create_request, DTO_store_search_request } from '../../types/DTO_store.types';
import { Injectable } from '@angular/core';
import { createStoreParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class StoreCreationRequestAdapter implements BaseAdapter<createStoreParams, DTO_store_search_request> {
	adapt(data: createStoreParams): DTO_store_create_request {
		return {
			name: data.name,
			magasinType: data.storeType,
			agencyId: data.agencyId,
			intervenantId: data.intervenantId ? data.intervenantId : undefined,
			phoneNumber1: data.phone1,
			phoneNumber2: data.phone2,
			address: data.address,
			contactLastName: data.contactLastname,
			contactFirstName: data.contactFirstname,
			contactEmail: data.contactEmail,
			plaqueImmatriculation: data.immat,
		};
	}
}
