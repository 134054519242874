export const environment = {
	api: process.env["NG_APP_API_URL"],
	business: process.env["NG_APP_BUSINESS_API_URL"],
	servicePatient: process.env["NG_APP_SERVICE_PATIENT_API_URL"],
	security: process.env["NG_APP_AZURE_AD_URL"],
	vnextSecurity: process.env["NG_APP_SECURITY_API_URL"],
	legacy: process.env["NG_APP_LEGACY_API_URL"],
	referential: process.env["NG_APP_REFERENTIAL_API_URL"],
	ged: process.env["NG_APP_GED_URL"],
	dataShaker: process.env["NG_APP_DATASHAKER_API_URL"],
	medical: process.env["NG_APP_MEDICAL_API_URL"],
	appointment: process.env["NG_APP_APPOINTMENT_API_URL"],
	userType: process.env["NG_APP_USER_TYPE"],
	orthop: process.env["NG_APP_ORTHOPV2_API_URL"]
};

// API_URL: '#{apiUrl}#',
// BUSINESS_API_URL: '#{businessApiUrl}#',
// SERVICE_PATIENT_URL: '#{servicePatientUrl}#',
// AZURE_AD_URL: '#{azureAdUrl}#',
// SECURITY_API_URL: '#{securityApiUrl}#',
// LEGACY_API_URL: '#{legacyApiUrl}#',
// REFERENTIAL_API_URL: '#{referentialApiUrl}#',
// GED_URL: '#{gedUrl}#',
// DATASHAKER_API_URL: '#{dataShakerApiUrl}#',
// MEDICAL_API_URL: '#{medicalApiUrl}#',
// APPOINTMENT_API_URL: '#{appointmentApiUrl}#',
// USER_TYPE: '#{userType}#',
// ORTHOPV2_API_URL: '#{orthopApiV2Url}#',