<ng-container
	*ngTemplateOutlet="hasTemplate(col()) ? withTemplate: noTemplate; context: {item: item(), index: index(), col: col(), odd: odd()}">
</ng-container>

<ng-template #noTemplate let-col="col" let-index="index" let-item="item" let-odd="odd">
	<div
		(click)="selectItem(index)"
		(mouseenter)="hoverLine($event)"
		(mouseleave)="removeHoveredLine($event)"
		[class.isNotCollapsible]="isCollapsible()"
		[class.odd]="odd"
		class="col-item col-{{index}}-{{componentId()}}">
						<span [class.bold]="col?.isBold">
							@if (col?.isState) {
								<span [class.danger]="item.toLowerCase() === 'inactif'"
											[class.success]="item.toLowerCase() === 'actif'"
											class="badge">
									{{ item }}
								</span>
							} @else {
								{{ item }}
							}
						</span>
	</div>
</ng-template>

<ng-template #withTemplate let-index="index" let-odd="odd">
	<div
		(click)="selectItem(index)"
		(mouseenter)="hoverLine($event)"
		(mouseleave)="removeHoveredLine($event)"
		[class.isNotCollapsible]="isCollapsible()"
		[class.odd]="odd"
		class="col-item col-{{index}}-{{componentId()}}">
		<ng-container #container></ng-container>
	</div>
</ng-template>