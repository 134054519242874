import { Component, EventEmitter, input, InputSignal, Output, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { options } from '../forms';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { className } from '../core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ClickOutsideDirective } from '../../directives';

export type dropdownOption = Pick<options, 'label' | 'value'> & { icon?: IconDefinition };

@Component({
	selector: 'intranet-dropdown',
	standalone: true,
	imports: [CommonModule, FaIconComponent, ClickOutsideDirective],
	templateUrl: './dropdown.component.html',
	styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
	className: InputSignal<className> = input.required<className>();
	align: InputSignal<string> = input.required<string>();
	options: InputSignal<dropdownOption[]> = input.required<options[]>();
	listIsVisible: WritableSignal<boolean> = signal(false);

	@Output() rowIsClicked: EventEmitter<dropdownOption> = new EventEmitter<dropdownOption>();

	get listClassName(): string {
		const btnClas = this.className().split(' ');
		const btnType = btnClas[1].split('-');
		return btnType[btnType.length - 1];
	}

	selectItem(item: dropdownOption) {
		this.rowIsClicked.emit(item);
		this.displayList();
	}

	displayList() {
		const visibility = this.listIsVisible();
		this.listIsVisible.set(!visibility);
	}
}
