import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormComponent } from '../../core';
import { textareaType } from './textarea.types';
import { ClickOutsideDirective } from '../../../directives';
import { tap } from 'rxjs';

@Component({
	selector: 'intranet-textarea',
	standalone: true,
	imports: [CommonModule, ClickOutsideDirective],
	templateUrl: './textarea.component.html',
	styleUrl: './textarea.component.scss',
})
export class TextareaComponent extends BaseFormComponent<textareaType> implements OnInit {
	@ViewChild('label') label!: ElementRef;
	@ViewChild('input') input!: ElementRef;

	addFocusToInput() {
		this.label.nativeElement.classList.add('floating');
		this.input.nativeElement.focus();
	}

	removeFocusInput() {
		if (!this.isNotEmpty) {
			this.label.nativeElement.classList.remove('floating');
		}
	}

	ngOnInit() {
		this.control.valueChanges.pipe(tap(() => this.resizeTextarea())).subscribe();
	}

	protected getChange(event: any) {
		this.onChange(event.target.value);
		this.onTouched();
	}

	private resizeTextarea() {
		this.input.nativeElement.style.height = 'auto';
		this.input.nativeElement.style.height = this.input.nativeElement.scrollHeight + 'px';
	}
}
