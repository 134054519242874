import { AfterViewInit, Component, EventEmitter, inject, Input, input, InputSignal, OnInit, Output, signal, Type, ViewChild, ViewContainerRef, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { listColDef } from '../listColDef.types';
import { BaseListComponent, dynamicType } from '@components';
import { faChevronDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ListManager, listState } from '../core/list.manager';
import { select } from '@ngneat/elf';
import { tap } from 'rxjs';

@Component({
	selector: 'intranet-list-item',
	standalone: true,
	imports: [CommonModule, FaIconComponent],
	templateUrl: './list-item.component.html',
	styleUrl: './list-item.component.scss',
})
export class ListItemComponent implements AfterViewInit, OnInit {
	@ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;

	dataSource: InputSignal<dynamicType[]> = input.required();
	index: InputSignal<number> = input.required();
	odd: InputSignal<boolean> = input.required();
	componentId: InputSignal<string> = input.required();
	item: InputSignal<string> = input.required();
	col: InputSignal<listColDef> = input.required();

	first: InputSignal<boolean> = input(false);
	isCollapsible: InputSignal<boolean> = input(false);
	isStripped: InputSignal<boolean> = input(false);

	rowStateCollapsed: WritableSignal<boolean> = signal(false);
	icon: IconDefinition = faChevronDown;

	@Output()
	rowIsClicked: EventEmitter<number> = new EventEmitter<number>();

	@Input()
	isClickable = false;

	private readonly listManager: ListManager = inject(ListManager);

	hasTemplate(col: listColDef): boolean {
		return col.template !== undefined;
	}

	selectItem(index: number) {
		this.rowIsClicked.emit(index);
	}

	hoverLine(event: any) {
		const selector = `div.${event.currentTarget.classList[0]}`.toString();
		const items = document.querySelectorAll(selector);
		items.forEach((item: Element) => {
			item.classList.add('hovered');
			if (this.isClickable) {
				item.classList.add('clickable');
			}
		});
	}

	removeHoveredLine(event: any) {
		const selector = `div.${event.currentTarget.classList[0]}`.toString();
		const items = document.querySelectorAll(selector);
		items.forEach((item: Element) => {
			item.classList.remove('hovered');
		});
	}

	ngOnInit() {
		this.listManager.store
			.pipe(
				select((state: listState) => state.data),
				tap((data: dynamicType[]) => this.rowStateCollapsed.set(data[this.index()]['isCollapsible'])),
			)
			.subscribe();
	}

	ngAfterViewInit() {
		if (this.col().template !== undefined) {
			const ref = this.container.createComponent<BaseListComponent>(this.col().template as Type<BaseListComponent>);
			ref.instance.rowId = this.index();
		}
	}
}
