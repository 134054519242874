import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { DTO_treatment } from '../types/DTO_treatment.types';
import { map, Observable } from 'rxjs';
import { TreatmentAdapter } from '../adapter/treatment.adapter';
import { Treatment } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientTreatmentRepository extends BaseHttpRepository {
	getPatientTreatment(patientId: number): Observable<Treatment[]> {
		return this.http
			.get<DTO_treatment[]>(`${this.apiUrl}patient/${patientId}/treatments`)
			.pipe(map((treatments: DTO_treatment[]) => treatments.map((treatment: DTO_treatment) => new TreatmentAdapter().adapt(treatment))));
	}
}
