import { Injectable } from '@angular/core';
import { DocumentInterface } from './document.interface';

@Injectable({
	providedIn: 'root',
})
export class DocumentFile implements DocumentInterface {
	protected _blob: Blob;

	constructor(blob: Blob) {
		this._blob = blob;
	}

	open() {
		window.open(URL.createObjectURL(this._blob));
	}

	download(documentName: string) {
		const fileUrl = window.URL.createObjectURL(this._blob);
		const fileLink = document.createElement('a');
		fileLink.href = fileUrl;
		fileLink.download = documentName;
		fileLink.click();
	}
}
