import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { DTO_dosage } from '../types/DTO_treatment.types';
import { map } from 'rxjs';
import { PatientDosageAdapter } from '../adapter/patient-dosage.adapter';

@Injectable({
	providedIn: 'root',
})
export class PatientDosageRepository extends BaseHttpRepository {
	constructor() {
		super();
	}

	getPatientDosages(patientId: number) {
		return this.http
			.get<DTO_dosage[]>(`${this.apiUrl}patient/${patientId}/posologies`, {
				headers: { 'X-Loader-Key': 'patient-dosages' },
			})
			.pipe(map((dosages: DTO_dosage[]) => dosages.map((dosage: DTO_dosage) => new PatientDosageAdapter().adapt(dosage))));
	}
}
