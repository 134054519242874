import { Injectable } from '@angular/core';
import { DTO_agencyEmployee } from '../types/DTO_agencyEmployee.types';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { Intervenant } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class AgencyEmployeeAdapter implements BaseAdapter<DTO_agencyEmployee, Intervenant> {
	adapt(data: DTO_agencyEmployee): Intervenant {
		return new Intervenant({
			id: data.id,
			fullName: data.fullName,
			agencyId: data.agencyId,
			agencyName: data.agencyName,
			profile: data.profile,
		});
	}
}
