import { Component, EventEmitter, input, InputSignal, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormComponent } from '../../core';
import { toggleOption } from './toogle.type';

@Component({
	selector: 'intranet-toggle',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './toggle.component.html',
	styleUrl: './toggle.component.scss',
})
export class ToggleComponent extends BaseFormComponent<toggleOption[]> {
	label: InputSignal<string> = input.required<string>();
	override config: InputSignal<toggleOption[]> = input.required<toggleOption[]>();

	@Output() changed: EventEmitter<any> = new EventEmitter<any>();

	selectState(item: toggleOption) {
		this.config().forEach(conf => (conf.state = false));
		item.state = !item.state;
		this.onChange(item.value);
		this.onTouched();
		this.changed.emit(item.value);
	}
}
