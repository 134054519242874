import { BaseModel } from '../../core/base.model';
import { typeIntervention } from '../../types/type-intervention';

export class TypeIntervention implements BaseModel<typeIntervention> {
	constructor(data: typeIntervention) {
		this._id = data.id;
		this._label = data.label;
		this._isMep = data.isMep;
		this._enterDatePlanned = data.enterDatePlanned;
		this._interventionPatient = data.interventionPatient;
		this._categoryId = data.categoryId;
		this._rayonId = data.rayonId;
		this._idNew = data.idNew;
		this._delayNew = data.delayEchangeNew;
		this._delayEchangeNew = data.delayEchangeNew;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _label: string;

	get label(): string {
		return this._label;
	}

	set label(value: string) {
		this._label = value;
	}

	private _isMep: boolean;

	get isMep(): boolean {
		return this._isMep;
	}

	set isMep(value: boolean) {
		this._isMep = value;
	}

	private _enterDatePlanned: string;

	get enterDatePlanned(): string {
		return this._enterDatePlanned;
	}

	set enterDatePlanned(value: string) {
		this._enterDatePlanned = value;
	}

	private _interventionPatient: boolean;

	get interventionPatient(): boolean {
		return this._interventionPatient;
	}

	set interventionPatient(value: boolean) {
		this._interventionPatient = value;
	}

	private _categoryId: string;

	get categoryId(): string {
		return this._categoryId;
	}

	set categoryId(value: string) {
		this._categoryId = value;
	}

	private _rayonId: number;

	get rayonId(): number {
		return this._rayonId;
	}

	set rayonId(value: number) {
		this._rayonId = value;
	}

	private _idNew: number;

	get idNew(): number {
		return this._idNew;
	}

	set idNew(value: number) {
		this._idNew = value;
	}

	private _delayNew: number;

	get delayNew(): number {
		return this._delayNew;
	}

	set delayNew(value: number) {
		this._delayNew = value;
	}

	private _delayEchangeNew: number;

	get delayEchangeNew(): number {
		return this._delayEchangeNew;
	}

	set delayEchangeNew(value: number) {
		this._delayEchangeNew = value;
	}

	asObject(): typeIntervention {
		return {} as typeIntervention;
	}
}
