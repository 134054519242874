import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'intranet-header-modal',
	standalone: true,
	imports: [CommonModule, FaIconComponent],
	templateUrl: './header-modal.component.html',
	styleUrl: './header-modal.component.scss',
})
export class HeaderModalComponent {
	readonly faTimes = faTimes;

	@Output() isClosed: EventEmitter<void> = new EventEmitter();

	closeModal() {
		this.isClosed.emit();
	}
}
