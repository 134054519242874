import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-base-modal',
	standalone: true,
	imports: [CommonModule],
	template: '',
})
export abstract class BaseModalComponent {}
