import { Component, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-data-box-phone-number',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './data-box-phone-number.component.html',
	styleUrl: './data-box-phone-number.component.scss',
})
export class DataBoxPhoneNumberComponent {
	contact: InputSignal<{ name: string, value: string }> = input.required();
}
