import { Agency, AuthenticatedUserData, ModulePermission, UserModule } from '@domain';
import { Injectable } from '@angular/core';
import { HasStore } from '../core/store/has-store';
import { AuthenticationRepository, OidcService } from '@infrastructure';
import { take } from 'rxjs';

export type oidcServiceState = {
	authenticatedUserData: AuthenticatedUserData | null;
	agencies: Agency[] | null;
	currentUserAgency: Agency | null;
	currentEmployeeId: number | null;
	employeeIds: Array<{ agencyId: number; employeeId: number }> | null;
	userModules: UserModule[] | null;
	access: boolean | null;
	permissions: Map<string, ModulePermission[]> | null;
};

@Injectable({
	providedIn: 'root',
})
export class AuthenticationManager extends HasStore<oidcServiceState> {
	constructor(
		private readonly oidc: OidcService,
		private userRepository: AuthenticationRepository) {
		super(
			{
				authenticatedUserData: null,
				agencies: null,
				currentUserAgency: null,
				currentEmployeeId: null,
				employeeIds: null,
				userModules: null,
				access: null,
				permissions: null,
			},
			'oidc',
		);
	}

	checkAuthentication() {
		this.oidc
			.checkAuth()
			.pipe(take(1))
			.subscribe(response => {
				if (response != null) {
					this.store.update(state => {
						return {
							...state,
							authenticatedUserData: response,
						};
					});
				} else {
					this.oidc.authenticate();
				}
			});
	}

	retrieveUserAgencies() {
		this.userRepository
			.getUserAgencies()
			.pipe(take(1))
			.subscribe(agencies => {
				this.store.update(state => {
					return {
						...state,
						agencies: agencies,
					};
				});
			});
	}

	retrieveUserModules(agencyId?: number) {
		this.userRepository
			.getUserModules(agencyId)
			.pipe(take(1))
			.subscribe(modules => {
				this.store.update(state => {
					return {
						...state,
						userModules: modules,
					};
				});
			});
	}

	retrieveEmployeeIds() {
		this.userRepository
			.getEmployeeIds()
			.pipe(take(1))
			.subscribe(data => {
				// UPDATE THE STORE
				this.store.update((state: oidcServiceState) => {
					return {
						...state,
						employeeIds: data.employeeIds,
					};
				});
			});
	}

	changeCurrentUserAgency(agency: Agency) {
		this.store.update(state => {
			return {
				...state,
				currentUserAgency: agency,
			};
		});
	}

	retrieveUserModulePermissions(agencyId: number, moduleSlug: string) {
		this.userRepository
			.getUserAgencyModulePermissions(agencyId, moduleSlug)
			.pipe(take(1))
			.subscribe(permissions => {
				let userPermissions = this.store.state.permissions;
				if (userPermissions == null) {
					userPermissions = new Map<string, ModulePermission[]>();
				}
				userPermissions.set(moduleSlug, permissions);

				this.store.update(state => {
					return {
						...state,
						permissions: userPermissions,
					};
				});
			});
	}
}
