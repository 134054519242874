import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_usersDetails } from '../types/DTO_user-management.types';
import { User } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class UserDetailsAdapter implements BaseAdapter<DTO_usersDetails, User> {
	adapt(data: DTO_usersDetails): User {
		return new User({
			id: data.id,
			lastname: data.lastName,
			firstname: data.firstName,
			email: data.email,
		});
	}
}
