<div class="data-box-item">

	<p class="data-box-item__label">
		{{ data().label }}
	</p>

	<p class="data-box-item__value">
		<ng-container *ngTemplateOutlet="isEditable ? editableTemplate : defaultTemplate"></ng-container>
	</p>

</div>


<ng-template #defaultTemplate>
	<p class="data-box-item__value">
		{{ data().value === true ? 'Oui' : data().value === false ? 'Non' : data().value }}
	</p>
</ng-template>

<ng-template #editableTemplate>
	<ng-content></ng-content>
</ng-template>