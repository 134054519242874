export enum medicalDeviceStatus {
	Actif = 'Actif',
	Sale = 'Sale',
	Desinfection = 'Désinfection',
	Defectueux = 'Défectueux',
	RetourPatient = 'Retour patient',
	Quarantaine = 'Quarantaine',
	Reparation = 'Réparation',
	RetireDuParc = 'Retiré du parc',
}
