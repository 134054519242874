import { BaseModel } from '../../core/base.model';

export type consommable = {
	expirationDate: string;
	quantity: number;
	id: number;
	label: string;
	noticeUrl: string;
	manufacturer: string;
	familyId: number;
	familyLabel: string;
	dossierId: number;
	dossierDetailId: number;
	rentalDate: string;
	installationDate: string;
	lastRevisionDate: string;
	imageUrl: string;
};

export class PatientConsommable implements BaseModel<consommable> {
	constructor(data: consommable) {
		this._id = data.id;
		this._label = data.label;
		this._quantity = data.quantity;
		this._expirationDate = data.expirationDate;
		this._rentalDate = data.rentalDate;
		this._noticeUrl = data.noticeUrl;
		this._manufacturer = data.manufacturer;
		this._familyId = data.familyId;
		this._familyLabel = data.familyLabel;
		this._dossierId = data.dossierId;
		this._dossierDetailId = data.dossierDetailId;
		this._installationDate = data.installationDate;
		this._lastRevisionDate = data.lastRevisionDate;
		this._imageUrl = '';
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _label: string;

	get label(): string {
		return this._label;
	}

	set label(value: string) {
		this._label = value;
	}

	private _quantity: number;

	get quantity(): number {
		return this._quantity;
	}

	set quantity(value: number) {
		this._quantity = value;
	}

	private _expirationDate: string;

	get expirationDate(): string {
		return this._expirationDate;
	}

	set expirationDate(value: string) {
		this._expirationDate = value;
	}

	private _rentalDate: string;

	get rentalDate(): string {
		return this._rentalDate;
	}

	set rentalDate(value: string) {
		this._rentalDate = value;
	}

	private _noticeUrl: string;

	get noticeUrl(): string {
		return this._noticeUrl;
	}

	set noticeUrl(value: string) {
		this._noticeUrl = value;
	}

	private _manufacturer: string;

	get manufacturer(): string {
		return this._manufacturer;
	}

	set manufacturer(value: string) {
		this._manufacturer = value;
	}

	private _familyId: number;

	get familyId(): number {
		return this._familyId;
	}

	set familyId(value: number) {
		this._familyId = value;
	}

	private _familyLabel: string;

	get familyLabel(): string {
		return this._familyLabel;
	}

	set familyLabel(value: string) {
		this._familyLabel = value;
	}

	private _dossierId: number;

	get dossierId(): number {
		return this._dossierId;
	}

	set dossierId(value: number) {
		this._dossierId = value;
	}

	private _dossierDetailId: number;

	get dossierDetailId(): number {
		return this._dossierDetailId;
	}

	set dossierDetailId(value: number) {
		this._dossierDetailId = value;
	}

	private _installationDate: string;

	get installationDate(): string {
		return this._installationDate;
	}

	set installationDate(value: string) {
		this._installationDate = value;
	}

	private _lastRevisionDate: string;

	get lastRevisionDate(): string {
		return this._lastRevisionDate;
	}

	set lastRevisionDate(value: string) {
		this._lastRevisionDate = value;
	}

	private _imageUrl: string;

	get imageUrl(): string {
		return this._imageUrl;
	}

	set imageUrl(value: string) {
		this._imageUrl = value;
	}

	asObject(): consommable {
		return {
			id: this._id,
			label: this._label,
			quantity: this._quantity,
			expirationDate: this._expirationDate,
			rentalDate: this._rentalDate,
			noticeUrl: this._noticeUrl,
			manufacturer: this._manufacturer,
			familyId: this._familyId,
			familyLabel: this._familyLabel,
			dossierId: this._dossierId,
			dossierDetailId: this._dossierDetailId,
			installationDate: this._installationDate,
			lastRevisionDate: this._lastRevisionDate,
			imageUrl: this._imageUrl,
		};
	}
}
