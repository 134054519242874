import { BaseModel } from '../../core/base.model';
import { establishment } from '../../types/establishment';

export class Establishment implements BaseModel<establishment> {
	constructor(data: establishment) {
		this._immatriculation = data.immatriculation;
		this._name = data.name;
		this._addressBuilding = data.addressBuilding;
		this._addressStreet = data.addressStreet;
		this._addressComplement = data.addressComplement;
		this._addressCityCode = data.addressCityCode;
		this._addressCityName = data.addressCityName;
		this._agencyId = data.agencyId;
		this._agencyName = data.agencyName;
		this._isActive = data.isActive;
	}

	private _immatriculation: string;

	get immatriculation(): string {
		return this._immatriculation;
	}

	set immatriculation(value: string) {
		this._immatriculation = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	private _addressBuilding: string;

	get addressBuilding(): string {
		return this._addressBuilding;
	}

	set addressBuilding(value: string) {
		this._addressBuilding = value;
	}

	private _addressStreet: string;

	get addressStreet(): string {
		return this._addressStreet;
	}

	set addressStreet(value: string) {
		this._addressStreet = value;
	}

	private _addressComplement: string;

	get addressComplement(): string {
		return this._addressComplement;
	}

	set addressComplement(value: string) {
		this._addressComplement = value;
	}

	private _addressCityCode: string;

	get addressCityCode(): string {
		return this._addressCityCode;
	}

	set addressCityCode(value: string) {
		this._addressCityCode = value;
	}

	private _addressCityName: string;

	get addressCityName(): string {
		return this._addressCityName;
	}

	set addressCityName(value: string) {
		this._addressCityName = value;
	}

	private _agencyId: number;

	get agencyId(): number {
		return this._agencyId;
	}

	set agencyId(value: number) {
		this._agencyId = value;
	}

	private _agencyName: string;

	get agencyName(): string {
		return this._agencyName;
	}

	set agencyName(value: string) {
		this._agencyName = value;
	}

	private _isActive: boolean;

	get isActive(): boolean {
		return this._isActive;
	}

	set isActive(value: boolean) {
		this._isActive = value;
	}

	asObject(): establishment {
		return {
			immatriculation: this.immatriculation,
			name: this.name,
			addressBuilding: this.addressBuilding,
			addressStreet: this.addressStreet,
			addressComplement: this.addressComplement,
			addressCityCode: this.addressCityCode,
			addressCityName: this.addressCityName,
			agencyId: this.agencyId,
			agencyName: this.agencyName,
			isActive: this.isActive,
		};
	}
}
