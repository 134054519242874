import { Injectable } from '@angular/core';
import { DocumentFile } from './document-file';

@Injectable({
	providedIn: 'root',
})
export class DocumentImage extends DocumentFile {
	constructor(blob: Blob) {
		super(blob);
	}

	override open() {
		const image = this.transformBlobToImage();
		const w = window.open('', '_blank') as Window;
		w.document.body.appendChild(image);
	}

	private transformBlobToImage(): HTMLImageElement {
		const image: HTMLImageElement = new Image();
		const fileReader: FileReader = new FileReader();
		fileReader.readAsDataURL(this._blob);
		fileReader.onloadend = () => {
			image.src = fileReader.result as string;
		};
		return image;
	}
}
