<div class="pagination">
	<div class="pagination__item">
		<p>Nombre de résultats : {{paginationData.total}}</p>
	</div>
	<div class="pagination__item">
		<p>Résultats par page :</p>
		<select
			(ngModelChange)="changeSize($event)"
			[(ngModel)]="currentPageSize">
			@for (item of pageSizeOptions; track item) {
				<option [value]="item" >{{ item }}</option>
			}
		</select>
	</div>

	<div class="pagination__item-navigation">
		<button
			(click)="previous()"
			[disabled]="!PaginationHelpers.canChangePage(PaginationEvent.PREVIOUS, paginationData)"
			class="prev"
		><fa-icon [icon]="faChevronLeft"></fa-icon></button>
		<div class="pagination__item-navigation__page">
      <span
				(click)="this.selectPage(1)"
				[ngClass]="paginationData.page === 1 ? 'label-actif' : ''"
			>1</span>
			@for(label of getPaginationLabels(); track label) {
				<span
					[ngClass]="paginationData.page === label ? 'label-actif' : ''"
					(click)="this.selectPage(label)"
				>{{label}}</span>
			}
			@if (paginationData.nbTotalPage > 1) {
				<span
					[ngClass]="paginationData.page === paginationData.nbTotalPage ? 'label-actif' : ''"
					(click)="this.selectPage(paginationData.nbTotalPage)"
				>{{ paginationData.nbTotalPage }}</span>
			}
		</div>
		<button
			(click)="next()"
			[disabled]="!PaginationHelpers.canChangePage(PaginationEvent.NEXT, paginationData)"
			class="next">
			<fa-icon [icon]="faChevronRight"></fa-icon>
		</button>
	</div>
</div>
