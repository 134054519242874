import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { PatientCase } from '@domain';
import { DTO_patient_case } from '../types/DTO_patient-document.types';

@Injectable({
	providedIn: 'root',
})
export class PatientCaseAdapter implements BaseAdapter<DTO_patient_case, PatientCase> {
	adapt(dto: DTO_patient_case): PatientCase {
		return new PatientCase({
			id: dto.id,
			rdvMedecinDate: dto.rdvMedecinDate,
			doctorFullName: dto.doctorFullName,
		});
	}
}
