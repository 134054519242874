import { BaseModel } from '../../core/base.model';

export type patientDosage = {
	id: number;
	startDate: string;
	endDate: string;
	duration: string;
	debit: string;
	title: string;
	prescribingDoctor: string;
};

export class PatientDosage implements BaseModel<patientDosage> {
	constructor(data: patientDosage) {
		this._id = data.id;
		this._startDate = data.startDate;
		this._endDate = data.endDate;
		this._duration = data.duration;
		this._debit = data.debit;
		this._title = data.title;
		this._prescribingDoctor = data.prescribingDoctor;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _startDate: string;

	get startDate(): string {
		return this._startDate;
	}

	set startDate(value: string) {
		this._startDate = value;
	}

	private _endDate: string;

	get endDate(): string {
		return this._endDate;
	}

	set endDate(value: string) {
		this._endDate = value;
	}

	private _duration: string;

	get duration(): string {
		return this._duration;
	}

	set duration(value: string) {
		this._duration = value;
	}

	private _debit: string;

	get debit(): string {
		return this._debit;
	}

	set debit(value: string) {
		this._debit = value;
	}

	private _title: string;

	get title(): string {
		return this._title;
	}

	set title(value: string) {
		this._title = value;
	}

	private _prescribingDoctor: string;

	get prescribingDoctor(): string {
		return this._prescribingDoctor;
	}

	set prescribingDoctor(value: string) {
		this._prescribingDoctor = value;
	}

	asObject(): patientDosage {
		return {
			id: this.id,
			startDate: this.startDate,
			endDate: this.endDate,
			duration: this.duration,
			debit: this.debit,
			title: this.title,
			prescribingDoctor: this.prescribingDoctor,
		};
	}
}
