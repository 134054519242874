import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'intranet-tabs-container',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './tabs-container.component.html',
	styleUrl: './tabs-container.component.scss',
})
export class TabsContainerComponent {}
