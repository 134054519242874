import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_available_roles } from '../types/DTO_user-management.types';
import { role } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class AvailableRoleAdapter implements BaseAdapter<DTO_available_roles, role> {
	adapt(dto_role: DTO_available_roles): role {
		return {
			id: dto_role.id,
			name: dto_role.label,
		};
	}
}
