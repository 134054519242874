import { Injectable } from '@angular/core';
import { DTO_supply_request_search_request } from '../../types/DTO_supply-requests.types';
import { searchSupplyRequestParams, supplyRequestStatus } from '@domain';
import { BaseAdapter, getEnumKeyByValue } from '@infrastructure';


@Injectable({
	providedIn: 'root',
})
export default class SupplyRequestSearchAdapter implements BaseAdapter<searchSupplyRequestParams, DTO_supply_request_search_request> {

	adapt(params: searchSupplyRequestParams): DTO_supply_request_search_request {
		const ret: DTO_supply_request_search_request = {
			intervenantId: params.technician,
			agencyId: params.agencyId,
			startSubmissionDate: params.startDate,
			endSubmissionDate: params.endDate,
			status:  getEnumKeyByValue(params.status, supplyRequestStatus),
			PageNumber: params.pageNumber,
			PageSize: params.pageSize,
			AssignedOrderState: params.assignedOrderState
		};
		return ret;
	}
}
