import { Agency } from '../../Agency/Agency';
import { Article } from '../store/article';
import { getEnumKeyByValue } from '@infrastructure';

export enum agencyLevel {
	Regional = 'OnlyRegionalLevel',
	National = 'OnlyNationalLevel',
}

export type templateStore = {
	id: number;
	name: string;
	agency: Agency;
	agencyLevel: agencyLevel;
	magasinTypeId: number;
	articles: Article[] | null;
};

export class TemplateStore {
	constructor(templateStore: templateStore) {
		this._id = templateStore.id;
		this._name = templateStore.name;
		this._agency = templateStore.agency;
		this._agencyLevel = templateStore.agencyLevel;
		this._magasinTypeId = templateStore.magasinTypeId;
		this._articles = templateStore.articles;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _name: string;

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	private _agency: Agency;

	get agency(): Agency {
		return this._agency;
	}

	set agency(value: Agency) {
		this._agency = value;
	}

	private _agencyLevel: agencyLevel;

	get agencyLevel(): agencyLevel {
		return this._agencyLevel;
	}

	set agencyLevel(value: agencyLevel) {
		this._agencyLevel = value;
	}

	private _magasinTypeId: number;

	get magasinTypeId(): number {
		return this._magasinTypeId;
	}

	set magasinTypeId(value: number) {
		this._magasinTypeId = value;
	}

	private _articles: Article[] | null;

	get articles(): Article[] | null {
		return this._articles;
	}

	set articles(value: Article[] | null) {
		this._articles = value;
	}

	get agencyLevelKey(): string | undefined {
		return getEnumKeyByValue(this._agencyLevel, agencyLevel);
	}

	get agencyName(): string {
		return this._agency.name;
	}

	get detailedName(): string {
		return this.agencyLevel == agencyLevel.National ? `${this.name} (national)` : `${this.name}`;
	}

	asObject(): templateStore {
		return {
			id: this.id,
			name: this.name,
			agency: this.agency,
			agencyLevel: this.agencyLevel,
			magasinTypeId: this.magasinTypeId,
			articles: this.articles,
		};
	}
}
