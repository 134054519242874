import { BaseAdapter } from '../../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_pdm_search_params } from '../../types/DTO_pdm.types';
import { pagination } from '@application';
import { pdmSearchParams } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class PDMSearchRequestAdapter
	implements
		BaseAdapter<
			{
				searchParams: pdmSearchParams;
				pagination: pagination;
			},
			DTO_pdm_search_params
		>
{
	adapt(data: { searchParams: pdmSearchParams; pagination: pagination }): DTO_pdm_search_params {
		const DTO_pdm_params: DTO_pdm_search_params = {
			PageNumber: data.pagination.page,
			PageSize: data.pagination.limit,
			codeArticle: data.searchParams.codeArticle,
		};

		return DTO_pdm_params;
	}
}
