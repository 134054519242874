import { BaseModel } from '../../core/base.model';

export type doctorPatient = {
	id: number;
	firstname: string;
	lastname: string;
	civility: string;
	birthDate: string;
	insee: string;
	hasTelesuivie: boolean;
	isActive: boolean;
	isDeceased: boolean;
	agencyName: string;
};

export class DoctorPatient implements BaseModel<doctorPatient> {
	constructor(data: doctorPatient) {
		this._id = data.id;
		this._firstname = data.firstname;
		this._lastname = data.lastname;
		this._civility = data.civility;
		this._birthDate = data.birthDate;
		this._insee = data.insee;
		this._hasTelesuivie = data.hasTelesuivie;
		this._isActive = data.isActive;
		this._isDeceased = data.isDeceased;
		this._agencyName = data.agencyName;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _firstname: string;

	get firstname(): string {
		return this._firstname;
	}

	set firstname(value: string) {
		this._firstname = value;
	}

	private _lastname: string;

	get lastname(): string {
		return this._lastname;
	}

	set lastname(value: string) {
		this._lastname = value;
	}

	private _civility: string;

	get civility(): string {
		return this._civility;
	}

	set civility(value: string) {
		this._civility = value;
	}

	private _birthDate: string;

	get birthDate(): string {
		return this._birthDate;
	}

	set birthDate(value: string) {
		this._birthDate = value;
	}

	private _insee: string;

	get insee(): string {
		return this._insee;
	}

	set insee(value: string) {
		this._insee = value;
	}

	private _hasTelesuivie: boolean;

	get hasTelesuivie(): boolean {
		return this._hasTelesuivie;
	}

	set hasTelesuivie(value: boolean) {
		this._hasTelesuivie = value;
	}

	private _isActive: boolean;

	get isActive(): boolean {
		return this._isActive;
	}

	set isActive(value: boolean) {
		this._isActive = value;
	}

	private _isDeceased: boolean;

	get isDeceased(): boolean {
		return this._isDeceased;
	}

	set isDeceased(value: boolean) {
		this._isDeceased = value;
	}

	private _agencyName: string;

	get agencyName(): string {
		return this._agencyName;
	}

	set agencyName(value: string) {
		this._agencyName = value;
	}

	asObject(): doctorPatient {
		return {
			id: this.id,
			firstname: this.firstname,
			lastname: this.lastname,
			civility: this.civility,
			birthDate: this.birthDate,
			insee: this.insee,
			hasTelesuivie: this.hasTelesuivie,
			isActive: this.isActive,
			isDeceased: this.isDeceased,
			agencyName: this.agencyName,
		};
	}
}
