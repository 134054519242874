import { BaseModel } from '../../core/base.model';
import { DocumentSource } from '@infrastructure';

export type technicienDocument = {
	fileId: string;
	fileName: string;
	depositDate: string;
	vu: boolean;
	documentType: string;
	dossierId: number;
	patientId: number;
	patientLastName: string;
	patientFirstName: string;
	patientFullName: string;
	intervenantId: number;
	source: DocumentSource;
	intervenantLastName: string;
	intervenantFirstName: string;
	intervenantFullName: string;
};

export class TechnicienDocument implements BaseModel<technicienDocument> {
	constructor(data: technicienDocument) {
		this._fileId = data.fileId;
		this._fileName = data.fileName;
		this._depositDate = data.depositDate;
		this._vu = data.vu;
		this._documentType = data.documentType;
		this._dossierId = data.dossierId;
		this._patientId = data.patientId;
		this._patientLastName = data.patientLastName;
		this._patientFirstName = data.patientFirstName;
		this._patientFullName = data.patientFullName;
		this._intervenantId = data.intervenantId;
		this._intervenantLastName = data.intervenantLastName;
		this._intervenantFirstName = data.intervenantFirstName;
		this._intervenantFullName = data.intervenantFullName;
		this._source = data.source;
	}

	private _fileId: string;

	get fileId(): string {
		return this._fileId;
	}

	set fileId(value: string) {
		this._fileId = value;
	}

	private _fileName: string;

	get fileName(): string {
		return this._fileName;
	}

	set fileName(value: string) {
		this._fileName = value;
	}

	private _depositDate: string;

	get depositDate(): string {
		return this._depositDate;
	}

	set depositDate(value: string) {
		this._depositDate = value;
	}

	private _vu: boolean;

	get vu(): boolean {
		return this._vu;
	}

	set vu(value: boolean) {
		this._vu = value;
	}

	private _documentType: string;

	get documentType(): string {
		return this._documentType;
	}

	set documentType(value: string) {
		this._documentType = value;
	}

	private _dossierId: number;

	get dossierId(): number {
		return this._dossierId;
	}

	set dossierId(value: number) {
		this._dossierId = value;
	}

	private _patientId: number;

	get patientId(): number {
		return this._patientId;
	}

	set patientId(value: number) {
		this._patientId = value;
	}

	private _patientLastName: string;

	get patientLastName(): string {
		return this._patientLastName;
	}

	set patientLastName(value: string) {
		this._patientLastName = value;
	}

	private _patientFirstName: string;

	get patientFirstName(): string {
		return this._patientFirstName;
	}

	set patientFirstName(value: string) {
		this._patientFirstName = value;
	}

	private _patientFullName: string;

	get patientFullName(): string {
		return this._patientFullName;
	}

	set patientFullName(value: string) {
		this._patientFullName = value;
	}

	private _intervenantId: number;

	get intervenantId(): number {
		return this._intervenantId;
	}

	set intervenantId(value: number) {
		this._intervenantId = value;
	}

	private _intervenantLastName: string;

	get intervenantLastName(): string {
		return this._intervenantLastName;
	}

	set intervenantLastName(value: string) {
		this._intervenantLastName = value;
	}

	private _intervenantFirstName: string;

	get intervenantFirstName(): string {
		return this._intervenantFirstName;
	}

	set intervenantFirstName(value: string) {
		this._intervenantFirstName = value;
	}

	private _intervenantFullName: string;

	get intervenantFullName(): string {
		return this._intervenantFullName;
	}

	set intervenantFullName(value: string) {
		this._intervenantFullName = value;
	}

	private _source: DocumentSource;

	get source(): DocumentSource {
		return this._source;
	}

	set source(value: DocumentSource) {
		this._source = value;
	}

	asObject(): technicienDocument {
		return {
			fileId: this._fileId,
			fileName: this._fileName,
			depositDate: this._depositDate,
			vu: this._vu,
			documentType: this._documentType,
			dossierId: this._dossierId,
			patientId: this._patientId,
			patientLastName: this._patientLastName,
			patientFirstName: this._patientFirstName,
			patientFullName: this._patientFullName,
			intervenantId: this._intervenantId,
			intervenantLastName: this._intervenantLastName,
			intervenantFirstName: this._intervenantFirstName,
			intervenantFullName: this._intervenantFullName,
			source: this._source,
		};
	}
}
