import { BaseModel } from '../../core/base.model';

export type patientMedicalDevice = {
	hasFollowUps: boolean;
	retrievalDate: string;
	modeId: number;
	modeLabel: string;
	parameters: parametre[];
	parkId: number;
	parkCode: string;
	serialNumber: string;
	id: number;
	label: string;
	noticeUrl: string;
	manufacturer: string;
	familyId: number;
	familyLabel: string;
	dossierId: number;
	dossierDetailId: number;
	rentalDate: string;
	installationDate: string;
	lastRevisionDate: string;
};

export type parametre = {
	parameterModeId: number;
	parameterModeLabel: string;
	value: string;
};

export class PatientMedicalDevice implements BaseModel<patientMedicalDevice> {
	constructor(data: patientMedicalDevice) {
		this._hasFollowUps = data.hasFollowUps;
		this._retrievalDate = data.retrievalDate;
		this._modeId = data.modeId;
		this._modeLabel = data.modeLabel;
		this._parameters = data.parameters;
		this._parkId = data.parkId;
		this._parkCode = data.parkCode;
		this._serialNumber = data.serialNumber;
		this._id = data.id;
		this._label = data.label;
		this._noticeUrl = data.noticeUrl;
		this._manufacturer = data.manufacturer;
		this._familyId = data.familyId;
		this._familyLabel = data.familyLabel;
		this._dossierId = data.dossierId;
		this._dossierDetailId = data.dossierDetailId;
		this._rentalDate = data.rentalDate;
		this._installationDate = data.installationDate;
		this._lastRevisionDate = data.lastRevisionDate;
	}

	private _hasFollowUps: boolean;

	get hasFollowUps(): boolean {
		return this._hasFollowUps;
	}

	set hasFollowUps(value: boolean) {
		this._hasFollowUps = value;
	}

	private _retrievalDate: string;

	get retrievalDate(): string {
		return this._retrievalDate;
	}

	set retrievalDate(value: string) {
		this._retrievalDate = value;
	}

	private _modeId: number;

	get modeId(): number {
		return this._modeId;
	}

	set modeId(value: number) {
		this._modeId = value;
	}

	private _modeLabel: string;

	get modeLabel(): string {
		return this._modeLabel;
	}

	set modeLabel(value: string) {
		this._modeLabel = value;
	}

	private _parameters: parametre[];

	get parameters(): parametre[] {
		return this._parameters;
	}

	set parameters(value: parametre[]) {
		this._parameters = value;
	}

	private _parkId: number;

	get parkId(): number {
		return this._parkId;
	}

	set parkId(value: number) {
		this._parkId = value;
	}

	private _parkCode: string;

	get parkCode(): string {
		return this._parkCode;
	}

	set parkCode(value: string) {
		this._parkCode = value;
	}

	private _serialNumber: string;

	get serialNumber(): string {
		return this._serialNumber;
	}

	set serialNumber(value: string) {
		this._serialNumber = value;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _label: string;

	get label(): string {
		return this._label;
	}

	set label(value: string) {
		this._label = value;
	}

	private _noticeUrl: string;

	get noticeUrl(): string {
		return this._noticeUrl;
	}

	set noticeUrl(value: string) {
		this._noticeUrl = value;
	}

	private _manufacturer: string;

	get manufacturer(): string {
		return this._manufacturer;
	}

	set manufacturer(value: string) {
		this._manufacturer = value;
	}

	private _familyId: number;

	get familyId(): number {
		return this._familyId;
	}

	set familyId(value: number) {
		this._familyId = value;
	}

	private _familyLabel: string;

	get familyLabel(): string {
		return this._familyLabel;
	}

	set familyLabel(value: string) {
		this._familyLabel = value;
	}

	private _dossierId: number;

	get dossierId(): number {
		return this._dossierId;
	}

	set dossierId(value: number) {
		this._dossierId = value;
	}

	private _dossierDetailId: number;

	get dossierDetailId(): number {
		return this._dossierDetailId;
	}

	set dossierDetailId(value: number) {
		this._dossierDetailId = value;
	}

	private _rentalDate: string;

	get rentalDate(): string {
		return this._rentalDate;
	}

	set rentalDate(value: string) {
		this._rentalDate = value;
	}

	private _installationDate: string;

	get installationDate(): string {
		return this._installationDate;
	}

	set installationDate(value: string) {
		this._installationDate = value;
	}

	private _lastRevisionDate: string;

	get lastRevisionDate(): string {
		return this._lastRevisionDate;
	}

	set lastRevisionDate(value: string) {
		this._lastRevisionDate = value;
	}

	asObject(): patientMedicalDevice {
		return {
			hasFollowUps: this._hasFollowUps,
			retrievalDate: this._retrievalDate,
			modeId: this._modeId,
			modeLabel: this._modeLabel,
			parameters: this._parameters,
			parkId: this._parkId,
			parkCode: this._parkCode,
			serialNumber: this._serialNumber,
			id: this._id,
			label: this._label,
			noticeUrl: this._noticeUrl,
			manufacturer: this._manufacturer,
			familyId: this._familyId,
			familyLabel: this._familyLabel,
			dossierId: this._dossierId,
			dossierDetailId: this._dossierDetailId,
			rentalDate: this._rentalDate,
			installationDate: this._installationDate,
			lastRevisionDate: this._lastRevisionDate,
		};
	}
}
