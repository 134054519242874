<div [class.edit]="editMode" class="data-box">

	<div class="data-box-tooling">
		<div class="data-box-title">
			<h3>
				<ng-content select="[title]"></ng-content>
			</h3>
		</div>

		<div class="data-box-action">
			@if (!editMode) {
				<button (click)="edit()" *ngIf="isEditable" class="btn-link">
					Modifier
					<fa-icon [icon]="faPenToSquare"></fa-icon>
				</button>
			}
		</div>

	</div>

	<div class="data-box-body">

		<ng-content select="[content]"></ng-content>

		@if (editMode) {
			<div class="data-box-validation">

				<button class="btn-link" (click)="cancel()">
					Annuler
				</button>

				<button intranetBtn color="primary" size="md" (click)="save()">
					Enregistrer
					<fa-icon [icon]="faSave"></fa-icon>
				</button>

			</div>
		}

	</div>

</div>