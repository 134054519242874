<div class="toggle-group">
	@if (label().length) {
		<div class="toggle-group__label">
			{{ label() }}
		</div>
	}

	<div class="toggle-group__content">
		@for (item of config(); track item.value) {
			<div
				class="toggle-group__block"
				[class.active]="item.state"
				(click)="selectState(item)">
				{{ item.label }}
			</div>
		}
	</div>
</div>
