<div class="grid-container">
	<ag-grid-angular
		(cellClicked)="onCellClicked($event)"
		(gridReady)="onGridReady($event)"
		(gridSizeChanged)="onGridSizeChanged($event)"
		[animateRows]="true"
		[autoSizeStrategy]="autoSizing ? autoSizeStrategy : undefined"
		[columnDefs]="columnDefs"
		[context]="context"
		[defaultColDef]="defaultColDef"
		[getRowClass]="rowClass"
		[getRowStyle]="rowStyle"
		[localeText]="localeText"
		[ngStyle]="style"
		[noRowsOverlayComponent]="noRowsOverlayComponent"
		[rowData]="rowData"
		[tooltipShowDelay]="0"
		class="ag-theme-quartz"
		id="myGrid">
	</ag-grid-angular>
</div>