import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { faFilePdf, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { prescription } from '@domain';

@Component({
	selector: 'intranet-prescription-url-downloader',
	standalone: true,
	imports: [CommonModule, FaIconComponent],
	template: `
		<p>
			@if (data.pdfUrl != null) {
				<a
					target="_blank"
					[href]="pdfUrl"
					class="btn-sos btn-sm">
					<fa-icon [icon]="faPdf"></fa-icon>
				</a>
			}
		</p>
	`,
	styleUrl: './prescription-downloader.component.scss',
})
export class PrescriptionDownloaderComponent implements ICellRendererAngularComp {
	faPdf: IconDefinition = faFilePdf;
	data!: prescription;

	get pdfUrl(): string {
		return this.data.pdfUrl as string;
	}

	agInit(params: ICellRendererParams): void {
		this.data = params.data;
	}

	refresh(params: ICellRendererParams): boolean {
		throw new Error('Method not implemented.');
	}
}
