import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map, Observable } from 'rxjs';
import PDMSearchRequestAdapter from '../adapters/pdm/pdm-search-request.adapter';
import { DTO_pdm_search_result } from '../types/DTO_pdm.types';
import { MedicalDevicePiece, pdmSearchParams } from '@domain';
import { paginatedResult, pagination } from '@application';
import { PDMSearchAdapter } from '../adapters/pdm/pdm-search-result.adapter';

@Injectable({ providedIn: 'root' })
export class PDMRepository extends BaseHttpRepository {
	constructor(
		private toastrService: ToastrService,
		private pdmSearchAdapter: PDMSearchRequestAdapter,
		private pdmResultAdapter: PDMSearchAdapter,
	) {
		super();
	}

	listPDM(searchParams: pdmSearchParams, pagination: pagination): Observable<paginatedResult<MedicalDevicePiece[]>> {
		// BUILD DATA TO SEND
		const dataToSend = this.pdmSearchAdapter.adapt({
			searchParams: searchParams,
			pagination: pagination,
		});

		return this.paginatedGet<DTO_pdm_search_result[]>(`${this.apiUrl}Stock/PiecesDispositifsMedicaux/${searchParams.codeArticle}/Search`, dataToSend, { 'X-Loader-Key': 'pdm-search' }).pipe(
			map((data: paginatedResult<DTO_pdm_search_result[]>) => {
				// SPECIFIC MESSAGE WHEN NO DATA
				if (data.data.length == 0) {
					this.toastrService.warning("Aucun dispositif médical correspondant à vos critères de recherche n'a été trouvé", '', {
						timeOut: 3000,
						closeButton: true,
						progressBar: true,
					});
				}

				return {
					data: data.data.map(pdm => this.pdmResultAdapter.adapt(pdm)),
					pagination: data.pagination,
				};
			}),
		);
	}
}
