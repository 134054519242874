<button
	[class]="className()"
	(click)="displayList()">
	<ng-content></ng-content>
</button>

@if (listIsVisible()) {
	<ul
		[style]="align() == 'left' ? 'left: 0' : 'right: 0'"
		[class]="listClassName"
		intranetClickOutside
		[appOutSideClick]="listIsVisible()"
		(outSideClick)="listIsVisible.set(false)">
		@for (item of options(); track item.label) {
			<li (click)="selectItem(item)">
				@if (item.icon) {
					<fa-icon [icon]="item.icon"></fa-icon>
				}
				<span>{{ item.label }}</span>
			</li>
		}
	</ul>
}
