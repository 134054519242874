import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_patientSearch } from '../types/DTO_patient.types';
import { PatientSearch, patientSearch } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class PatientSearchAdapter implements BaseAdapter<DTO_patientSearch, PatientSearch> {
	adapt(data: DTO_patientSearch): PatientSearch {
		const patientSearch: patientSearch = {
			birthdate: data.birthDate,
			firstname: data.firstName,
			id: data.patientMigrationId,
			insee: data.insee,
			isActive: data.isActive,
			lastname: data.lastName,
		};

		return new PatientSearch(patientSearch);
	}
}
