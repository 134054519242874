import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_doctor } from '../types/DTO_doctor.types';
import { Doctor, doctor } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class DoctorAdapter implements BaseAdapter<DTO_doctor, Doctor> {
	adapt(data: DTO_doctor): Doctor {
		const doctor: doctor = {
			id: data.rpps,
			rpps: data.rpps,
			typeId: data.typeId,
			typeLabel: data.typeLabel,
			titleId: data.titleId,
			titleLabel: data.titleLabel,
			lastname: data.lastName,
			firstname: data.firstName,
			fullname: data.fullName,
			email: data.email,
			phone: data.phone,
			mobile: data.mobile,
			fax: data.fax,
			isActive: data.isActive,
			hasAccountExtranetV1: data.hasAccountExtranetV1,
			accountIdExtranetV2: data.accountIdExtranetV2,
			establishments: data.establishments,
		};

		return new Doctor(doctor);
	}
}
