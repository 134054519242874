import { Injectable } from '@angular/core';
import { BaseAdapter, DTO_doctorSpecialty } from '@infrastructure';
import { DoctorSpecialty } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class DoctorSpecialtyAdapter implements BaseAdapter<DTO_doctorSpecialty, DoctorSpecialty> {
	adapt(data: DTO_doctorSpecialty): DoctorSpecialty {
		return {
			id: data.id,
			label: data.label,
		};
	}
}
