import { BaseAdapter } from '../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_techIntervention } from '../types/DTO-planning-tech.types';
import { DateTime } from 'luxon';

import { getEnumTechInterventionStatusValueByKey, TechIntervention, techInterventionStatus, Technician } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class TechInterventionAdapter implements BaseAdapter<DTO_techIntervention, TechIntervention> {
	adapt(data: DTO_techIntervention): TechIntervention {
		return new TechIntervention({
			id: data.idIntervention,
			technician: new Technician({
				id: data.idIntervenant,
				name: data.intervenantFullName?.toLowerCase(),
			}),
			patient: {
				id: data.idPatient,
				firstname: data.patientFirstName.toLowerCase(),
				lastname: data.patientLastName,
				address: data.patientAdress + ' ' + data.patientZipCode + ' ' + data.patientCity,
				phoneNumber: data.patientPhone,
				doctor: {
					firstname: data.doctorFirstName?.toLocaleLowerCase(),
					lastname: data.doctorLastName,
				},
			},
			idTypeIntervention: data.idTypeIntervention,
			typeInterventionLibelle: data.typeInterventionLibelle,
			status: getEnumTechInterventionStatusValueByKey(data.statusLibelle),
			realisationDate: data.realisationDate,
			expectedDate: data.expectedDate,
			interventionStartHour:
				getEnumTechInterventionStatusValueByKey(data.statusLibelle) == techInterventionStatus.ToPlan || getEnumTechInterventionStatusValueByKey(data.statusLibelle) == techInterventionStatus.Planned
					? this.buildStartHour(data.expectedDate)
					: this.buildStartHour(data.realisationDate),
			interventionEndHour:
				getEnumTechInterventionStatusValueByKey(data.statusLibelle) == techInterventionStatus.ToPlan || getEnumTechInterventionStatusValueByKey(data.statusLibelle) == techInterventionStatus.Planned
					? this.buildEndTime(data.expectedDate)
					: this.buildEndTime(data.realisationDate),
			comment: data.comment,
			source: data.source,
			details: {
				idGestAppel: data.idGestAppel,
				idSourceGestAppel: data.idSourceGestAppel,
				gaCategory: data.gaCategory,
				gaPrecisionInfo: data.gaPrecisionInfo,
				gaMateriel: data.gaMateriel,
				gaComment: data.gaComment,
				gaCallDate: data.gaCallDate,
			},
		});
	}

	private buildEndTime(dateStr: string | null) {
		// ADD THE DURATION IF THE START TIME AND DURATION EXIST
		if (dateStr && DateTime.fromFormat(dateStr, "yyyy-MM-dd'T'HH:mm:ss").hour != 0) {
			return DateTime.fromFormat(dateStr, "yyyy-MM-dd'T'HH:mm:ss").plus({ minutes: 60 }).toFormat('HH:mm');
		} else {
			return null;
		}
	}

	private buildStartHour(dateStr: string | null) {
		// GET THE HOUR FROM THE DATE
		if (dateStr) {
			let date = DateTime.fromFormat(dateStr, "yyyy-MM-dd'T'HH:mm:ss");
			if (date.hour != 0) {
				return date.toFormat('HH:mm');
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}
