import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { DocumentAdapter } from '../adapter/document.adapter';
import { DocumentFile } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class DocumentRepository extends BaseHttpRepository {
	constructor(private readonly documentAdapter: DocumentAdapter) {
		super();
	}

	getDocumentById(fileId: string): Observable<DocumentFile> {
		return this.http.get(`${this.apiUrl}file/download/${fileId}`, { responseType: 'blob' }).pipe(map((document: Blob) => this.documentAdapter.adapt(document)));
	}

	getLegacyDocument(entityId: number, type: string): Observable<DocumentFile> {
		const category = type === 'intervention' ? 'InterventionObservance' : 'Prescription';
		return this.http.get(`${this.apiUrl}file/legacy/${entityId}?categoryFile=${category}`, { responseType: 'blob' }).pipe(
			map((document: Blob) => {
				return this.documentAdapter.adapt(document);
			}),
		);
	}

	markDocumentAsRead(fileId: string): Observable<void> {
		return this.http.put<void>(`${this.apiUrl}file/read/${fileId}`, true);
	}

	markDocumentAsUnread(fileId: string): Observable<void> {
		return this.http.put<void>(`${this.apiUrl}file/read/${fileId}`, false);
	}
}
