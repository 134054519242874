import { Injectable } from '@angular/core';
import { take } from 'rxjs';

import { Agency, Intervenant, Technicien, TechnicienDocument } from '@domain';
import { HasStore, paginatedResult } from '@application';
import { AgencyRepository } from '@infrastructure';

export type agencyState = {
	availableAgencies: Agency[];
	agenciesIntervenants: Intervenant[];
	agencyTechniciens: Technicien[];
	documents: paginatedResult<TechnicienDocument[] | null>;
};

@Injectable({
	providedIn: 'root',
})
export class GlobalAgencyManager extends HasStore<agencyState> {
	constructor(private agencyRepository: AgencyRepository) {
		super(
			{
				availableAgencies: [],
				agenciesIntervenants: [],
				documents: {
					data: null,
					pagination: {
						total: 0,
						page: 1,
						limit: 10,
						nbTotalPage: 1,
					},
				},
				agencyTechniciens: [],
			},
			'agency',
		);
	}

	defineAvailableAgencies() {
		this.agencyRepository
			.listAgencies()
			.pipe(take(1))
			.subscribe(agencies => {
				this.store.update(state => {
					return {
						...state,
						availableAgencies: agencies,
					};
				});
			});
	}

	defineAvailableIntervenants(agencyId?: number) {
		this.agencyRepository
			.listIntervenants(agencyId)
			.pipe(take(1))
			.subscribe(intervenants => {
				this.store.update(state => {
					return {
						...state,
						agenciesIntervenants: intervenants,
					};
				});
			});
	}
}
