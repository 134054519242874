import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../core/adapters/base.adapter';
import { DTO_users } from '../types/DTO_user-management.types';
import { User } from '@domain';

@Injectable({
	providedIn: 'root',
})
export default class UserAdapter implements BaseAdapter<DTO_users, User> {
	adapt(data: DTO_users): User {
		return new User({
			id: data.id,
			lastname: data.lastName,
			firstname: data.firstName,
			email: data.email,
		});
	}
}
