import { BaseAdapter } from '../../core/adapters/base.adapter';
import { Injectable } from '@angular/core';
import { DTO_technician } from '../types/DTO-planning-tech.types';
import { Technician } from '@domain';

@Injectable({
	providedIn: 'root',
})
export class TechnicianAdapter implements BaseAdapter<DTO_technician, Technician> {
	adapt(data: DTO_technician): Technician {
		return new Technician({
			id: data.id,
			name: data.fullName,
		});
	}
}
