import { BaseModel } from '../../core/base.model';

export type prescription = {
	id: number;
	nature: string;
	packageId: number;
	packageName: string;
	isActive: boolean;
	ald: boolean;
	pdfUrl: string;
	pdfName: string;
	state: string;
	origin: string;
	startDate: string;
	endDate: string;
	retrievalDate: string;
	deactivationDate: string;
	sentDate: string;
	depositDate: string;
	renewDate: string;
	dossierId: number;
	patientId: number;
	patientFirstName: string;
	patientLastName: string;
	patientFullName: string;
	doctorId: number;
	doctorRpps: string;
	doctorFirstName: string;
	doctorLastName: string;
	doctorFullName: string;
	doctorSignatureDate: string;
	initialPrescriptionId: any;
	initialDoctorFirstName: any;
	initialDoctorLastName: any;
	initialDoctorFullName: string;
};

export class Prescription implements BaseModel<prescription> {

	constructor(data: prescription) {
		this._id = data.id;
		this._nature = data.nature;
		this._packageId = data.packageId;
		this._packageName = data.packageName;
		this._isActive = data.isActive;
		this._ald = data.ald;
		this._pdfUrl = data.pdfUrl;
		this._state = data.state;
		this._origin = data.origin;
		this._startDate = data.startDate;
		this._endDate = data.endDate;
		this._retrievalDate = data.retrievalDate;
		this._deactivationDate = data.deactivationDate;
		this._sentDate = data.sentDate;
		this._depositDate = data.depositDate;
		this._renewDate = data.renewDate;
		this._dossierId = data.dossierId;
		this._patientId = data.patientId;
		this._patientFirstName = data.patientFirstName;
		this._patientLastName = data.patientLastName;
		this._patientFullName = data.patientFullName;
		this._doctorId = data.doctorId;
		this._doctorRpps = data.doctorRpps;
		this._doctorFirstName = data.doctorFirstName;
		this._doctorLastName = data.doctorLastName;
		this._doctorFullName = data.doctorFullName;
		this._doctorSignatureDate = data.doctorSignatureDate;
		this._initialPrescriptionId = data.initialPrescriptionId;
		this._initialDoctorFirstName = data.initialDoctorFirstName;
		this._initialDoctorLastName = data.initialDoctorLastName;
		this._initialDoctorFullName = data.initialDoctorFullName;
		this._pdfName = data.pdfName;
	}

	private _pdfName: string;

	get pdfName(): string {
		return this._pdfName;
	}

	set pdfName(value: string) {
		this._pdfName = value;
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _nature: string;

	get nature(): string {
		return this._nature;
	}

	set nature(value: string) {
		this._nature = value;
	}

	private _packageId: number;

	get packageId(): number {
		return this._packageId;
	}

	set packageId(value: number) {
		this._packageId = value;
	}

	private _packageName: string;

	get packageName(): string {
		return this._packageName;
	}

	set packageName(value: string) {
		this._packageName = value;
	}

	private _isActive: boolean;

	get isActive(): boolean {
		return this._isActive;
	}

	set isActive(value: boolean) {
		this._isActive = value;
	}

	private _ald: boolean;

	get ald(): boolean {
		return this._ald;
	}

	set ald(value: boolean) {
		this._ald = value;
	}

	private _pdfUrl: string;

	get pdfUrl(): string {
		return this._pdfUrl;
	}

	set pdfUrl(value: string) {
		this._pdfUrl = value;
	}

	private _state: string;

	get state(): string {
		return this._state;
	}

	set state(value: string) {
		this._state = value;
	}

	private _origin: string;

	get origin(): string {
		return this._origin;
	}

	set origin(value: string) {
		this._origin = value;
	}

	private _startDate: string;

	get startDate(): string {
		return this._startDate;
	}

	set startDate(value: string) {
		this._startDate = value;
	}

	private _endDate: string;

	get endDate(): string {
		return this._endDate;
	}

	set endDate(value: string) {
		this._endDate = value;
	}

	private _retrievalDate: string;

	get retrievalDate(): string {
		return this._retrievalDate;
	}

	set retrievalDate(value: string) {
		this._retrievalDate = value;
	}

	private _deactivationDate: string;

	get deactivationDate(): string {
		return this._deactivationDate;
	}

	set deactivationDate(value: string) {
		this._deactivationDate = value;
	}

	private _sentDate: string;

	get sentDate(): string {
		return this._sentDate;
	}

	set sentDate(value: string) {
		this._sentDate = value;
	}

	private _depositDate: string;

	get depositDate(): string {
		return this._depositDate;
	}

	set depositDate(value: string) {
		this._depositDate = value;
	}

	private _renewDate: string;

	get renewDate(): string {
		return this._renewDate;
	}

	set renewDate(value: string) {
		this._renewDate = value;
	}

	private _dossierId: number;

	get dossierId(): number {
		return this._dossierId;
	}

	set dossierId(value: number) {
		this._dossierId = value;
	}

	private _patientId: number;

	get patientId(): number {
		return this._patientId;
	}

	set patientId(value: number) {
		this._patientId = value;
	}

	private _patientFirstName: string;

	get patientFirstName(): string {
		return this._patientFirstName;
	}

	set patientFirstName(value: string) {
		this._patientFirstName = value;
	}

	private _patientLastName: string;

	get patientLastName(): string {
		return this._patientLastName;
	}

	set patientLastName(value: string) {
		this._patientLastName = value;
	}

	private _patientFullName: string;

	get patientFullName(): string {
		return this._patientFullName;
	}

	set patientFullName(value: string) {
		this._patientFullName = value;
	}

	private _doctorId: number;

	get doctorId(): number {
		return this._doctorId;
	}

	set doctorId(value: number) {
		this._doctorId = value;
	}

	private _doctorRpps: string;

	get doctorRpps(): string {
		return this._doctorRpps;
	}

	set doctorRpps(value: string) {
		this._doctorRpps = value;
	}

	private _doctorFirstName: string;

	get doctorFirstName(): string {
		return this._doctorFirstName;
	}

	set doctorFirstName(value: string) {
		this._doctorFirstName = value;
	}

	private _doctorLastName: string;

	get doctorLastName(): string {
		return this._doctorLastName;
	}

	set doctorLastName(value: string) {
		this._doctorLastName = value;
	}

	private _doctorFullName: string;

	get doctorFullName(): string {
		return this._doctorFullName;
	}

	set doctorFullName(value: string) {
		this._doctorFullName = value;
	}

	private _doctorSignatureDate: string;

	get doctorSignatureDate(): string {
		return this._doctorSignatureDate;
	}

	set doctorSignatureDate(value: string) {
		this._doctorSignatureDate = value;
	}

	private _initialPrescriptionId: any;

	get initialPrescriptionId(): any {
		return this._initialPrescriptionId;
	}

	set initialPrescriptionId(value: any) {
		this._initialPrescriptionId = value;
	}

	private _initialDoctorFirstName: any;

	get initialDoctorFirstName(): any {
		return this._initialDoctorFirstName;
	}

	set initialDoctorFirstName(value: any) {
		this._initialDoctorFirstName = value;
	}

	private _initialDoctorLastName: any;

	get initialDoctorLastName(): any {
		return this._initialDoctorLastName;
	}

	set initialDoctorLastName(value: any) {
		this._initialDoctorLastName = value;
	}

	private _initialDoctorFullName: string;

	get initialDoctorFullName(): string {
		return this._initialDoctorFullName;
	}

	set initialDoctorFullName(value: string) {
		this._initialDoctorFullName = value;
	}

	asObject(): prescription {
		return {
			id: this.id,
			nature: this.nature,
			packageId: this.packageId,
			packageName: this.packageName,
			isActive: this.isActive,
			ald: this.ald,
			pdfUrl: this.pdfUrl,
			pdfName: this.pdfName,
			state: this.state,
			origin: this.origin,
			startDate: this.startDate,
			endDate: this.endDate,
			retrievalDate: this.retrievalDate,
			deactivationDate: this.deactivationDate,
			sentDate: this.sentDate,
			depositDate: this.depositDate,
			renewDate: this.renewDate,
			dossierId: this.dossierId,
			patientId: this.patientId,
			patientFirstName: this.patientFirstName,
			patientLastName: this.patientLastName,
			patientFullName: this.patientFullName,
			doctorId: this.doctorId,
			doctorRpps: this.doctorRpps,
			doctorFirstName: this.doctorFirstName,
			doctorLastName: this.doctorLastName,
			doctorFullName: this.doctorFullName,
			doctorSignatureDate: this.doctorSignatureDate,
			initialPrescriptionId: this.initialPrescriptionId,
			initialDoctorFirstName: this.initialDoctorFirstName,
			initialDoctorLastName: this.initialDoctorLastName,
			initialDoctorFullName: this.initialDoctorFullName,
		};
	}
}
