import { Injectable } from '@angular/core';
import { BaseHttpRepository } from '../../core/repository/base-http.repository';
import { PatientFollowUpAdapter } from '../adapter/patient-follow-up.adapter';
import { DTO_followUp } from '../types/DTO_follow_up.type';
import { map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PatientFollowUpRepository extends BaseHttpRepository {
	constructor(private readonly patientFollowUpAdapter: PatientFollowUpAdapter) {
		super();
	}

	getPatientFollowUp(patientId: number) {
		return this.http.get<DTO_followUp[]>(`${this.apiUrl}patient/${patientId}/suivis`).pipe(map(followUps => followUps.map(followUp => this.patientFollowUpAdapter.adapt(followUp))));
	}
}
